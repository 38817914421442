import React, { useEffect, useState } from 'react'
import { htmlToReact } from '../utils/HtmlToReact'
import session        from '../controllers/Session'
import { useHotkeys } from 'react-hotkeys-hook'
import TestContainer from './TestContainer'
import MoveForwardBttn from './MoveForwardBttn'
import styled from 'styled-components'
import { TestArea, flashcardStyle } from './FlashcardsStyles'
import QuestionScreenHelperBttns from './QuestionScreenHelperBttns'
import { ColumnCSS, SelectableText } from '../components/BasicComponents'


const FlashcardQuestion = styled.div`
    ${flashcardStyle}
    text-align: center;
    ${SelectableText}
`


const BttsContainer = styled.div`
    width: 100%;
    ${ColumnCSS}
`

export default function FlashcardQuestionJSX({ test, moveEndQueue, goToPreviousQuestion, moveForward}) {
    const [flashcard, setFlashcard] = useState("")

    
    useEffect(() => {
        if (!test) {
            console.log(session.session)
            setFlashcard(
                `Erro grave ao obter a pergunta.
                Por favor, tire um print e envie na DM!
                (${session.session[session.currentIndex].testID} / ${session.currentIndex})
            `)
        }

        setFlashcard(
            htmlToReact( test.getQuestion() )
        )
    }, [test])


    useHotkeys('ctrl+z', goToPreviousQuestion)
    useHotkeys('shift+p', moveEndQueue)
    useHotkeys('space', moveForward)
    useHotkeys('enter', moveForward)

    
    return (
        <>
            <TestContainer>
                <FlashcardQuestion>
                    {flashcard}
                </FlashcardQuestion> 
            </TestContainer>
                

            <BttsContainer>
                <QuestionScreenHelperBttns
                    goToPreviousQuestion = {goToPreviousQuestion}
                    moveEndQueue = {moveEndQueue} />

                <MoveForwardBttn
                    moveForward = {moveForward} />
            </BttsContainer>
        </>
    )
}