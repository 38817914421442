import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts'
import { 
    Book, 
    BookOpen, 
    Bell, 
    Coffee, 
    ArrowRight, 
    Calendar, 
    Award, 
    Brain, 
    TrendingUp, 
    Target, 
    Clock,
    ChevronRight,
    Star,
    Timer,
    Tag,
    User,
    BarChart2,
    Check
} from 'lucide-react'

import BackIcon from './../assets/back_colored.png'
import TitleCard from '../custom/TitleCard'
import { Column, ColumnCSS, RowCSS } from '../components/BasicComponents'
import LastSessionController from '../controllers/LastSessionController'
import { formatNumber, sleep } from '../utils/Utils'
import { startSavedSession, useSessionStarter } from '../utils/StartSessionMethods'
import LastSessionCard from './LastSessionCard'
import LastSessionCardTEST from '../controllers/Test'
import OslerButton from '../components/OslerButton'



export default function LastSessions() {
    const { startSavedSession } = useSessionStarter()
    

    const [nShow, setNShow] = useState(3)
    const [sessions, setSessions] = useState({
        items: {},
        sorted: []
    })


    useEffect(() => {
        async function load() {
            if (!LastSessionController.loaded) {
                await sleep(100)
            }

            await LastSessionController.getLastSessions()
            updateLastSessions()
        }

        load()
    }, [])


    function updateLastSessions() {
        setSessions({
            items: {...LastSessionController.lastSessions},
            sorted: [...LastSessionController.sortedSessions]
        })
    }


    function toggleFavorite(sessionID) {
        LastSessionController.toggleFavorite(sessionID)
        updateLastSessions()
    }


    function deleteSession(sessionID) {
        LastSessionController.deleteSession(sessionID)
        updateLastSessions()
    }


    function showMore() {
        setNShow(nShow + 5)
    }

    if (!sessions.items || !sessions.sorted) return null
    return (
        <TitleCard
            title="Últimas Sessões"
            icon={BackIcon}>
            <Column style={{width: '100%', gap: '1.5em', paddingTop: '2em', paddingBottom: '2em'}}>
                {sessions.sorted.slice(0, nShow).map(sessionID => (
                    <LastSessionCard
                        key={sessionID}
                        sessionID={sessionID}
                        session={sessions.items[sessionID]}
                        toggleFavorite={toggleFavorite}
                        deleteSession={deleteSession}
                    />
                ))}


                { nShow < sessions.sorted.length &&
                    <OslerButton
                        size='small'
                        style = {{alignSelf: 'flex-end', marginTop: '1em', marginBottom: '-2em'}}
                        text = 'Mostrar mais'
                        onClick = {() => showMore()}
    
                        />                
                }

                {/* <LastSessionCardTEST /> */}
            </Column>
        </TitleCard>    
    )
}