import React, { useEffect, useState } from 'react'
import ResidenciaIcon from './../assets/residencia.png'
import SurpriseIcon from './../assets/surprise-box.png'
import FlashcardIcon from './../assets/flashcard.png'
import TitleCard from '../custom/TitleCard'
import styled from 'styled-components'
import { ColumnCSS, RowCSS } from '../components/BasicComponents'
import { useSessionStarter } from '../utils/StartSessionMethods'
import { SessionConfig, STUDY_MODES, TEST_TYPES } from '../controllers/SessionConfig'


const ReviewGrid = styled.div`
    padding-top: 2em;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5em;
`


const Item = styled.div`
    ${RowCSS}

    width: 100%;
    padding: 1.5em;

    gap: 1em;

    // background: #f1f5f9;
    border: 1px solid rgba(0, 102, 204, 0.3);
    background-color: rgba(0, 102, 204, 0.05);

    border-radius: 1em;
    cursor: pointer;

    // Determina a velocidade do hover na sequência
    transition: all 0.3s ease;
    &:hover {
        border: 1px solid rgba(0, 102, 204, 0.9);
        background-color: rgba(0, 102, 204, 0.1);
    }
`


const IconWrapper = styled.div`
    ${RowCSS}    
    width: 20%;

    img {
        width: 3em;
        height: 3em;
        transition: transform 0.3s ease;
    }

    // Ao hover de ReviewItem, modifica a imagem
    ${Item}:hover & img {
        transform: scale(1.1) rotate(5deg);
    }
`

export default function SurpriseSessionCard() {

    const { startNewSession } = useSessionStarter()


    function start(testType) {
        startNewSession({
            testType: testType,
            builder: 'random',
            sessionConfig: SessionConfig.createConfig(testType),
            studyMode: STUDY_MODES.TEST_MODE,
            descriptor: 'Modo Surpresa'
        })
    }


    

    function Button({ icon, count, label, onClick}) {
        return (
            <Item onClick = {onClick}>
            <IconWrapper>
                <img src={icon} alt={label} />
            </IconWrapper>
            </Item>
        )
    }


    return (
        <TitleCard
            title = 'Surpresa'
            icon={SurpriseIcon}
            style = {{width: 'auto'}} >

                <ReviewGrid>
                    <Button
                        icon={FlashcardIcon}
                        count={12}
                        label='cards pendentes'
                        onClick = {() => start(TEST_TYPES.FLASHCARDS)} />

                    <Button
                        icon={ResidenciaIcon}
                        onClick = {() => start(TEST_TYPES.RESIDENCIA)}/>
                </ReviewGrid>

        </TitleCard>


    )


    // return (
    //     <MSCard
    //         title = {'Surpresa'}
    //         icon  = {SurpriseIcon}

    //         flashcards = {'?'}
    //         flashcardsAction = {() => start('Flashcards')}

    //         residencia = {'?'}
    //         residenciaAction = {() => start('Residencia')} 
            
    //         text = {(<><b>Quer estudar algo, mas tá difícil escolher um tema?</b> A Osler te ajuda, vamos pegar um tema aleatório que você nunca estudou.</>)} />

    // )
}