import styled from 'styled-components'
import { motion } from 'framer-motion'
import { ColumnCSS } from './BasicComponents'
import React from 'react'

const StyledCard = styled(motion.div)`
    ${ColumnCSS}

    width: ${props => props.width || '100%'};


    background-color: ${props => props.background || props.theme.darkMode ? 
        'rgba(61, 61, 61, 1)' : 
        'rgba(255, 255, 255, 0.98)'
    };


    
    border-radius: 1em;
    padding: ${props => props.padding || '2em'};
    
    // Sombra em repouso
    box-shadow: 
        0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06),
        0 0 0 1px rgba(0, 0, 0, 0.05);
        
    // Borda sutil
    border: 1px solid ${props => props.theme.darkMode ? 
        'rgba(255, 255, 255, 0.1)' : 
        'rgba(0, 0, 0, 0.05)'
    };

    // Transições suaves
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);

    &:hover {
        transform: translateY(-2px);
        box-shadow: 
            0 20px 25px -5px rgba(0, 0, 0, 0.1),
            0 10px 10px -5px rgba(0, 0, 0, 0.04),
            0 0 0 1px rgba(0, 0, 0, 0.05);
    }

    // Garantir que o conteúdo não vaze das bordas arredondadas
    overflow: hidden;

    // Estilo específico para modo escuro
    ${props => props.theme.darkMode && `
        box-shadow: 
            0 4px 6px -1px rgba(0, 0, 0, 0.2),
            0 2px 4px -1px rgba(0, 0, 0, 0.12);

        &:hover {
            box-shadow: 
                0 20px 25px -5px rgba(0, 0, 0, 0.2),
                0 10px 10px -5px rgba(0, 0, 0, 0.08);
        }
    `}

    text-align: left;

    @media (max-width: 1100px) {
        padding: 1em;
    }
`

export default function OslerCard ({ 
    children, 
    width,
    background,
    padding,
    className,
    style,
    onClick,
    forceWhiteBg = false,
    ...props 
}, ref) {

    // Precisamos colocar o ref para que componentes possam usá-lo

    return (
        <StyledCard
            ref={ref}
            width={width}
            background={background}
            padding={padding}
            className={className}
            style={style}
            onClick={onClick}
            forceWhiteBg={forceWhiteBg}
            {...props}
        >
            {children}
        </StyledCard>
    )
}



// Sem isso daqui, quando atribuímos uma ref a um componente OslerCard ela não é 
// passada, ficando como nula.
OslerCard = React.forwardRef(OslerCard)