import { db } from "../firebase/firebase-setup"
import { getLastSessionPath } from "../utils/SessionUtils"
import { timeStampToDate } from "../utils/Utils"
import OslerData, { KEYS } from "./OslerData"
import Session from "./Session"
import SessionBuilder from "./SessionBuilder"
import { STUDY_MODES, TEST_TYPES } from "./SessionConfig"
import UserReviewsInfo from "./UserReviewsInfo"


class LastSessionController { 
    constructor() {
        this.loaded = false
    }

    prepare(user, userInfo) {
        this.userID = user.id
        this.userReviewsInfo = userInfo.info
        this.allInfo = userInfo


        this.collectionPath = db.collection(`users/${this.userID}/lastSessions`)

        this.lastSessions = {}
        this.loaded = true
    }


    async getLastSessions() {
        const docs = await this.collectionPath.get()
        
        this.lastSessions = {}        
        docs.forEach(doc => {
            this.lastSessions[doc.id] = doc.data()
            this.lastSessions[doc.id].dateSaved = this.lastSessions[doc.id].dateSaved.toDate()
        })

        this.sortSessions()
    }


    sortSessions() {
        // Ordenamos por data de acesso e/ou estar favoritado
        this.sortedSessions = Object.keys(this.lastSessions).sort((a, b) => {
            const sessionA = this.lastSessions[a]
            const sessionB = this.lastSessions[b]
            
            // Se um é favorito e outro não, favorito vem primeiro
            if (sessionA.favorite && !sessionB.favorite) return -1
            if (!sessionA.favorite && sessionB.favorite) return 1
            
            // Se ambos são favoritos ou ambos não são, ordena por data
            return sessionB.dateSaved - sessionA.dateSaved
        })
    }


    async toggleFavorite(sessionID) {
        const session = this.lastSessions[sessionID]
        session.favorite = !session.favorite
        this.sortSessions()

        await this.writeSessionData(sessionID, session)
    }


    async deleteSession(sessionID) {
        delete this.lastSessions[sessionID]
        this.sortSessions()

        await this.collectionPath.doc(sessionID).delete()
    }


    sessionAlreadyExists(ID) {
        return Object.keys(this.lastSessions).includes(ID)
    }



    
    createSessionID() {
        function generateID() {
            return crypto.randomUUID().replace(/[^A-Z0-9]/gi, '').slice(0,12)
        }

        let ID = generateID()
        while (this.sessionAlreadyExists(ID)) {
            ID = generateID()
        }

        return ID
    }


    getLastSession(sessionID) { 
        const lastSession = this.lastSessions[sessionID]
        const adjustedSession = this.adjustLastSession(lastSession)
        return adjustedSession
    }



    adjustLastSession(session) {
        // Corrigimos para o fato de que o usuário pode ter respondido testes por fora
        function shouldRemove(testType, testID, config) {
            const isFutureReview = SessionBuilder.isFutureReview(testType, testID)
            if (isFutureReview && config.removeFutureReviews) {
                console.log(`Removendo ${testID} - é revisão futura (futureReview=${isFutureReview}, config=${config.removeFutureReviews})`)
                return true
            }
            
            const isBuried = SessionBuilder.isBuried(testType, testID)
            if (isBuried && config.removeBuried) {
                console.log(`Removendo ${testID} - está enterrado (buried=${isBuried}, config=${config.removeBuried})`)
                return true
            }
         
            const isSolved = (testType === TEST_TYPES.RESIDENCIA) && SessionBuilder.isSolved(testType, testID)
            if (isSolved && config.removeSolved) {
                console.log(`Removendo ${testID} - já está resolvido (solved=${isSolved}, config=${config.removeSolved})`)
                return true
            }
         
            const isNew = SessionBuilder.isNewTest(testType, testID)
            if (isNew && config.removeNewTests) {
                console.log(`Removendo ${testID} - é novo (new=${isNew}, config=${config.removeNewTests})`)
                return true
            }
         
            const isPending = SessionBuilder.isPendingReview(testType, testID)
            if (isPending && config.removePendingReviews) {
                console.log(`Removendo ${testID} - está pendente de revisão (pending=${isPending}, config=${config.removePendingReviews})`)
                return true
            }
         
            return false
         }

        if (session.mode === STUDY_MODES.TEST_MODE) {                
            const newSession = []
            const newListIDs = []

            session.session.forEach(test => {
                if (test.answered || !shouldRemove(session.testType, test.testID, session.config)) {
                    // Se já foi respondido, mantém independente das condições
                    // porque... só servirá a fins de consulta
                    //
                    // Do contrário, só se satisfaz as condições esperadas
                    newSession.push(test)
                    newListIDs.push(test.testID)
                } else {
                    console.log(`estamos descartando o teste ` + test.testID)
                    console.log(test.answered)
                    console.log(shouldRemove(session.testType, test.testID, session.config))
                }
            })
                
            session.session = newSession
            session.listIDs = newListIDs
            session.sessionSize = newSession.length            
        }


        return session
    }


    async saveAsLastSession(session) {
        const exists = this.sessionAlreadyExists(session.sessionID)
        const remainingTests = session.session.filter(test => !test.answered).length
        const nSessions = this.sortedSessions.length


        console.log(`Iremos salvar:`)
        console.log(session)
        console.log(`Existe? ${exists} -- Restam ${remainingTests} testes -- ${nSessions} sessões`)

        
        if (exists && remainingTests === 0) {
            await this.deleteSession(session.sessionID)
        }
        else {
            if (!exists) {
                // Se não existia, precisamos colocar no array e, se ultrapasssou o limite, deletar alguma
                if (nSessions >= 15) {
                    const lastSessionID = this.sortedSessions.pop()
                    await this.deleteSession(lastSessionID)
                }


                this.lastSessions[session.sessionID] = session
                this.sortSessions()
            }

            await this.saveSession(session)
        }
    }


    async saveSession(session) {
        // Na realidade, não precisava executar todas as vezes, e só na primeira -- salvo que queiramos
        // adaptar para a possibilidade de alguns testes irem sumindo, mas...
        const { tags, mostFrequent } = this.getTagpaths(session.testType, session.listIDs)


        const sessionData = {
            dateSaved: new Date(),
            testType: session.testType,
            listIDs: session.listIDs,
            sessionSize: session.sessionSize,
            saveAsLastSession: session.saveAsLastSession,
            downloadStatistics: session.downloadStatistics,
            lastAnsweredIndex: session.lastAnsweredIndex,
            currentIndex: session.currentIndex,
            mode: session.mode,
            descriptor: session.descriptor,
            config: session.config,
            tags: tags,
            mainTheme: mostFrequent,
            sessionID: session.sessionID,
            favorite: session.favorite || false
        }

        sessionData['session'] = session.session.map(entry => ({
            ...entry,
            data: false
        }))

        await this.writeSessionData(session.sessionID, sessionData)
    }


    async writeSessionData(sessionID, data) {
        try {
            await this.collectionPath.doc(sessionID).set(data)
        }
        catch (error) {
            console.log(sessionID)
            console.log(data)
            console.error(error)
            throw error
        }
    }


    getTagpaths(testType, testIDs) {
        console.log(testIDs)

        // Primeiro vamos contar frequências
        const frequencies = {}
        
        for (let testID of testIDs) {
            const path = UserReviewsInfo.getGivenTestTagPath(testType, testID)
            const lastTag = path.split('/').pop()
            
            frequencies[lastTag] = (frequencies[lastTag] || 0) + 1
        }
        
        // Transformar em array e ordenar por frequência
        const sortedTags = Object.entries(frequencies)
            .sort(([, countA], [, countB]) => countB - countA)
            .map(([tag]) => tag)
        
        // O mais frequente será o primeiro após ordenação
        // const mostFrequent = {
        //     tag: sortedTags[0],
        //     count: frequencies[sortedTags[0]]
        // }
        
        const mostFrequent = sortedTags[0]

        return {
            tags: sortedTags, // agora já retorna ordenado
            mostFrequent
        }
     }
}


export default new LastSessionController()