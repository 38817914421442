// Novembro, 2024
// Wrapper alterantivo ao OslerCard, usado no mobile, quando não queremos paddings ou sombras
// ou afins, para maximizar a width.

import styled from 'styled-components'

const FlatCard = styled.div`
    width: 100%;
    padding: 0;
    margin: 0;
    box-shadow: none;
    background: transparent;

    background-color: white;
    border: 1px solid gray;
    padding: 0.25em;
    
`

export default FlatCard
