import React from 'react'
import styled from 'styled-components'
import GeneralDialog from '../tests/GeneralDialog'
import HelpIcon from './../assets/question-new.png'
import OwlIcon from './../assets/owl.png'
import BooksIcon from './../assets/books.png'
import SandboxIcon from './../assets/sandbox.png'

const HelpTitle = styled.p`
    font-size: 1.1em;
    font-weight: bold;
    margin: 0em 0 0.5em 0;
    color: ${props => props.theme.darkMode ? 'rgb(220, 220, 220)' : 'inherit'};
`

const HelpImg = styled.img`
    height: 1em;
    margin-right: 0.5em;
`


export function StudyModeGeneralDialog({ open, onClose, testType = 'Residencia' }) {
    return (
        <GeneralDialog
            open={open}
            icon = {HelpIcon}
            title={'Ajuda'}
            bigWidth={false}
            onClose={onClose}
        >

            { testType === 'Residencia' ? (
                <>
                <p><b>Escolha os temas.</b> A árvore de tags permite acesso e seleção rápida aos temas disponíveis. Se precisar, há uma barra de busca no topo.</p>

                <p><b>Filte por instituições e anos.</b> <u>Opcionalmente</u>, você pode buscar pela instituições do seu interesse e optar por questões de provas mais ou menos antigas.</p>
                
                <p><i>Perceba que isso dinamicamente altera dinamicamente os temas e a quantidade de questões disponíveis. Por exemplo, pode ser que, selecionando "USP" e "2015" realmente não haja nenhuma questão de anemias microangiopáticas.</i></p>

                <p><b>Usa o mesmo filtro com frequência?</b> É normal que você sempre foque nas mesmas instituições e determinados anos (os mais recentes, presume-se...). Você pode salvar esse filtro, clicando em "Salvar filtro" na direita, e carregá-lo mais rapidamente depois através da aba "Meus filtros".</p>
                
                <p><b>Configurações adicionais.</b> Na direita, você seleciona o modo de estudo e pode fazer ajustes adicionais quanto aos tipos de teste, especialmente no que tange a <i>status</i> de revisão, questões anuladas, etc. Novamente, veja que isso altera dinamicamente a árvore.</p>

                <p><b>Inicie a sessão.</b> Escolhendo temas & filtros & ajustes de modo que haja ao menos uma questão, aparecerá um botão para você carregar os testes e/ou salvá-los em uma lista.</p>
                </>
            ) : (
                <>
                <p><b>Crie uma sessão de flashcards.</b> Você poderá respondê-las agora ou salvá-las em uma lista, para mais tarde.</p>
                </>
            )}
        </GeneralDialog>
    )
}


export function StudyModeStudyDialog({ open, onClose }) {
    return (
        <GeneralDialog
            icon={HelpIcon}
            title='Modos de estudo'
            open={open}
            onClose={onClose}
        >
            <HelpTitle><HelpImg src={OwlIcon} alt="Dumbbell Icon" />Teste</HelpTitle>
            <p>É o padrão, você responderá aos testes, afetando as suas estatísticas de acerto/erro e a programação das suas revisões. Salvo que configure o contrário, você não verá os testes que estão agendados para revisão no futuro.</p>

            <HelpTitle><HelpImg src={BooksIcon} alt="Books Icon" />Consulta</HelpTitle>
            <p>Você irá ler o conteúdo dos testes, tendo acesso tanto às perguntas quanto às respostas, mas sem conseguir respondê-los. É um estudo passivo, análogo a fazer uma leitura. Ideal para um primeiro contato com o tema ou para pesquisas.</p>

            <HelpTitle><HelpImg src={SandboxIcon} alt="Sandbox Icon" />Playground</HelpTitle>
            <p>Você terá acesso a todos os testes (sem diferenciar entre novos, revisões pendentes, revisões futuras) e irá respondê-los, mas NÃO afetará suas estatísticas de acerto ou agenda de revisões.</p>

            <p>É um estudo ativo, ideal quando você quer retomar um tema rapidamente (e.g., antes de uma prova). Ou para quando você quer mostrar para um colega (embaixadores, amamos vocês ♥️).</p>
        </GeneralDialog>
    )
}




export function StudyModeConfigDialog({ open, onClose, testType = 'Residencia' }) {
    return (
        <GeneralDialog
            icon={HelpIcon}
            title='Configurações'
            open={open}
            onClose={onClose}
        >
            {testType === 'Residencia' ? (
                <>
                    <p>O padrão é carregar tanto as questões novas (i.e., que você nunca viu) quanto as revisões pendentes, mas você pode selecionar só um ou o outro.</p>

                    <p>As questões vêm ordenadas por <u>temas</u>, na sequência por instituição e, dentro de uma mesma, por anos decrescentes. <u>Excluímos</u> as anuladas. Você aleatorizar e/ou incluir as anuladas alterando os ajustes.</p>
                </>
            ) : (
                <>
                    <p>O padrão é carregar tanto os flashcards novos (i.e., que você nunca viu) quanto as revisões pendentes, mas você pode selecionar só um ou o outro.</p>

                    <p>Os flashcards vem agrupados por tema e, dentro de um mesmo, ordenados na sequência lógica (i.e., definição → epidemiologia → clínica → diagnóstico → tratamento). Se quiser aleatorizar, selecione o filtro abaixo.</p>
                </>
            )}
        </GeneralDialog>
    )
}