import styled from 'styled-components';
import { useState } from 'react';
import { 
    Book, 
    Brain, 
    Clock, 
    Play, 
    Star, 
    Trash2, 
    ChevronRight, 
    Calendar, 
    Tag as TagIcon,
    BookOpen,
    Lightbulb,
    ClipboardList
} from 'lucide-react';



const COLORS = {
    primary: '#0051FF',
    primaryLight: '#E6EDFF',
    text: '#1a1f36',
    textLight: '#6b7280',
    background: '#ffffff'
};


const Card = styled.div`
    background: linear-gradient(145deg, #ffffff 0%, #f8f9ff 100%);
    border-radius: 24px;
    padding: 24px;
    box-shadow: 0 8px 32px rgba(0, 81, 255, 0.07);
    border: 1px solid rgba(255, 255, 255, 0.4);
    transition: all 0.3s ease;
    width: 900px;
    max-width: 95vw;

    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 12px 48px rgba(0, 81, 255, 0.12);
    }
`;


const Header = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 20px;
`;

const Title = styled.h2`
    font-size: 24px;
    font-weight: 600;
    color: ${COLORS.text};
    margin: 0;
`;

const IconWrapper = styled.div`
    width: 48px;
    height: 48px;
    background: ${COLORS.primary};
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
`;

const MetadataRow = styled.div`
    display: flex;
    align-items: center;
    gap: 24px;
    color: ${COLORS.textLight};
    font-size: 14px;
    margin-bottom: 16px;
`;

const MetadataItem = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const TagsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 8px;
    margin-top: 12px;
    max-height: ${props => props.expanded ? '200px' : '0'};
    overflow: hidden;
    transition: all 0.3s ease;
`;

const TopicTag = styled.span`
    background: ${COLORS.primaryLight};
    color: ${COLORS.primary};
    padding: 6px 12px;
    border-radius: 12px;
    font-size: 13px;
    font-weight: 500;
`;


const Progress = styled.div`
    position: relative;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: #f3f4f6;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    color: white;
    z-index: 1;
    
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: conic-gradient(
            ${COLORS.primary} ${props => props.percentage}%,
            transparent ${props => props.percentage}%
        );
        z-index: -1;
    }
`;



const SessionTypeIndicator = styled.div`
    width: 80px;
    height: 80px;
    border-radius: 20px;
    background: ${COLORS.primaryLight};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    color: ${COLORS.primary};
    font-size: 12px;
    font-weight: 500;
`;

const TimeInfo = styled.div`
    display: inline-flex;
    align-items: center;
    padding: 6px 12px;
    background: ${COLORS.primaryLight};
    border-radius: 12px;
    color: ${COLORS.primary};
    font-size: 14px;
    font-weight: 500;
    gap: 6px;
`;

const ActionButtons = styled.div`
    display: flex;
    gap: 12px;
    margin-top: 24px;
    justify-content: space-between;
`;

const IconButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    color: ${COLORS.textLight};
    padding: 8px;
    border-radius: 12px;
    transition: all 0.2s ease;

    &:hover {
        background: ${COLORS.primaryLight};
        color: ${COLORS.primary};
    }
`;

const ContinueButton = styled.button`
    background: linear-gradient(135deg, ${COLORS.primary} 0%, #0066FF 100%);
    color: white;
    border: none;
    padding: 12px 32px;
    border-radius: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 8px;

    &:hover {
        transform: translateY(-1px);
        box-shadow: 0 4px 12px rgba(0, 81, 255, 0.2);
    }
`;


const SessionList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
`;


export default function LastSessionCardTEST() {
    const mockSessions = [
        {
            sessionType: 'Teste',
            sessionOrigin: 'Cronograma',
            mainTheme: 'Anatomia Cardíaca',
            lastAccess: '12/12/2024',
            topics: [
                'Ventrículo Esquerdo',
                'Átrio Direito',
                'Válvulas Cardíacas',
                'Sistema de Condução',
                'Circulação Coronariana'
            ],
            progress: 75,
            completed: 15,
            total: 20,
            timeSpent: '45min',
            timeTotal: '60min',
            isFavorite: false
        },
        {
            sessionType: 'Consulta',
            sessionOrigin: 'Estudo customizado',
            mainTheme: 'Farmacologia - Antibióticos',
            lastAccess: '12/12/2024',
            topics: [
                'Penicilinas',
                'Cefalosporinas',
                'Aminoglicosídeos',
                'Quinolonas'
            ],
            timeTotal: '120min',
            timeSpent: '45min',
            isFavorite: true
        },
        {
            sessionType: 'Playground',
            sessionOrigin: 'Testes salvos',
            mainTheme: 'Casos Clínicos - Pediatria',
            lastAccess: '11/12/2024',
            topics: [
                'Bronquiolite',
                'Otite Média',
                'Pneumonia'
            ],
            timeTotal: '30min',
            timeSpent: '15min',
            isFavorite: false
        }
    ];

    return (
        <SessionList>
            {mockSessions.map((session, index) => (
                <SessionCard key={index} data={session} />
            ))}
        </SessionList>
    );
}

function SessionCard({ data }) {
    const [showTags, setShowTags] = useState(false)
    
    const toggleTags = () => setShowTags(!showTags)

    const renderSessionIcon = () => {
        switch (data.sessionType) {
            case 'Teste':
                return <Brain size={24} />
            case 'Consulta':
                return <BookOpen size={24} />
            default:
                return <Lightbulb size={24} />
        }
    }

    const renderProgressOrType = () => {
        if (data.sessionType === 'Teste') {
            return (
                <Progress percentage={data.progress}>
                    {data.progress}%
                </Progress>
            )
        }
        
        return (
            <SessionTypeIndicator>
                {renderSessionIcon()}
                {data.sessionType}
            </SessionTypeIndicator>
        )
    }

    const renderTimeInfo = () => {
        if (data.sessionType === 'Teste') {
            return (
                <div style={{ color: COLORS.textLight }}>
                    <div style={{ marginBottom: '8px', fontSize: '15px' }}>
                        {data.completed} de {data.total} questões
                    </div>
                    <div style={{ 
                        display: 'flex',
                        gap: '16px',
                        fontSize: '14px'
                    }}>
                        <TimeInfo>
                            <Clock size={16} />
                            {data.timeSpent} estudados
                        </TimeInfo>
                        <span style={{ color: COLORS.textLight }}>
                            de {data.timeTotal} previstos
                        </span>
                    </div>
                </div>
            )
        }

        return (
            <div style={{ 
                display: 'flex',
                flexDirection: 'column',
                gap: '8px'
            }}>
                <TimeInfo>
                    <Clock size={16} />
                    {data.timeSpent} estudados
                </TimeInfo>
                <span style={{ color: COLORS.textLight, fontSize: '14px' }}>
                    Tempo previsto: {data.timeTotal}
                </span>
            </div>
        )
    }

    return (
        <Card>
            <Header>
                <IconWrapper>
                    {renderSessionIcon()}
                </IconWrapper>
                <Title>{data.mainTheme}</Title>
            </Header>

            <MetadataRow>
                <MetadataItem>
                    <Clock size={16} />
                    Último acesso: {data.lastAccess}
                </MetadataItem>
                <MetadataItem>
                    <Calendar size={16} />
                    {data.sessionOrigin}
                </MetadataItem>
                <MetadataItem 
                    onClick={toggleTags}
                    style={{ cursor: 'pointer' }}
                >
                    <TagIcon size={16} />
                    {data.topics.length} tópicos
                </MetadataItem>
            </MetadataRow>

            <TagsGrid expanded={showTags}>
                {data.topics.map(topic => (
                    <TopicTag key={topic}>{topic}</TopicTag>
                ))}
            </TagsGrid>

            <div style={{ 
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '20px'
            }}>
                {renderTimeInfo()}
                {renderProgressOrType()}
            </div>

            <ActionButtons>
                <div>
                    <IconButton title="Favoritar sessão">
                        <Star 
                            size={20}
                            fill={data.isFavorite ? COLORS.primary : 'none'}
                        />
                    </IconButton>
                    <IconButton title="Excluir sessão">
                        <Trash2 size={20} />
                    </IconButton>
                </div>
                <ContinueButton>
                    Continuar Estudando
                    <ChevronRight size={20} />
                </ContinueButton>
            </ActionButtons>
        </Card>
    )
}