import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import CloseIcon from './../assets/close-black.png';
import { ColumnCSS } from '../components/BasicComponents';
import { motion } from 'framer-motion'
import ReactDOM from 'react-dom'




const Button = styled.div`
    ${ColumnCSS}
    justify-content: center;


    // Importante porque é um botão
    border: none;
    cursor: pointer;

    // Firulas
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    background: white;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);

    &:hover {
        background: linear-gradient(135deg, #f5f5f5 0%, #f0f0f0 100%);
        scale: 1.1;
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    }

    // Tamanho
    height: 90%;
    aspect-ratio: 1 / 1; /* Define uma proporção de 1:1 (quadrado) */
    object-fit: cover; /* Ajusta o conteúdo se necessário */

    border-radius: 50%;


    &:active {
        transform: scale(0.95);
    }


`

const BttnImg = styled.img`
    height: 60%;
    aspect-ratio: 1 / 1; /* Define uma proporção de 1:1 (quadrado) */
    object-fit: cover; /* Ajusta o conteúdo se necessário */
`


const CloseImg = styled.img`
    width: 1.25em;
    height: 1.25em;

    @media (max-width: 600px) {
        width: 1em;
        height: 1em;
    }

`



const MotionOverlay = styled(motion.div)`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;

    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.3);
    z-index: 199;

        // Isso aqui precisa ser visto
    // conjuntamente a FloatContainer

    height: 100dvh;
`

const MotionExpandContent = styled(motion.div)`
    width: 70vw;
    max-width: 400px;
    padding: 1.5em;
    border-radius: 1.2em;
    background-color: white;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
    ${(props) =>
        props.theme.darkMode &&
        `
        background: rgb(51, 51, 51);
        border: 1px solid rgba(255, 255, 255, 0.1);
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
    `}
`



export default function FloatingButton({ icon, dialogOpen, children, expanded, onToggle }) {
    const containerRef = useRef(null)

    useEffect(() => {
        function handleClickOutside(event) {
            if (containerRef.current && !containerRef.current.contains(event.target) && !dialogOpen) {
                // event.stopPropagation()
                expanded && onToggle(false)
            }
        }

        if (expanded) {
            document.addEventListener('click', handleClickOutside, true)
            return () => document.removeEventListener('click', handleClickOutside, true)
        }
    }, [expanded, dialogOpen, onToggle])

    return (
        <>
            {expanded &&
                ReactDOM.createPortal(
                    <MotionOverlay
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        <MotionExpandContent
                            ref={containerRef}
                            initial={{ scale: 0.9, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            exit={{ scale: 0.9, opacity: 0 }}
                            transition={{ duration: 0.3 }}
                        >
                            {children}
                        </MotionExpandContent>
                    </MotionOverlay>,
                    document.body
                )
            }
    
            <Button onClick={() => onToggle(!expanded)}>
                {!expanded ? (
                    <BttnImg src={icon} />
                ) : (
                    <CloseImg src={CloseIcon} />
                )}
            </Button>
        </>
    )
    
}
