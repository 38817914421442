import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import AppContainer from '../app-container/AppContainer'
import { guaranteeSubscription } from '../firebase/firebaseUtils';
import Graph from './../assets/analytics.png'
import './StatisticsScreen.css'
import styled from 'styled-components'
import LastDaysStatistics from './LastDaysStatistics'
import GeneralUseStatistics from './GeneralUseStatistics'
import UserReviewsInfo from '../controllers/UserReviewsInfo'
import ThemeStatistics from './ThemeStatistics'
import ScreenContainerHeader from '../components/ScreenContainerHeader'
import OslerCard from '../components/OslerCard'



export default function StatisticsScreen() {
    const navigate = useNavigate()
    const user = useSelector(state => state.user.data)

    useEffect(() => {
        console.log("StatisticsScreen: checking subscription")
        guaranteeSubscription(user, navigate)
    }, [])



    /*
        Lógica: aqui a width é 100%, para dar o máximo de espaço aos gráficos.
        
        Nos subcomponentes, também deve ser 100%. O StatisticExplanationCard e o 
        ExhibitionTypeSelector estão fixados em 90%, para ficarem alinhados entre si e entre
        os demais. 
        
        Para reduzir a width dos gráficos (se desejado) deve ser colocado um componente ao redor
        deles.
    */

    return (

            <ScreenContainerHeader
                title = 'Estatísticas'
                icon = {Graph} >

                    <OslerCard>
                        { UserReviewsInfo.loaded &&
                            <GeneralUseStatistics  />
                        }
                        
                        <ThemeStatistics />

                        <LastDaysStatistics />

                    </OslerCard>
                            
            </ScreenContainerHeader>
    )
}