import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import OslerLogo  from './../assets/Logo.png'
import SignatureIcon from './../assets/signature.png'
import OslerLogoDark from './../assets/LogoDark.png'

import SignatureDark from './../assets/signatureDark.png'
import Menu from './../assets/menu.png'
import styled from 'styled-components'
import { expandedWidth, NavigationButtons } from './SideBar'
import { ColumnCSS } from '../components/BasicComponents'


const MenuContainer = styled.div`
    ${ColumnCSS}
    z-index: 1000;

    // É o que usamos em Sidebar, afinal tudo foi calculado vindo de lá
    width: ${expandedWidth}px !important;

    // Necessário para fixar no local correto
    // e garantir que alcance a altura setada.
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;

    padding: 1em 0.2em 2em 0.2em;
    overflow-y: auto;

    scrollbar-width: none;  /* Firefox */
    -ms-overflow-style: none;  /* IE/Edge */
    &::-webkit-scrollbar {
        display: none;  /* Chrome/Safari/Opera */
    }


    transform: translateX(${props => props.visible ? '0' : '-100%'});
    transition: transform 0.3s ease-out;



    ${props => props.theme.darkMode ? `
        background-color: rgba(61, 61, 61, 1);
        color: rgb(220, 220, 220);
    ` : `
        background-color: white;
    `}


`

const Overlay = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    z-index: 900;
    background-color: rgba(0, 0, 0, 0.75);


    overscroll-behavior: none;


    opacity: ${props => props.visible ? 1 : 0};
    pointer-events: ${props => props.visible ? 'auto' : 'none'};
    transition: opacity 0.3s ease-out;
`

const Logo = styled.img`
    width: 2em;

    @media (max-width: 768px) {
        width: 1.8em;
    }

    @media (max-width: 480px) {
        width: 1.6em;
    }
`




const Signature = styled.img`
    height: 1.8em;
    margin-left: 1em;

    @media (max-width: 768px) {
        height: 1.6em;
        margin-left: 0.8em;
    }

    @media (max-width: 480px) {
        height: 1.4em;
        margin-left: 0.6em;
    }
`


const MenuLogo = styled.img`
    width: 3em;
`

const SignatureSide = styled.img`
    margin: 0.5em 0 1em 0;
    width: ${expandedWidth * 2/3}px;
`


const Container = styled.div`
    width: 100vw;
    z-index: 900;
    display: flex;

    
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0.5em;


    ${props => props.theme.darkMode ? `
        background-color: rgba(61, 61, 61, 1);
        box-shadow: none;
    ` : `
        background-color: white;
        // box-shadow: 0 1px 1px rgba(0, 0, 0, 0.7);
    `}

    @media (max-width: 768px) {
        padding: 0.6em 0.8em;
    }
`


const MenuButton = styled.div`
    & > img {
        width: 1.8em;
    }

    @media (max-width: 768px) {
        padding: 0.4em;
        
        & > img {
            width: 1.6em;
        }
    }
`

const Brand = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`



export default function TopBar() {
    const darkMode = useSelector(state => state.theme.darkModeOn)
    const [ menuVisible, setMenuVisibible ] = useState(false)

    useEffect(() => {
        const images = [OslerLogo, OslerLogoDark, SignatureIcon, SignatureDark];
        images.forEach(image => {
            const img = new Image();
            img.src = image;
        });
    }, []);

    
    function showMenu() {
        setMenuVisibible (!menuVisible)
    }

    function closeMenu() {
        setMenuVisibible(false)
    }

    return (
        <div>

        {/* Precisa ser assim, sempre montado, e com uma animação para abrir, senão
        faz um flicker do caralho. */}
        <MenuContainer visible={menuVisible}>
            <MenuLogo src = {darkMode ? OslerLogoDark : OslerLogo} />
            <SignatureSide src = {darkMode ? SignatureDark : SignatureIcon} />
            <NavigationButtons
                useAnimation = {false}
                isTouch = {true}
                expanded = {true}
                showDarkMode = {true}
                afterClick = {closeMenu} />
        </MenuContainer>

        <Overlay 
            visible={menuVisible} 
            onClick={closeMenu} 
        />


            {/* Barra do topo */}
            <Container>
                <MenuButton onClick={showMenu}>
                    <img src={Menu} alt="Menu" />
                </MenuButton>

                <Brand>
                    <Logo src = {darkMode ? OslerLogoDark : OslerLogo} />
                    <Signature src = {darkMode ? SignatureDark : SignatureIcon} />
                </Brand>

                <div></div> {/* Spacer */}
            </Container>
        </div>
    )
}