import { useEffect, useRef, useState } from 'react'
import { useNavigate } from "react-router-dom"
import styled from "styled-components"


const ErrorFallback = styled.div`
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    min-height: 100vh
    padding: 2rem
    text-align: center
`

const ErrorTitle = styled.h1`
    color: ${props => props.theme.darkMode ? '#e74c3c' : '#c0392b'}
    margin-bottom: 1rem
`

const ErrorMessage = styled.p`
    color: ${props => props.theme.darkMode ? '#95a5a6' : '#7f8c8d'}
    margin-bottom: 1rem
`

const ReloadButton = styled.button`
    background-color: #3498db
    color: white
    border: none
    padding: 0.5rem 1rem
    border-radius: 4px
    cursor: pointer
    transition: background-color 0.2s

    &:hover {
        background-color: #2980b9
    }
`

export default function ErrorHandler({ error }) {
    const navigate = useNavigate()
    
    const handleReset = () => {
        navigate('/')
        window.location.reload()
    }

    return (
        <ErrorFallback>
            <ErrorTitle>Ops! Algo deu errado</ErrorTitle>
            <ErrorMessage>
                {error.message || 'Ocorreu um erro inesperado na aplicação'}
            </ErrorMessage>
            <ReloadButton onClick={handleReset}>
                Voltar ao início
            </ReloadButton>
        </ErrorFallback>
    )
}
