import { useEffect, useState } from "react"
import styled from "styled-components"
import StatisticsPie from "./StatisticsPie"
import Notebook from './../assets/tree/notebook.png'
import Dumbbell from './../assets/tree/dumbbell.png'

export const PiesContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;


    @media (max-width: 900px) {
        justify-content: space-between;
        padding: 0;
    }

    @media (max-width: 500px) {
        flex-direction: column;
        align-items: center;
        padding: 0;
    }
`

export default function LastDaysPies({ dailyData, testType }) {
    const [totalNewTests, setTotalNewTests] = useState(0)
    const [totalReviews, setTotalReviews] = useState(0)
    const [newsData, setNewsData] = useState([])
    const [reviewsData, setReviewsData] = useState([])

    useEffect(() => {
        const testConfigs = {
            'Flashcards': {
                colorScale: ["rgb(39, 121, 196)", "rgb(35, 146, 7)", "rgb(128, 128, 128)", "#8E0011"],
                labels: ["Fácil", "Normal", "Difícil", "Errou"],
                newKeys: [['newEasy'], ['newGood'], ['newHard'], ['newAgain']],
                reviewKeys: [
                    ['learningEasy', 'relearningEasy', 'reviewEasy'],
                    ['learningGood', 'relearningGood', 'reviewGood'],
                    ['learningHard', 'relearningHard', 'reviewHard'],
                    ['learningAgain', 'relearningAgain', 'reviewAgain']
                ],
                unit: ['card', 'cards'],
                percentageOfNew: ['dos novos respondidos no período'],
                percentageOfReview: ['das revisões respondidas no período']
            },
            'Residencia': {
                colorScale: ['#1e88e5', '#43a047', '#e53935', '#ffb300'],
                labels: ['Acertou, acaso', 'Acertou, sabia', 'Errou, distração', 'Errou, desconhecia'],
                newKeys: [['newRightGuess'], ['newRightConfident'], ['newWrongDistraction'], ['newWrongConcept']],
                reviewKeys: [['reviewRightGuess'], ['reviewRightConfident'], ['reviewWrongDistraction'], ['reviewWrongConcept']],
                unit: ['questão', 'questões'],
                percentageOfNew: ['dos novas respondidas no período'],
                percentageOfReview: ['das revisões respondidas no período']
            },
            'Combined': {
                combinedRightColor: 'rgb(35, 146, 7)',
                combinedWrongColor: '#8E0011'
            }
        };
        
        function prepareDataObject(D, labels, keys, colorScale, unit, percentageOf) {
            return labels.map((label, index) => ({
                label: label,
                number: keys[index].reduce((sum, key) => sum + (D[key] || 0), 0),
                color: colorScale[index],
                unit: unit,
                percentageOf: percentageOf
            })).reverse();
        }
        
        function calculateTotal(D, keys) {
            return keys.reduce((sum, key) => sum + (D[key] || 0), 0);
        }
        
        let newsDataTmp, reviewsDataTmp, totalNewTestsTmp, totalReviewsTmp;
        
        if (testType === 'Flashcards' || testType === 'Residencia') {
            const config = testConfigs[testType];
            const D = prepareData(testType);
        
            // Preparando os dados para novos testes e revisões
            newsDataTmp = prepareDataObject(D, config.labels, config.newKeys, config.colorScale, config.unit, config.percentageOfNew);
            reviewsDataTmp = prepareDataObject(D, config.labels, config.reviewKeys, config.colorScale, config.unit, config.percentageOfReview);
            
            // Calculando os totais de novos testes e revisões
            totalNewTestsTmp = calculateTotal(D, config.newKeys.flat());
            totalReviewsTmp = calculateTotal(D, config.reviewKeys.flat());
        
        } else {
            // Teste combinado de 'Residencia' e 'Flashcards'
            const resid = prepareData('Residencia');
            const cards = prepareData('Flashcards');
            const config = testConfigs.Combined;
        
            // Cálculo dos acertos e erros para novos testes
            let newRight = calculateTotal(resid, ['newRightConfident']) + calculateTotal(cards, ['newEasy', 'newGood', 'newHard']);
            let newWrong = calculateTotal(resid, ['newRightGuess', 'newWrongConcept', 'newWrongDistraction']) + calculateTotal(cards, ['newAgain']);
        
            newsDataTmp = [
                { label: 'Acertou', number: newRight, color: config.combinedRightColor },
                { label: 'Errou', number: newWrong, color: config.combinedWrongColor }
            ].reverse();
        
            // Cálculo dos acertos e erros para revisões
            let reviewRight = calculateTotal(resid, testConfigs['Residencia'].reviewKeys[1]) + calculateTotal(cards, testConfigs['Flashcards'].reviewKeys[1]);
            let reviewWrong = calculateTotal(resid, testConfigs['Residencia'].reviewKeys[0].concat(testConfigs['Residencia'].reviewKeys[2], testConfigs['Residencia'].reviewKeys[3])) 
                            + calculateTotal(cards, testConfigs['Flashcards'].reviewKeys[0].concat(testConfigs['Flashcards'].reviewKeys[2], testConfigs['Flashcards'].reviewKeys[3]));
        
            reviewsDataTmp = [
                { label: 'Acertou', number: reviewRight, color: config.combinedRightColor },
                { label: 'Errou', number: reviewWrong, color: config.combinedWrongColor }
            ].reverse();
        
            // Calculando os totais de novos testes e revisões
            totalNewTestsTmp = newRight + newWrong;
            totalReviewsTmp = reviewRight + reviewWrong;
        }


        setTotalNewTests(totalNewTestsTmp)
        setTotalReviews(totalReviewsTmp)
        setNewsData(newsDataTmp)
        setReviewsData(reviewsDataTmp)


    }, [dailyData, testType])



    function prepareData(type) {
        const d = dailyData[type]

        if (type === 'Flashcards') {
            const types = ['Again', 'Easy', 'Good', 'Hard']
            const keys = ['new', 'learning', 'relearning', 'review']
            
            const D = types.reduce((acc, type) => {
                keys.forEach(key => acc[`${key}${type}`] = d[`${key}${type}`] || 0)
                return acc
            }, {})

            return D
        } else {

            const categories = ['new', 'review']
            const labels = ['RightGuess', 'RightConfident', 'WrongConcept', 'WrongDistraction']
            
            const D = categories.reduce((acc, category) => {
                labels.forEach(label => acc[`${category}${label}`] = d[`${category}${label}`] || 0)
                return acc
            }, {})

            return D
        }
    }





    return (
        <PiesContainer>
            <StatisticsPie
                title    = {totalNewTests}
                subtitle = {'testes novos'}
                icon     = {Notebook}
                data     = {newsData}
            />

            <StatisticsPie
                title    = {totalReviews}
                subtitle = {'revisões feitas'}
                icon     = {Dumbbell}
                data     = {reviewsData}
            />
        </PiesContainer>
    )
}