import React, {useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import CloseIcon from './../assets/x-icon.png'
import ForwardIcon from './../assets/tree/forward.png'
import { formatNumber } from '../utils/Utils'
import { RowCSS } from '../components/BasicComponents'
import OslerCard from '../components/OslerCard'
import CustomStudyPill from './CustomStudyPill'


const StatsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;

    
    gap: 1em;
    margin-bottom: ${props => props.noMargin ? '0' : '1.6em'};

    @media (max-width: 1200px) and (min-width: 769px) {
        gap: 0.8em;
        margin-bottom: ${props => props.noMargin ? '0' : '1.4em'};
    }

    @media (max-width: 768px) {
        gap: 0.6em;
        margin-bottom: ${props => props.noMargin ? '0' : '1em'};
    }
`

const StartButton = styled.button`
    width: 100%;

    background: linear-gradient(135deg, #005fbb 0%, #4ea1f5 100%);
    color: white;
    border: none;

    border-radius: 1.4em; // Um pouco mais arredondado para mais sofisticação
    padding: 1.4em;
    font-size: 1.05em;
    font-weight: 600;
    letter-spacing: -0.015em;

    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    transition: all 0.4s cubic-bezier(0.25, 1, 0.5, 1);

    ${RowCSS}
    justify-content: space-between;


    gap: 0.8em;
    opacity: ${props => props.disabled ? 0.6 : 1};
    box-shadow: 0 6px 16px rgba(0, 95, 187, 0.2); // Leve sombra para dar profundidade

    &:hover {
        transform: ${props => props.disabled ? 'none' : 'translateY(-3px)'};
        box-shadow: ${props => props.disabled ? 'none' : '0 12px 24px rgba(0, 95, 187, 0.3)'};
        background: linear-gradient(135deg, #0075d9 0%, #60b3ff 100%);
    }

    &:active {
        transform: translateY(1px);
        box-shadow: 0 4px 12px rgba(0, 95, 187, 0.2);
    }

    img {
        width: 1.1em;
        height: 1.1em;
        opacity: 0.95;
    }

    @media (max-width: 1200px) and (min-width: 769px) {
        padding: 1.2em;
        border-radius: 1.2em;
        font-size: 1em;
        gap: 0.7em;

        &:hover {
            transform: ${props => props.disabled ? 'none' : 'translateY(-2px)'};
            box-shadow: ${props => props.disabled ? 'none' : '0 10px 20px rgba(0, 95, 187, 0.25)'};
        }

        img {
            width: 1em;
            height: 1em;
        }
    }

    @media (max-width: 768px) {
        padding: 1em;
        border-radius: 1em;
        font-size: 0.95em;
        gap: 0.6em;
        
        &:hover {
            transform: none;
            box-shadow: none;
        }
        
        img {
            width: 0.9em;
            height: 0.9em;
        }
    }
`

const Txt = styled.p`
    margin: 0;
    padding: 0;
`

const Num = styled.span`
    margin-left: 0.5em;
    font-size: 1em;
`

const Descriptor = styled.span`
    margin-left: 0.25em;
    font-size: 0.9em;
`


const EmptyMessage = styled.div`
    text-align: center;
    color: ${props => props.theme.darkMode ? 'rgb(160, 160, 160)' : '#64748b'};
    font-size: 0.95em;
    padding: 1.5em;
    line-height: 1.5;
    font-weight: 450;
    letter-spacing: -0.01em;

    @media (max-width: 1200px) and (min-width: 769px) {
        font-size: 0.9em;
        padding: 0em;
    }

    @media (max-width: 768px) {
        font-size: 0.9em;
        padding: 1em;
    }
`



const StartSection = styled(OslerCard)`
    width: 100%;

  background: white;
  border-radius: 1.4em;
  padding: 1.5em;


  
    @media (max-width: 1100px) {
        width: 50%;
    }


    @media (max-width: 800px) {
        width: 60%;
        padding: 1em;
    }


    @media (max-width: 600px) {
        width: 70%;
        padding: 1em;

        // PRECISA para reduzir o botão inteiro no celular
        // senão ele ocupa >30% da tela; isso aplica-se a todos os
        // campos, o que ajuda muito
        font-size: 0.85em;
    }
`



// O resto do componente permanece praticamente igual, só removemos toda a 
// complexidade do FloatingButton
export default function StartBttnLuxury({
    testType,
    pills,
    hasSelections,
    nTests,
    onStart
}) {

    const canStart = nTests > 0 || hasSelections
    return (
        <StartSection>
            {hasSelections ? (
                <>
                    <StatsContainer noMargin={!canStart}>
                        {pills.map((pill, i) => (
                            <CustomStudyPill key = {i} data = {pill} />
                        ))}
                    </StatsContainer>

                    {canStart ? (
                        <>

                            <StartButton onClick={onStart}>
                                <Txt>
                                    <Num>{formatNumber(nTests)} </Num>
                                    <Descriptor>{testType === 'Residencia' ? 'questões' : 'cards'}</Descriptor>
                                </Txt>
                                <img src={ForwardIcon} alt="Iniciar" />
                            </StartButton>
                        </>
                    ) : (
                        <EmptyMessage>
                            Não encontramos questões com esses filtros
                        </EmptyMessage>
                    )}
                </>
            ) : (
                <EmptyMessage>
                    Selecione temas, instituições ou anos para começar
                </EmptyMessage>
            )}
        </StartSection>
    )
}