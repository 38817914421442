import React, { useEffect, useState, useRef } from "react"
import styled from "styled-components"
import { statisticsDateToReadable } from '../utils/Utils'
import { useSelector } from 'react-redux' 
import CardsIcon from './../assets/flashcard.png'
import ResidIcon from './../assets/residencia.png'
import OslerNewDateChart from "./OslerNewDateChart";
import { ColumnCSS } from "../components/BasicComponents";
import { backgroundWhiteBlack, darkBackgroundPrimaryColor, darkBackgroundQuarternaryColor, darkBackgroundSecondaryColor, darkBackgroundTertiaryColor, darkTextPrimaryColor, darkTextSecondaryColor, darkTextTertiaryColor, textWhiteBlack } from "../tests/FlashcardsStyles"

const Container = styled.div`
    ${ColumnCSS}
    width: 100%;
    
    

    @media (max-width: 500px) {
        margin-top: 2em;
    }
`

export const GRAPHS_COLORS = {
    blue: {
        light: '#5490de',
        dark: '#5CA3FF'
    },

    darkBlue: {
        light: '#264063',
        dark: '#2784FF'
    },

    pink: {
        light: '#e33b50',
        dark: '#FF8291'
    },

    darkPink: {
        light: '#781f2a',
        dark: '#FF6375'
    }, 

    gray: {
        light: 'rgba(194, 194, 194, 0.6)',
        dark: 'rgba(222, 222, 222)'
    },

    darkGray: {
        light: 'rgba(112, 112, 112)',
        dark: 'rgba(163, 163, 163)'
    },

    // burgundy: {
    //     light: '#8e0011',
    //     dark: '#b50016'
    // },
}


export function getColor(darkMode, colorKey) {
    const color = GRAPHS_COLORS[colorKey]
    
    if (color) {
        return darkMode ? color.dark : color.light;
    } else {
        return 'purple'
    }
}


const TooltipContainer = styled.div`
    padding: 1em 2em 1em 2em;
    min-width: 200px;
    border-radius: 0.5em;


    ${props => props.theme.darkMode ? `
        background-color: ${darkBackgroundPrimaryColor};
    `:`
        background-color: white;    
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    `}

`

const TooltipTitle = styled.div`
    font-weight: bold;
    text-align: center;
    margin: 0;

    ${textWhiteBlack};
`

const TooltipSubtitle = styled.div`
    font-weight: bold;
    font-size: 0.85em;
    text-align: center;
    margin: 0 0 0.5em 0;

    ${props => props.color && `
        color: ${props.color};    
    `}
`


const TooltipRow = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 5px;
`;


const Comma = styled.span`
        ${props => props.theme.darkMode ? `color: ${darkTextSecondaryColor}` : `color: gray;`}

`

const IconWrapper = styled.span`
  margin-right: 5px;
`;

const Icon = styled.img`
    height: 1.25em;
    width: 1.25em;
`


const NewTooltip = ({ active, payload, darkMode, exhibitionType }) => {
  if (!active || !payload || !payload.length) return null;

  const datum = payload[0].payload;
  
  const renderContent = (count, color, label) => 
    count > 0 ? <span style={{ color: getColor(darkMode, color) }}>{count} {label}</span> : null;

  const renderRow = (icon, data, newColor, reviewColor) => {
    const [reviewCount, newCount] = data;
    const hasContent = reviewCount > 0 || newCount > 0;

    return (
      <TooltipRow>
        <IconWrapper>{<Icon src = {icon} />}</IconWrapper>
        {hasContent ? (
          <>
            {renderContent(newCount, newColor, 'novos')}
            {newCount > 0 && reviewCount > 0 && <Comma>,&nbsp;</Comma>}
            {renderContent(reviewCount, reviewColor, 'revisões')}
          </>
        ) : '😢'}
      </TooltipRow>
    );
  };

  return (
    <TooltipContainer>
      <TooltipTitle>{statisticsDateToReadable(datum.date)}</TooltipTitle>
      <TooltipSubtitle color = {getColor(darkMode, 'darkGray')}>{datum.line1} min</TooltipSubtitle>
      {exhibitionType === 'Flashcards' && datum.bar1 && renderRow(CardsIcon, datum.bar1, 'pink', 'darkPink')}
        {exhibitionType === 'Residencia' && datum.bar1 && renderRow(ResidIcon, datum.bar1, 'blue', 'darkBlue')}
        {exhibitionType === 'Ambos' && datum.bar1 && renderRow(CardsIcon, datum.bar1, 'pink', 'darkPink')}
        {exhibitionType === 'Ambos' && datum.bar2 && renderRow(ResidIcon, datum.bar2, 'blue', 'darkBlue')}
    </TooltipContainer>
  );
};


export default function LastDaysChart({dailyData, exhibitionType, style, shouldRotate = true}) {

    const [ajustedDailyData, setAjustedDailyData] = useState(undefined)
    // const [dailyTimeSpent, setDailyTimeSpent] = useState(undefined)

    const darkMode = useSelector(state => state.theme.darkModeOn);
    const [metadata, setMetadata] = useState(false)


    useEffect(() => {
        function sumIntoNewsReviews(data, newsKeys, reviewsKeys) {
            const tmp = data.map(dataItem => {
                let novos = 0;
                let revisoes = 0;
                let timeSpent = 0;
    
                let useData = dataItem['data']['Total'] || false;
        
                if (useData) {
                    newsKeys.forEach(key => {
                        novos += useData[key] || 0;
                    });
        
                    reviewsKeys.forEach(key => {
                        revisoes += useData[key] || 0;
                    });
        
                    // Medimos em segundos, registramos em minutos -- não há razão para casa decimal
                    // Não usamos .toFixed(0) pois gera uma string. E sempre colocamos no mínimo
                    // 1 minuto, senão o gráfico perde lógica: parece exagero, mas pode ocorrer
                    // no primeiro uso do app.
                    timeSpent = useData.timeSpent ? Math.ceil(useData.timeSpent / 60) : 1;
                }
        
                // A revisão é o bar[0], o novo é o  bar[1]
                return {
                    date: dataItem.date_str,
                    bar1: [revisoes, novos],
                    line1: timeSpent,
                }
            });
        
            return tmp;
        }


        function combineData(cardsData, residData) {
            return cardsData.map((cardItem, index) => {
                const residItem = residData[index]

                if (exhibitionType === 'Flashcards') {
                    return {
                        date: cardItem.date,
                        bar1: cardItem.bar1,
                        line1: cardItem.line1
                    }
                } else if (exhibitionType === 'Residencia') {
                    return {
                        date: cardItem.date,
                        bar1: residItem.bar1,
                        line1: residItem.line1,
                    }
                } else {
                    // ambos
                    return {
                        date: cardItem.date,
                        bar1: cardItem.bar1,
                        bar2: residItem.bar1,
                        line1: cardItem.line1 + residItem.line1,
                    }
                }
            })
        }

                
        const cardsData = sumIntoNewsReviews(
            dailyData['Flashcards'],
            ['newAgain', 'newEasy', 'newGood', 'newHard'],
            ['learningAgain', 'learningEasy', 'learningGood', 'learningHard',
                'relearningAgain', 'relearningEasy', 'relearningGood', 'relearningHard',
                'reviewAgain', 'reviewEasy', 'reviewGood', 'reviewHard'    
            ]
        )


        const residData = sumIntoNewsReviews(
            dailyData['Residencia'],
            ['newRightConfident', 'newRightGuess', 'newWrongConcept', 'newWrongDistraction'],
            ['reviewRightConfident', 'reviewRightGuess', 'reviewWrongConcept', 'reviewWrongDistraction']
        )




        const combinedData = combineData(cardsData, residData)
        setAjustedDailyData(combinedData)

        const metadataTmp = {
            line1_color: getColor(darkMode, 'darkGray'),
            line1_label: 'Tempo'
        }

        if (exhibitionType === 'Flashcards') {
            metadataTmp['bar1_labels'] = ['Revisões (cards)', 'Novos (cards)']
            metadataTmp['bar1_colors'] = [getColor(darkMode, 'darkPink'), getColor(darkMode, 'pink')]
        }
        else if (exhibitionType === 'Residencia') {
            metadataTmp['bar1_labels'] = ['Revisões (questões)', 'Novas (questões)']
            metadataTmp['bar1_colors'] = [getColor(darkMode, 'darkBlue'), getColor(darkMode, 'blue')]
        }
        else {
            metadataTmp['bar1_labels'] = ['Revisões (cards)', 'Novos (cards)']
            metadataTmp['bar1_colors'] = [getColor(darkMode, 'darkPink'), getColor(darkMode, 'pink')]
            metadataTmp['bar2_labels'] = ['Revisões (questões)', 'Novas (questões)']
            metadataTmp['bar2_colors'] = [getColor(darkMode, 'darkBlue'), getColor(darkMode, 'blue')]
        }

        

        setMetadata(metadataTmp)

    }, [exhibitionType])



    if (!ajustedDailyData) {
        return <></>
    }


    return (
        <Container style = {style}>
            <OslerNewDateChart
                data = {ajustedDailyData}
                metadata = {metadata}
                shouldRotate = {shouldRotate}
                tooltip = {(props) => <NewTooltip {...props} 
                                            darkMode = {darkMode}
                                            exhibitionType={exhibitionType} />}
                />
        </Container>
    )

}