// Outubro, 2024
import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import Search from './../assets/search.png'
import X from './../assets/x-icon.png'
import { textWhiteBlack } from '../tests/FlashcardsStyles'
import debounce from 'lodash/debounce'
import { RowCSS } from '../components/BasicComponents'
import { useWindowWidth } from '../components/useWindowWidth'


const Container = styled.div`
    ${RowCSS}
    justify-content: space-between;

    // Fica mais fácil posicionar os elementos, que são poucos
    position: relative;

    ${props => props.theme.darkMode ? `
        background-color: rgb(90, 90, 90);
        color: rgb(200, 200, 200);
    ` : `
        background-color: #f5f5f5;
        color: black;
    `}


    padding: 0.5em;
    border-radius: 1em;
    width: 100%;

`


const Left = styled.div`
    ${RowCSS}
    width: 95%;
    max-width: 95%;
`


const Right = styled.div`
    ${RowCSS}
    width: 5%;
    min-width: 2em;
    height: 1.5em;
`


const Icon = styled.img`
    max-height: 1.5em;
    position: absolute;
    left: 0.8em;
    filter: ${props => props.theme.darkMode ? 'brightness(0.9)' : 'none'};
    opacity: ${props => props.theme.darkMode ? 0.8 : 1};

    @media (max-width: 500px) {
        max-height: 1.2em;
    }
`

const Input = styled.input`
    font-size: 0.9em;
    padding: 0.4em 0 0.4em 2.6em;
    border-radius: 0.3em;
    outline: none;
    border: none;

    margin-left: 0.5em;

    background-color: transparent;
    width: 100%;

    ${textWhiteBlack}

    @media (max-width: 500px) {
        margin: 0 0 0 0.8em;
        height: 2.5em;
        font-size: 0.8em;
        padding-left: 2em;
    }

    &::placeholder {
        color: #999;
    }
`


const CancelIcon = styled.img`
    cursor: pointer;
    max-height: 0.8em;
    position: absolute;
    right: 1em;
    filter: ${props => props.theme.darkMode ? 'brightness(0.9) invert(1)' : 'none'};
    opacity: ${props => props.theme.darkMode ? 0.8 : 1};

    &:hover {
        opacity: ${props => props.theme.darkMode ? 1 : 0.7};
    }
`


export default function SearchBar({ currentString, setSearch, visible, showBorder, placeholder, style }) {
    const [localValue, setLocalValue] = useState(currentString)
    const inputRef = useRef(null)


    useEffect(() => {
        console.log(` * SearchBar: o termo buscado é "${currentString}"`)
        setLocalValue(currentString)
    }, [currentString])



    const width = useWindowWidth()

    useEffect(() => {
        const handleScroll = () => {
            // No mobile e em tablets, fechamos a barra ao scroll
            if (width <= 900 && document.activeElement === inputRef.current) {
                inputRef.current.blur()
            }
        }

        window.addEventListener('touchmove', handleScroll)
        return () => {
            window.removeEventListener('touchmove', handleScroll)
        }
    }, [width])


    const debouncedSearch = useCallback(
        // O objetivo do debounce é não iniciar a pesquisa assim que o usuário começar a digitar,
        // senão a cada letra digitada, uma nova pesquisa seria feita.
        //
        // o callBack evita que seja criado várias vezes. mas note que ele é cancelado sempre que o
        // usuário digita mais, até a hora que ele para de digitar, e então a função é chamada "interval"
        // após esse momento
        debounce((value) => {
            console.log(` * SearchBar: atualizando para "${value}"`)
            setSearch(value)
        }, 400),
        [setSearch]
    )

    useEffect(() => {
        return () => {
            debouncedSearch.cancel()
        }
    }, [debouncedSearch])

    function handleChange(e) {
        const value = e.target.value
        setLocalValue(value)
        debouncedSearch(value)
    }

    function handleClear() {
        setLocalValue('')
        setSearch('')
        debouncedSearch.cancel()
    }

    if (!visible) return null

    return (
        <Container showBorder={showBorder} style={style}>
            <Left>
                <Icon src={Search} alt='Search icon' />
                <Input  
                    ref={inputRef}
                    type='text'
                    value={localValue}
                    onChange={handleChange}
                    placeholder={placeholder || 'Pesquisar...'}
                />
            </Left>
            <Right>
                {localValue && localValue !== '' && (
                    <CancelIcon src={X} onClick={handleClear} alt='Clear search' />
                )}
            </Right>
        </Container>
    )
}