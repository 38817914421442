import styled, {css} from "styled-components";
import { backgroundWhiteBlack } from "../tests/FlashcardsStyles";



const breakpoints = {
    smallMobile: '320px',
    mobile: '576px',
    smallTablet: '768px',
    bigTablet: '992px',
    desktop: '1200px',
};



export const GlassyContainer = css`
    background: rgba(255, 255, 255, 0.2);
    
    /* Modern browsers */
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
`



export const OslerScrollBar = (props) => css`

    

    
    &::-webkit-scrollbar {
        width: 4px;
        height: 90%;
        max-height: 40vh;
    }

    &::-webkit-scrollbar-track {
        ${backgroundWhiteBlack}   


        // Margens condicionais baseadas na prop
        margin-top: ${props?.verticalMargin ? '0.75em' : '0'};
        margin-bottom: ${props?.verticalMargin ? '0.75em' : '0'};
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(142, 0, 17, 0.8);
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: rgba(160, 29, 14, 0.8);
    }

    @media (max-width: 500px) {
        &::-webkit-scrollbar {
            width: 2px;
        }
    }
`


export const ColumnCSS = css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`


export const Column = styled.div`
    ${ColumnCSS}

    ${props => css`${props.style}`}
`


export const RowCSS = css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`


export const Row = styled.div`
    ${RowCSS}
`


export const SelectableText = css`
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
`
