
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts'
import { 
    Book, 
    BookOpen, 
    Bell, 
    Coffee, 
    ArrowRight, 
    Calendar, 
    Award, 
    Brain, 
    TrendingUp, 
    Target, 
    Clock,
    ChevronRight,
    Star,
    Timer,
    Tag,
    User,
    BarChart2,
    Check
} from 'lucide-react'

// Assets da plataforma
import ResidenciaIcon from './../assets/residencia.png'
import FlashcardIcon from './../assets/flashcard.png'
import OslerLogo from './../assets/Logo.png'
import Signature from './../assets/signature.png'
import DumbbellIcon from './../assets/dumbbell.png'

import { useSelector, useDispatch } from 'react-redux'
import { setIsLoading } from './../redux/loadingSlice'
import session from './../controllers/Session'
import sessionBuilder from './../controllers/SessionBuilder'
import userReviewsInfo from './../controllers/UserReviewsInfo'
import { useNavigate } from "react-router-dom";
import { measureTime, toastMsg } from '../utils/Utils';
import './MainScreen.css'
import AppContainer from './../app-container/AppContainer';
import { guaranteeSubscription } from '../firebase/firebaseUtils';
import PendingReviewsCard from './PendingReviewsCard';
import IsLoading from './IsLoading';
import TestsDoneGraph from '../statistics/TestsDoneGraph'
import PreviousSessionCard from './PreviousSessionCard'
import SurpriseSessionCard from './SurpriseSessionCard'
import NoticeCard from './NoticeCard'
import OslerData, { KEYS } from '../controllers/OslerData'
import { backgroundWhiteBlack } from '../tests/FlashcardsStyles'
import { db } from '../firebase/firebase-setup'
import { loadAppData } from '../services/LoadAppData'
import personalNotes from '../controllers/PersonalNotes'
import MistakesJournalController from '../controllers/MistakesJournalController'
import Notebook from '../controllers/NotebookController'
import TitleCard from '../custom/TitleCard'
import PendingReviews from './PendingReviews'
import LastSessions from './LastSessions'
import LastSessionController from '../controllers/LastSessionController'
import Session from './../controllers/Session'
import MainScreenHeader from './MainScreenHeader'
import { ColumnCSS, RowCSS } from '../components/BasicComponents'

import FlameIcon from './../assets/flame.png'

// Container principal com background sutilmente texturizado
const Container = styled.div`
    ${ColumnCSS}

    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    padding: 32px;
    
    background-image: linear-gradient(to right, #f8fafc 1px, transparent 1px),
                      linear-gradient(to bottom, #f8fafc 1px, transparent 1px);
    
    
    
    background-size: 24px 24px;
`
const SectionHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
`

const SectionTitle = styled.h2`
    font-size: 1.5rem;
    font-weight: 600;
    color: #2d3748;
    display: flex;
    align-items: center;
    gap: 12px;
`




const StatCard = styled.div`
    background: ${props => props.$highlighted ? 
        'linear-gradient(135deg, #ebf8ff 0%, #e6fffa 100%)' : 
        'white'};

    border: 1px solid ${props => props.$highlighted ? '#90cdf4' : '#e2e8f0'};

    border-radius: 16px;
    padding: 24px;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease;

    &:hover {
        transform: translateY(-4px);
        box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 100px;
        height: 100%;
        background: linear-gradient(90deg, 
            transparent, 
            ${props => props.$highlighted ? 
                'rgba(235, 248, 255, 0.8)' : 
                'rgba(247, 250, 252, 0.8)'});
        transform: skewX(-15deg) translateX(100%);
        transition: transform 0.5s ease;
    }

    &:hover::after {
        transform: skewX(-15deg) translateX(-200%);
    }
`

// Seções base com design atualizado
const Section = styled.section`
    background: white;
    border-radius: 24px;
    padding: 32px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
    }
`

// Últimas sessões com visual mais elaborado
const SessionsGrid = styled.div`
    display: grid;
    gap: 16px;
`

const SessionCard = styled.div`
    background: white;
    border: 1px solid #e2e8f0;
    border-radius: 16px;
    padding: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;

    &:hover {
        transform: translateX(8px);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        border-color: #90cdf4;
    }

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 4px;
        background: ${props => props.$type === 'card' ? '#4299e1' : '#48bb78'};
        border-radius: 4px 0 0 4px;
    }
`

const SessionHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    gap: 16px;
`

const SessionInfo = styled.div`
    flex: 1;
`

const SessionType = styled.div`
    font-weight: 600;
    color: #2d3748;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 4px;

    img {
        width: 20px;
        height: 20px;
        opacity: 0.9;
    }
`

const SessionMeta = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    font-size: 0.9rem;
    color: #718096;
`

const SessionMetaItem = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
`

const SessionTags = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 12px;
`

const SessionTag = styled.span`
    font-size: 0.8rem;
    padding: 4px 12px;
    background: ${props => props.$primary ? '#ebf8ff' : '#f0fff4'};
    color: ${props => props.$primary ? '#3182ce' : '#38a169'};
    border-radius: 20px;
    display: flex;
    align-items: center;
    gap: 4px;
`

const SessionProgress = styled.div`
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #f7fafc;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px solid ${props => props.completed > 75 ? '#48bb78' : 
                                props.completed > 50 ? '#4299e1' : 
                                props.completed > 25 ? '#ed8936' : '#e53e3e'};
`


const ProgressValue = styled.div`
    font-size: 1.2rem;
    font-weight: 600;
    color: #2d3748;
`

const ProgressLabel = styled.div`
    font-size: 0.7rem;
    color: #718096;
`

// Continua na próxima mensagem...

// Quick Actions com design mais elaborado
const QuickActionsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
`

const ActionCard = styled.button`
    display: flex;
    align-items: center;
    padding: 24px;
    background: ${props => props.$primary ? 'linear-gradient(135deg, #1a365d 0%, #2c5282 100%)' : 'white'};
    border: 1px solid ${props => props.$primary ? 'transparent' : '#e2e8f0'};
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: left;
    position: relative;
    overflow: hidden;
    
    &:hover {
        transform: translateY(-4px);
        box-shadow: ${props => props.$primary ? 
            '0 12px 24px rgba(26, 54, 93, 0.3)' : 
            '0 12px 24px rgba(0, 0, 0, 0.1)'};
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 80px;
        background: ${props => props.$primary ? 
            'linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.1))' : 
            'linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.02))'};
        transform: skewX(-15deg) translateX(100%);
        transition: transform 0.5s ease;
    }

    &:hover::after {
        transform: skewX(-15deg) translateX(-180%);
    }
`

const ActionIcon = styled.div`
    width: 56px;
    height: 56px;
    border-radius: 16px;
    background: ${props => props.$primary ? 'rgba(255, 255, 255, 0.15)' : '#f7fafc'};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    transition: transform 0.3s ease;

    img {
        width: 32px;
        height: 32px;
        filter: ${props => props.$primary ? 'brightness(0) invert(1)' : 'none'};
        transition: transform 0.3s ease;
    }

    ${ActionCard}:hover & {
        transform: scale(1.1);
    }
`

const ActionContent = styled.div`
    color: ${props => props.$primary ? 'white' : '#2d3748'};
    flex: 1;
`

const ActionTitle = styled.div`
    font-weight: 600;
    font-size: 1.1rem;
    margin-bottom: 6px;
`

const ActionDescription = styled.div`
    font-size: 0.9rem;
    opacity: 0.9;
    display: flex;
    align-items: center;
    gap: 6px;
`

const ActionProgress = styled.div`
    margin-top: 8px;
    height: 4px;
    background: ${props => props.$primary ? 'rgba(255, 255, 255, 0.2)' : '#edf2f7'};
    border-radius: 2px;
    overflow: hidden;
`

const ProgressBar = styled.div`
    width: ${props => props.value}%;
    height: 100%;
    background: ${props => props.$primary ? 'rgba(255, 255, 255, 0.8)' : '#4299e1'};
    border-radius: 2px;
    transition: width 0.3s ease;
`

// Deck do Dia com design premium
const DailyDeck = styled(Section)`
    background: linear-gradient(135deg, #4299e1 0%, #667eea 100%);
    color: white;
    position: relative;
    overflow: hidden;
    padding: 40px;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 300px;
        height: 300px;
        background: radial-gradient(circle, rgba(255,255,255,0.1) 0%, transparent 70%);
        transform: translate(50%, -50%);
    }

    &::after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        width: 160px;
        height: 160px;
        background: url(${OslerLogo});
        background-size: contain;
        opacity: 0.1;
        transform: translate(30%, 30%);
    }
`

const DeckContent = styled.div`
    position: relative;
    z-index: 2;
`

const DeckHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 24px;
`

const DeckIcon = styled.div`
    width: 48px;
    height: 48px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const DeckInfo = styled.div`
    flex: 1;
`

const DeckTitle = styled.h3`
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 4px;
`

const DeckMeta = styled.div`
    font-size: 0.9rem;
    opacity: 0.9;
    display: flex;
    align-items: center;
    gap: 12px;
`

const DeckDescription = styled.p`
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 32px;
    opacity: 0.95;
`

const DeckStats = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    margin-bottom: 32px;
`

const DeckStat = styled.div`
    background: rgba(255, 255, 255, 0.1);
    padding: 16px;
    border-radius: 12px;
    text-align: center;
`

const StatNumber = styled.div`
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 4px;
`

const StatLabel = styled.div`
    font-size: 0.9rem;
    color: #718096;
`

const StatTrend = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 0.875rem;
    color: ${props => props.$positive ? '#38a169' : '#e53e3e'};
    margin-top: 12px;

    svg {
        transition: transform 0.3s ease;
    }

    ${StatCard}:hover & svg {
        transform: translateY(-2px);
    }
`

// Distribuição por Especialidade com visual aprimorado
const SpecialtyDistribution = styled.div`
    margin-top: 32px;
`

const DistributionHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
`

const DistributionTitle = styled(SectionTitle)`
    display: flex;
    align-items: center;
    gap: 12px;
`

const DistributionGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 32px;
    align-items: center;
`

const PieChartContainer = styled.div`
    height: 240px;
    position: relative;
    margin: -20px;
`

const DistributionLegend = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`

const LegendItemEnhanced = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 8px 16px;
    border-radius: 8px;
    transition: all 0.3s ease;
    cursor: pointer;

    &:hover {
        background: #f7fafc;
        transform: translateX(4px);
    }
`

const LegendColor = styled.div`
    width: 16px;
    height: 16px;
    border-radius: 4px;
    background: ${props => props.color};
`

const LegendInfo = styled.div`
    flex: 1;
`

const LegendTitle = styled.div`
    font-weight: 500;
    color: #2d3748;
    margin-bottom: 2px;
`

const LegendValue = styled.div`
    font-size: 0.875rem;
    color: #718096;
    display: flex;
    align-items: center;
    gap: 8px;
`

const Notice = styled.div`
    background: white;
    border-radius: 16px;
    padding: 20px;
    border: 1px solid #feebc8;
    transition: all 0.3s ease;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    &:hover {
        transform: translateX(8px);
        box-shadow: 0 4px 12px rgba(251, 211, 141, 0.2);
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 4px;
        height: 100%;
        background: ${props => props.$type === 'achievement' ? '#48bb78' : 
                              props.$type === 'content' ? '#4299e1' : 
                              props.$type === 'event' ? '#ed8936' : '#e53e3e'};
        border-radius: 2px;
    }
`


// Biblioteca Osleriana com design elegante
const OslerLibrary = styled(Section)`
    background: linear-gradient(135deg, #f7fafc 0%, #edf2f7 100%);
`

const LibraryGrid = styled.div`
    display: grid;
    gap: 20px;
`

const LibraryArticle = styled.div`
    background: white;
    border-radius: 16px;
    padding: 24px;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    border: 1px solid #e2e8f0;

    &:hover {
        transform: translateX(8px);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
        border-color: #90cdf4;
    }
`


const ArticleHeader = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 16px;
    margin-bottom: 16px;
`

const ArticleIcon = styled.div`
    width: 48px;
    height: 48px;
    border-radius: 12px;
    background: #ebf8ff;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease;

    ${LibraryArticle}:hover & {
        transform: scale(1.1);
    }
`

const ArticleInfo = styled.div`
    flex: 1;
`

const ArticleTitle = styled.h4`
    font-size: 1.2rem;
    color: #2d3748;
    font-weight: 600;
    margin-bottom: 8px;
    line-height: 1.4;
`

const ArticleMeta = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    font-size: 0.85rem;
    color: #718096;
`

const MetaItem = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
`

const ArticlePreview = styled.p`
    color: #4a5568;
    font-size: 0.95rem;
    line-height: 1.6;
    margin-bottom: 16px;
`

const ArticleTags = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
`

const ArticleTag = styled.span`
    font-size: 0.8rem;
    padding: 4px 12px;
    background: #f7fafc;
    color: #4a5568;
    border-radius: 20px;
    display: flex;
    align-items: center;
    gap: 4px;
`


// Analytics Section com design mais elaborado
const AnalyticsSection = styled(Section)`
    background: white;
    padding: 32px;
`

const AnalyticsHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
`

const AnalyticsTitle = styled(SectionTitle)`
    display: flex;
    align-items: center;
    gap: 12px;
`

const ChartContainer = styled.div`
    height: 280px;
    margin: 0 -16px;
    padding: 16px 0;
`

const ChartLegend = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    margin-top: 16px;
`

const LegendItem = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 0.9rem;
    color: #4a5568;
`

const LegendDot = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: ${props => props.color};
`

const StatsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin: 32px 0;
`


const StatContent = styled.div`
    position: relative;
    z-index: 2;
`

const StatHeader = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
`

const StatIconWrapper = styled.div`
    width: 48px;
    height: 48px;
    border-radius: 12px;
    background: ${props => props.$highlighted ? 
        'rgba(66, 153, 225, 0.2)' : 
        '#f7fafc'};
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease;

    ${StatCard}:hover & {
        transform: scale(1.1);
    }
`

const StatValue = styled.div`
    font-size: 2rem;
    font-weight: 700;
    color: #2d3748;
    margin: 16px 0 8px;
`

const DeckButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    width: 100%;
    padding: 16px;
    background: rgba(255, 255, 255, 0.1);
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: 12px;
    color: white;
    font-weight: 600;
    font-size: 1.1rem;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
        background: rgba(255, 255, 255, 0.2);
        transform: translateY(-2px);
    }

    svg {
        transition: transform 0.3s ease;
    }

    &:hover svg {
        transform: translateX(4px);
    }
`




const MainRow = styled.div`
    ${RowCSS}    
    width: 100%;
    justify-content: space-around;
    margin: 2em 0 1em 0;
`


// Continua na próxima mensagem com a implementação do componente principal e demais elementos...

export default function HomeScreen() {
    // Dados de exemplo para o progresso do usuário
    const [progressData] = useState([
        { date: 'Seg', cards: 45, questions: 15, total: 60 },
        { date: 'Ter', cards: 52, questions: 18, total: 70 },
        { date: 'Qua', cards: 48, questions: 22, total: 70 },
        { date: 'Qui', cards: 70, questions: 25, total: 95 },
        { date: 'Sex', cards: 61, questions: 30, total: 91 },
        { date: 'Sab', cards: 85, questions: 28, total: 113 },
        { date: 'Dom', cards: 78, questions: 35, total: 113 },
    ])

    // Dados de distribuição por especialidade
    const specialtyData = [
        { name: 'Clínica Médica', value: 35, color: '#4299e1', trend: '+12%', items: 248 },
        { name: 'Cardiologia', value: 25, color: '#48bb78', trend: '+8%', items: 176 },
        { name: 'Pediatria', value: 20, color: '#ed8936', trend: '+15%', items: 142 },
        { name: 'Cirurgia', value: 15, color: '#667eea', trend: '+5%', items: 106 },
        { name: 'Outras', value: 5, color: '#a0aec0', trend: '+3%', items: 35 }
    ]


    const oslerQuotes = [
        "Ouça ao paciente, ele está te dizendo o diagnóstico.",
        "A medicina é a ciência da incerteza e a arte da probabilidade.",
        "O bom médico trata a doença; o grande médico trata o paciente que tem a doença.",
        "Observe, registre, tabule, comunique. Use seus cinco sentidos.",
        "A primeira tarefa do médico é educar as massas para não tomarem remédios.",
        "O jovem médico começa a vida com 20 medicamentos para cada doença, e o velho médico termina a vida com um medicamento para 20 doenças.",
        "É mais importante saber que tipo de pessoa tem a doença do que saber que tipo de doença a pessoa tem.",
        "A maior esperança para o progresso terapêutico reside no poder de observar."
    ]

    // Dados dos avisos
    const notices = [
        {
            id: 1,
            type: 'achievement',
            title: 'Conquista Desbloqueada!',
            content: 'Você completou 30 dias consecutivos de estudo. Sua dedicação está construindo uma base sólida de conhecimento.',
            time: 'Agora mesmo',
            icon: <Award size={20} />,
            action: 'Ver todas as conquistas'
        },
        {
            id: 2,
            type: 'content',
            title: 'Novo Conteúdo Disponível',
            content: 'Adicionamos 200 novos flashcards de Clínica Médica, focados em diagnóstico diferencial.',
            time: '2 horas atrás',
            icon: <BookOpen size={20} />,
            action: 'Começar agora'
        },
        {
            id: 3,
            type: 'event',
            title: 'Webinar: Método Osler',
            content: 'Não perca nossa próxima sessão sobre como maximizar seu aprendizado com o método Osler.',
            time: '5 horas atrás',
            icon: <Target size={20} />,
            action: 'Registrar-se'
        }
    ]

    const [currentQuote] = useState(() => {
        const randomIndex = Math.floor(Math.random() * oslerQuotes.length)
        return oslerQuotes[randomIndex]
    })


    // Dados da biblioteca
    const libraryArticles = [
        {
            id: 1,
            title: 'A Arte da Anamnese: Lições de William Osler',
            preview: 'Descubra como o método clínico de Osler revolucionou o ensino da medicina e continua relevante até hoje.',
            readTime: '8 min',
            author: 'Dr. André Santos',
            tags: ['Método Clínico', 'História da Medicina'],
            icon: <Book size={24} color="#4299e1" />
        },
        {
            id: 2,
            title: 'Medicina Baseada em Evidências: Um Guia Prático',
            preview: 'Como integrar as melhores evidências científicas à prática clínica diária sem perder a essência do cuidado ao paciente.',
            readTime: '12 min',
            author: 'Dra. Maria Silva',
            tags: ['MBE', 'Prática Clínica'],
            icon: <BookOpen size={24} color="#4299e1" />
        }
    ]

    const user = useSelector(state => state.user.data)
    let navigate = useNavigate();
    const dispatch = useDispatch()


    useEffect(() => {
        console.log("MainScreen: checking subscription")
        guaranteeSubscription(user, navigate)
    }, [])
    
    const [downloadedReviewInfo, setDownloadedReviewInfo] = useState(false)
    const [downloadedTodayInfo, setDownloadTodayInfo] = useState(false)


    useEffect(() => {
        async function loadMain() {         
            dispatch( setIsLoading(true) )

            try {
                console.log('Vamos carregar')
                await loadAppData(user)

                // await OslerData.start(user)

                // não tinha await antes?! ?! ?! 
                // await measureTime(() => userReviewsInfo.start(), 'UserReviewsInfo(): started in')


                const tests_per_tag = userReviewsInfo.getTestsPerTag()
                sessionBuilder.prepare(user, userReviewsInfo, tests_per_tag) 
                Session.prepare(user)

                personalNotes.construct(user)
                MistakesJournalController.construct(user)
                Notebook.construct(user)
                LastSessionController.prepare(user, userReviewsInfo)

                setDownloadedReviewInfo(true)    
                setDownloadTodayInfo(true)    



                dispatch( setIsLoading(false) )

            }
            catch (error) {
                navigate('/error', { state: { msg: 'loadMain(): error', data: error } });
            }
        }

        console.log('MainScreen: starting...')
        userReviewsInfo.construct(user, ['Flashcards', 'Residencia'])
        loadMain()
    }, [])



    


    function moveToTests() {
        navigate("/test")
        dispatch( setIsLoading(false) )
    }


    // Trocar por spinner no futuro
    if (!downloadedReviewInfo || !downloadedTodayInfo) return
    return (
        <Container>

            <MainScreenHeader />

            <MainRow>
                <NoticeCard />
            </MainRow>

            <MainRow>
                <PendingReviews />

                <SurpriseSessionCard />
            </MainRow>

            <MainRow>
                <LastSessions />
            </MainRow>

            <MainRow>
                <TitleCard
                    icon = {FlameIcon}
                    title = 'Última semana'>

                        <TestsDoneGraph />

                    </TitleCard>
            </MainRow>
            
            </Container>
        )
}