import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ColumnCSS, OslerScrollBar, RowCSS } from '../components/BasicComponents'
import { darkBackgroundPrimaryColor } from './FlashcardsStyles'
import { useHotkeys } from 'react-hotkeys-hook'
import CloseGrayIcon from './../assets/close-gray.png'
import CloseBlackIcon from './../assets/close-black.png'
import OslerButton from '../components/OslerButton'
import { AnimatePresence, motion } from 'framer-motion'
import ReactDOM from 'react-dom'


const Backdrop = styled(motion.div)`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${props => props.theme.darkMode ? 
        'rgba(0, 0, 0, 0.5)' : 
        'rgba(0, 0, 0, 0.2)'};
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    display: flex;
    justify-content: center;
    align-items: center;

    // Não pode ultrapassar o loading overlay, cuidado
    // lá está como 500
    z-index: 400;

    @media (max-width: 768px) {
        padding: 0.5em;
    }
`


const TitleHeader = styled.div`
    ${RowCSS}
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 1.25em; // Reduzi de 2em para 1.25em

    @media (max-width: 768px) {
        margin-bottom: 1em;
    }
`

const TitleText = styled.h1`
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 1.5em;
    margin: 0;
    font-weight: 700;
    letter-spacing: -0.03em;

    ${props => props.theme.darkMode ? `
        background: linear-gradient(90deg, rgb(220, 220, 220) 0%, rgba(200, 200, 200, 0.85) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    ` : `
        background: linear-gradient(90deg, #000 0%, #333 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    `}

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    
    ${props => props.theme.darkMode && `
        background: linear-gradient(90deg, #FFF 0%, rgba(255, 255, 255, 0.85) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    `}

    @media (max-width: 768px) {
        font-size: 1.3em;
    }

    @media (max-width: 480px) {
        font-size: 1.1em;
    }
`

const TitleInfo = styled.div`
    ${RowCSS}
    align-items: center;

    
`

const TitleIcon = styled.img`
    height: 1.5em;
    margin-right: 0.5em;

    @media (max-width: 768px) {
        height: 1.3em;
    }

    @media (max-width: 480px) {
        height: 1.1em;
    }
`

const CloseButton = styled.div`
    ${RowCSS}
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    position: relative;
    
    &:hover {
        background-color: ${props => props.theme.darkMode ? 
            'rgba(255, 255, 255, 0.1)' : 
            'rgba(0, 0, 0, 0.05)'};
    }

    @media (max-width: 768px) {
        width: 1.8em;
        height: 1.8em;
    }
`

const CloseIcon = styled.img`
    position: absolute;
    width: 1em;
    height: 1em;
    transition: opacity 0.2s cubic-bezier(0.4, 0.0, 0.2, 1);
    opacity: ${props => props.isVisible ? 1 : 0};
    filter: ${props => props.theme.darkMode ? 'invert(1)' : 'none'};

    @media (max-width: 768px) {
        width: 0.9em;
        height: 0.9em;
    }
`


const Wrapper = styled(motion.div)`
    ${ColumnCSS}
    border-radius: 1em;

    max-height: 90dvh;
    overflow-y: auto;
    padding: 2em;

    ${OslerScrollBar({verticalMargin: true})}

    // Aumentei as larguras máximas
    width: ${props => props.bigWidth ? '900px' : '600px'};

    background: ${props => props.theme.darkMode ? 
        'rgb(41, 41, 41)' : 
        'rgba(255, 255, 255, 0.95)'};
    backdrop-filter: blur(24px);
    box-shadow: ${props => props.theme.darkMode ? 
        '0 24px 64px rgba(0, 0, 0, 0.4)' : 
        '0 24px 64px rgba(0, 0, 0, 0.1)'};
    border: 1px solid ${props => props.theme.darkMode ? 
        'rgba(255, 255, 255, 0.1)' : 
        'rgba(0, 0, 0, 0.06)'};

    @media (max-width: 768px) {
        padding: 1.5em;
        border-radius: 16px;
        width: 100%;
        max-height: 90dvh;
        overflow-y: auto;
    }

    @media (max-width: 480px) {
        padding: 1em;
        border-radius: 12px;
    }
`


const Content = styled.div`
    ${ColumnCSS}
    align-items: flex-start;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    width: 100%;
    color: ${props => props.theme.darkMode ? 'rgb(220, 220, 220)' : 'inherit'};

    p {
        font-size: 1em;
        line-height: 1.7;
        color: ${props => props.theme.darkMode ? 'white' : 'inherit'};

        @media (max-width: 768px) {
            font-size: 0.95em;
            line-height: 1.6;
        }

        @media (max-width: 480px) {
            font-size: 0.9em;
            line-height: 1.5;
        }
    }

`

const Actions = styled.div`
    ${RowCSS}
    justify-content: flex-end;
    gap: 1em;
    margin-top: 2em;

    @media (max-width: 768px) {
        margin-top: 1.5em;
        gap: 0.75em;
    }

    @media (max-width: 480px) {
        margin-top: 1.25em;
        gap: 0.5em;
    }
`


export default function GeneralDialog({ open, title, children, 
                                        actions, onClose, icon, bigWidth = false,
                                        allowClose = true,
                                        titleColor = '#06c' }) {

    useHotkeys('esc', () => {
        if (allowClose) onClose();
    })


    function getColor(style) {
        switch(style) {
            case 'neutral':
                return 'gray';
            case 'confirm':
                return 'blue';
            case 'destructive':
                return 'red';
            default:
                return 'gray';
        }
    }


    const [isHovered, setIsHovered] = useState(false)
    
    return ReactDOM.createPortal(
        <AnimatePresence>
            {open && (
                <Backdrop
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3 }}
                    onClick={() => allowClose && onClose()}
                >
                    <Wrapper
                        onClick={(e) => e.stopPropagation()}
                        bigWidth={bigWidth}
                        initial={{ 
                            y: 50, 
                            opacity: 0,
                            scale: 0.9,
                            rotateX: 10
                        }}
                        animate={{ 
                            y: 0, 
                            opacity: 1,
                            scale: 1,
                            rotateX: 0
                        }}
                        exit={{ 
                            y: 30, 
                            opacity: 0,
                            scale: 0.95,
                            rotateX: 5
                        }}
                        transition={{ 
                            type: "spring",
                            damping: 20,
                            stiffness: 300,
                            mass: 0.8,
                        }}
                    >
                        {/* <WrapperInner> */}
                            <TitleHeader>
                            <TitleInfo>
                                {icon && <TitleIcon src={icon}/> }
                                {title && <TitleText color = {titleColor}>{title}</TitleText>}
                            </TitleInfo>
                            { allowClose && 
                                <CloseButton 
                                    onMouseEnter={() => setIsHovered(true)}
                                    onMouseLeave={() => setIsHovered(false)}
                                    onClick={onClose}
                                >
                                    <CloseIcon src={CloseGrayIcon} isVisible={!isHovered} />
                                    <CloseIcon src={CloseBlackIcon} isVisible={isHovered} />
                                </CloseButton>
                            }
                        </TitleHeader>
                        <Content>{children}</Content>
                        <Actions>
                            {actions && actions.map((action, index) => (
                                <OslerButton
                                    key={index}
                                    text = {action.label}
                                    onClick={action.onClick}
                                    color = { getColor(action.style) } />
                            ))}
                        </Actions>
                        {/* </WrapperInner> */}
                    </Wrapper>
                </Backdrop>
            )}
        </AnimatePresence>,
        document.body
    )
}