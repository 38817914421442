import { db } from "../firebase/firebase-setup";


export async function loadNumberCards(uid) {
    const overallStatistics = await db.doc(`users/${uid}/Flashcards/statistics/assorted/nEachTypeTest`).get()
    let totalCards = 0

    if (overallStatistics.exists) {
        const data = overallStatistics.data()

        function getTotalValue(data, key) {
            return data.Total?.[key] ?? 0;
        }
        
        // Utilizando a função para obter os valores
        const learning = getTotalValue(data, 'learning');
        const relearning = getTotalValue(data, 'relearning');
        const review = getTotalValue(data, 'review');

        totalCards = (review + relearning + learning)
    }
    else {
        totalCards = 0
    }

    return totalCards
}