import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import ResidenciaIcon from './../assets/residencia.png';
import FlashcardIcon from './../assets/flashcard.png';
import down from './../assets/down.png';
import right from './../assets/right.png';
import downDark from './../assets/down dark.png';
import rightDark from './../assets/right dark.png';
import sessionBuilder from '../controllers/SessionBuilder';
import Lock from './../assets/padlock.png';
import { db } from '../firebase/firebase-setup';
import OslerData, { KEYS } from '../controllers/OslerData';
import ActivityInfo from './ActivityInfo';
import ActivityAction from './ActivityAction';
import ModuleAction from './ModuleAction';
import StepAction from './StepAction';
import ModuleName from './ModuleName';
import { textWhiteBlack } from '../tests/FlashcardsStyles';
import { ColumnCSS, RowCSS } from '../components/BasicComponents';
import OslerCard from '../components/OslerCard';


const Module = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-color: white;
    align-self: center;
    border-radius: 1em;
    width: 100%;
    margin-bottom: 2em;


    ${props => props.theme.darkMode ? `
        background-color: rgba(61, 61, 61, 1);
        box-shadow: none;
    ` : `
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

        &:hover {
           box-shadow: rgba(0, 0, 0, 0.32) 0px 1px 4px;
        }
    `}


    @media (max-width: 900px) {
        width: 100%;
    }

    
`;

const Header = styled.div`
    width: 100%;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 1em;


    ${props => props.theme.darkMode ? `
        background-color: rgba(61, 61, 61, 1);
    ` : `
        background-color: white;
    `}


    ${props => props.expanded && `
        margin-bottom: 1.25em;  
    `}

    
    @media (max-width: 500px) {
        // Atenção daqui em diante, porque o font-size pode alterar os 'em', o que dá
        // assimetrias discretas.
        font-size: 0.9em;
        flex-direction: row;
        align-items: center;
        gap: 1em;
        padding: 0.6em;
    }
`


const HeaderLeft = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    width: 60%;


    // Está aqui, para permitir que onclick faça a expansion com menos área falsa
    padding: 2em 2em 2em 1em;

    @media (max-width: 900px) {
        padding: 1em 1em 1em 1em;
    }

    @media (max-width: 768px) {
        width: 100%;
        padding: 0;
    }
`


const ArrowIcon = styled.img`
    width: 0.9em;
    height: auto;
    margin: 0 1em 0 0;

    @media (max-width: 500px) {
        width: 0.7em;
        margin: 0 0.3em 0 0;
    }
`


const HeaderBttns = styled.div`
    ${RowCSS}

    width: 40%;

    // Para igular o HeaderLeft
    padding: 0 1em 0 0;

    @media (max-width: 900px) {
        padding: 1em;
        ${ColumnCSS}
    }

    @media (max-width: 768px) {
        width: 60%;
        padding: 0;
        justify-content: flex-start;
    }

`




const ExtensivoModuleStepsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    // Senão fica imperfeito
    border-radius: 1em;
    ${props => props.theme.darkMode ? `
    background-color: rgba(61, 61, 61, 1);
        ` : `
    background-color: white;
        // background-color: yellow;
    `}


    @media (max-width: 900px) {
        padding: 1em;
    }

    @media (max-width: 500px) {
        // Atenção daqui em diante, porque o font-size pode alterar os 'em', o que dá
        // assimetrias discretas.
        font-size: 0.9em;
    }

    @media (max-width: 768px) {
        padding: 0.5em;
    }
`

const StepSeparator = styled.hr`
  margin-top: 2em;
  margin-bottom: 3em;
  width: 90%;
  align-self: center;
  ${props => props.theme.darkMode ? `
  color: rgba(61, 61, 61, 1);
      ` : `
  color: white;
  `}
  
  @media (max-width: 768px) {
    margin: 1.5em 0;
    width: 100%;
}
  `;


const Step = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;

    ${props => props.theme.darkMode ? `
        background-color: rgba(61, 61, 61, 1);
    ` : `
        background-color: white;
    `}

    @media (max-width: 768px) {
        padding: 0em;
    }
`


const StepHeader = styled.div`
    width: 100%;
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 1em;

    @media (max-width: 768px) {
        flex-direction: row;
        align-items: center;
        gap: 1.2em;
        
    }
`


const StepDate = styled.div`
    width: 60%;

    padding: 0 0 0 2em;


    p { 
        font-size: 1.3em;
        @media (max-width: 500px) {
            font-size: 1.1em;
            margin-bottom: 10px;
        }

        margin: 0;
        padding: 0;
        font-weight: bold;
        ${props => props.theme.darkMode ? `
            color: #74a4f2
        ` : `
            color: #164eab
        `}
    }

    @media (max-width: 768px) {
        width: 100%;
        padding: 0;
        
        p {
            font-size: 1.1em;
            margin-bottom: 0em;
        }
    }

`


const DateBttns = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 40%;

    padding-right: 1em;

    @media (max-width: 900px) {
        padding: 0;
        ${ColumnCSS}
    }

    @media (max-width: 500px) {
        padding: 0;
        ${ColumnCSS}
    }

    @media (max-width: 768px) {
        width: 70%;
        padding: 0;
        justify-content: flex-start;
    }

`


const Activity = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    ${props => props.theme.darkMode ? `
        background-color: rgba(61, 61, 61, 1);
    ` : `
        background-color: white;
    `}

    ${textWhiteBlack}

    padding: 0.3em 0 0.3em 0;

    border-radius: 0.3em;
    &:hover {
        ${props => props.theme.darkMode ? `
            background-color: transparent;
        ` : `
            background-color: white;
        `}
    }

    @media (max-width: 768px) {
        flex-direction: row;
        align-items: center;
        gap: 0.8em;
        padding: 0.8em 0;
    }
`



const Name = styled.div`
    width: 60%;

    padding-left: 3em;

    p {
        margin: 0;
        font-weight: bold;
        font-size: 1.1em;
        ${textWhiteBlack}    
    }

    @media (max-width: 768px) {
        width: 150%;
        padding-left: 0;
        
        p {
            font-size: 0.85em;
        }
    }

    // background-color: red;

`


const ActivityBttns = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    
    width: 40%;

    // Fundamental manter para alinhar no desktop, mas... retirar para alinhar
    // no mobile.
    padding-right: 1em;

    @media (max-width: 900px) {
        padding: 0em;
        ${ColumnCSS};
    }


    @media (max-width: 500px) {
        padding: 0;
        ${ColumnCSS};
        width: 25%;
    }

    @media (max-width: 768px) {
        width: 100%;
        padding: 0;
        justify-content: flex-end;
    }

`

const InactiveStep = styled.div`
  width: 40%;
  display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 1em;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0.6em;
    display: flex;
    justify-content: center;
}
`;

const InactiveStepIcon = styled.img`
  height: 2.5em;
  background-color: ${props => props.checked ? `none` : (props.theme.darkMode ? `rgba(121, 121, 121, 1)` : `rgba(235, 235, 235, 0.6)`)};
  padding: 0.5em;
  border-radius: 1em;

  @media (max-width: 768px) {
    height: 1.5em;
}
`;




/*
    Module é o conteúdo da semana, e step o conteúdo do dia.
    O step é formado por várias activities.
    
    APRENDIZADO. O console do navegador muitas vezes lida com objetos e arrays de maneira um pouco 
    diferente de valores primitivos. Quando você imprime um objeto ou array, o console 
    pode fornecer uma visão 'viva' do objeto. Então, se o objeto for modificado após a 
    chamada console.log, mas antes de você expandir o objeto no console, você verá o 
    estado mais recente do objeto, não o estado que ele tinha quando foi logado.

    Solução Potencial: Para verificar o estado do objeto no momento exato do 
    console.log, você pode fazer uma cópia profunda do objeto antes de imprimi-lo:
    console.log(JSON.parse(JSON.stringify(updatedSteps)));
*/



export default function ExtensivoModule({ 
    steps, name, emojis, isLatestModule, startTestModeSession, startViewModeSession, ...props }) {
    const user = useSelector(state => state.user.data)
    const dark = useSelector(state => state.theme.darkOn)
    const [expanded, setExpanded] = useState(false)

    // const moduleData = useRef(undefined)

    const [moduleData, setModuleData] = useState(undefined)
    const [loadedStatistics, setLoadedStatistics] = useState(false)



    useEffect(() => {
        async function loadData() {
            // Para cada atividade de cada step, iremos obter os testIDs de todos os testes novos
            // e das revisões, para exibirmos a quantidade e facilitarmos o início da sessão.
            //
            // Aqui, cada step é um array de atividades, no modelo:
            //
            // {
            //     'flashcards_tagpaths': [ 'tagpaths dos decks, se unidos por | no Sheets' ],
            //     'residencia_tagpaths': [ 'idem acima' ],
            //     'available': 'TRUE',    --> se já está disponível, note que é uma string
            //     'name': 'Chagas: infecção',
            //     'newFlashcards': [ 'testsIDs' ],
            //     'reviewFlashcards': [ 'testsIDs' ],
            //     'newResidencia': [ 'testsIDs' ],
            //     'reviewResidencia': [ 'testsIDs' ],
            //     'statistics': {          --> pendentes, ainda não foram baixadas
            //         'Residencia': {
            //             'firstAnswerCorrectPercentage': '0%',
            //             'lastAnswerCorrectPercentage': '0%'
            //         },
            //         'Flashcards': {
            //             'firstAnswerCorrectPercentage': '-',
            //             'lastAnswerCorrectPercentage': '-'
            //         }
            //     }
            // }

            // for (let step of steps) {
            //     for (let activity of step['activities']) {
            //         await fetchActivityTests(activity, 'Flashcards');
            //         await fetchActivityTests(activity, 'Residencia');
            //     }
            // }

            // Salvamos os dados. Se for o último step a ser lançado, o componente já 
            // fica expandido (i.e., todos os steps ficam visíveis)
            setModuleData(steps)
            setExpanded(isLatestModule)

            // Como o estado demora para atualizar, e precisamos para as estatísticas
            // retornamos. Talvez o ideal fossem dois useEffects separados.
            return steps
        }




        async function loadStatistics(steps) {
            for (let step of steps) {
                for (let activity of step['activities']) {
                    activity['statistics'] = {}

                    for (let residenciaPath of activity['residencia_tagpaths']) {
                        let ajustedTagpath = residenciaPath.replace(/\//g, '|');
                        let docPath = `users/${user.id}/Residencia/lists/extensivo_tagpaths_lists/${ajustedTagpath}`
                        await calculateStatistics(docPath, 'Residencia', activity, residRightAnswer)
                    }

                    for (let flashcardsPath of activity['flashcards_tagpaths']) {
                        let deckRoot = getDeckRoot(flashcardsPath)
                        let docPath = `users/${user.id}/Flashcards/lists/deck_lists/${deckRoot}`

                        await calculateStatistics(docPath, 'Flashcards', activity, cardRightAnswer)
                    }
                }
            }

            setModuleData(steps)
            setLoadedStatistics(true)
        }



        function getDeckRoot(tagpath_param) {
            let rootIntoTagpath = OslerData.data[KEYS.FLASHCARDS][KEYS.TAGPATH_PER_ID]
            for (const [root, tagpath] of Object.entries(rootIntoTagpath)) {
                if (tagpath_param == tagpath) {
                    return root
                }
            }

            return undefined
        }


        function cardRightAnswer(dict) {
            return dict['levelOfSuccess'] > 0
        }


        function residRightAnswer(dict) {
            return dict['gaveRightAnswer']
        }


        async function calculateStatistics(docPath, type, activity, gaveRightAnswer) {
            let doc = await db.doc(docPath).get()

            activity['statistics'][type] = {}

            if (doc.exists) {
                const data = doc.data()

                let total = 0
                let firstCorrect = 0
                let lastCorrect = 0

                Object.entries(data).forEach(([questionID, questionData]) => {
                    total += 1

                    if (gaveRightAnswer(questionData.first_answer)) {
                        firstCorrect += 1
                    }

                    if (gaveRightAnswer(questionData.last_answer)) {
                        lastCorrect += 1
                    }

                });

                const firstCorrectPercentage = total > 0 ? Math.round((firstCorrect / total) * 100) : '-';
                activity['statistics'][type]['firstAnswerCorrectPercentage'] = firstCorrectPercentage + '%';

                const lastCorrectPercentage = total > 0 ? Math.round((lastCorrect / total) * 100) : '-';
                activity['statistics'][type]['lastAnswerCorrectPercentage'] = lastCorrectPercentage + '%';

            } else {
                activity['statistics'][type]['firstAnswerCorrectPercentage'] = '-';
                activity['statistics'][type]['lastAnswerCorrectPercentage'] = '-';
            }
        }


        async function start() {
            const steps = await loadData()
            await loadStatistics(steps)
        }

        start()
    }, [steps])




    function getDateJSX(dateStr) {
        // A data é do formato '16/08/2023'
        // Para uma boa exibição na platforma, queremos retirar o ano, e adicionar
        // o dia da semana e o mês em português. Para isso, fazemos um parse,
        // corrigimos o mês (pois em JS começa no 0), e aí usamos a biblioteca.
        if (dateStr == '') {
            return (
                <></>
            )
        }
        else {
            let [day, month, year] = dateStr.split('/')
            let dateObj = new Date(year, month - 1, day)

            let monthStr = dateObj.toLocaleString('pt-BR', { month: 'long' })

            // Isso é real uma regra abnt
            if (monthStr != 'maio') {
                monthStr = monthStr.slice(0, 3)
            }

            let weekdayStrk = dateObj.toLocaleDateString('pt-BR', { weekday: 'long' })
            weekdayStrk = weekdayStrk.replace('-feira', '')

            return (
                `${dateObj.getDate()} de ${monthStr}, ${weekdayStrk}`
            )
        }
    }


    function getDayJSX(step) {
        return (
            <>
                <Step key={step.day}>
                    <StepHeader>
                        <StepDate><p>{getDateJSX(step['day'])}</p></StepDate>
                        <DateBttns>
                            <ModuleAction
                                activitities={step['activities']}
                                testType={'Flashcards'}
                                startTestModeSession = {startTestModeSession}
                            />

                            <ModuleAction       
                                activitities={step['activities']}
                                testType={'Residencia'}
                                startTestModeSession = {startTestModeSession}
                            />
                        </DateBttns>
                    </StepHeader>


                    {step['activities'].map(activity => {
                        const hasCards = activity['flashcards_tagpaths'].length > 0
                        const hasResid = activity['residencia_tagpaths'].length > 0

                        return (
                            <Activity>
                                <Name>
                                    <p>{activity['name']}</p>
                                </Name>

                                {activity.available === 'TRUE' ? (
                                    <ActivityBttns>
                                        <ActivityInfo
                                            type = 'Flashcards'
                                            activity = {activity}
                                            hasTests = {hasCards}
                                            icon = {FlashcardIcon}
                                            startTestModeSession = {startTestModeSession}
                                            startViewModeSession = {startViewModeSession}
                                            loadedStatistics = {loadedStatistics} />

                                        <ActivityInfo
                                            type = 'Residencia'
                                            activity = {activity}
                                            hasTests = {hasResid}
                                            icon = {ResidenciaIcon}
                                            startTestModeSession = {startTestModeSession}
                                            startViewModeSession = {startViewModeSession}
                                            loadedStatistics = {loadedStatistics} />

                                    </ActivityBttns>
                                ) : (
                                    <InactiveStep>
                                        <InactiveStepIcon src={Lock} />
                                    </InactiveStep>
                                )}
                            </Activity>
                        );
                    })}
                </Step>

                <StepSeparator />
            </>
        );
    }



    if (!moduleData) return

    return (
        <OslerCard style = {{marginBottom: '2em', padding: '0em'}}>
            <Header expanded = {expanded}>
                <HeaderLeft onClick={() => setExpanded(!expanded)}>
                    <ArrowIcon src={expanded ? (dark ? downDark : down) : (dark ? rightDark : right)} />
                    <ModuleName
                        title = {name}
                        emojis = {emojis} />
                </HeaderLeft>

                <HeaderBttns>
                    <ModuleAction
                        activitities = {moduleData.reduce((acc, module) => {
                            return acc.concat(module.activities);
                        }, [])}
                        testType={'Flashcards'}
                        startTestModeSession = {startTestModeSession}
                    />

                    <ModuleAction
                        activitities={moduleData.reduce((acc, module) => {
                            return acc.concat(module.activities);
                        }, [])}
                        testType={'Residencia'}
                        startTestModeSession = {startTestModeSession}
                    />
                </HeaderBttns>
            </Header>

            {expanded && moduleData && (
                // <ExtensivoModuleContent>
                <ExtensivoModuleStepsContainer>
                    {moduleData.map(step => getDayJSX(step))}
                </ExtensivoModuleStepsContainer>

                // </ExtensivoModuleContent>
            )}
        </OslerCard>
    );
}