// ErrorScreen.js
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import Logo from './../assets/Logo.png';
import OslerButton from '../components/OslerButton';
import { db } from '../firebase/firebase-setup';
import { dateIntoString } from '../utils/Utils';
import { setIsLoading } from '../redux/loadingSlice';

import { ColumnCSS, RowCSS } from '../components/BasicComponents';


const Container = styled.div`
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f8f9fa;
    padding: 1rem;
`;

const Content = styled.div`
    ${ColumnCSS}
    max-width: 600px;
    width: 100%;
    padding: 2rem;
    text-align: center;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const LogoImage = styled.img`
    width: 80px;
    height: auto;
    margin-bottom: 1.5rem;
`;


const Title = styled.p`
    font-size: 1.4rem;
    font-weight: bold;
    color: #2d3436;
    margin: 0;
`


const Joke = styled.p`
    color: gray;
    font-size: 0.9rem;
    width: 80%;
    margin: 0;
    // font-weight: bold;
`


const Message = styled.p`
    color: #2d3436;
    margin-top: 2em;
    margin-bottom: 1.2rem;
    line-height: 1.5;
`;



const ErrorInfo = styled.div`
    padding: 1rem;
    background-color: #f2f2f2;
    border: 1px solid #ef4444;
    border-radius: 4px;
    text-align: left;
`;

const ErrorDetail = styled.p`
    font-family: monospace;
    font-size: 0.875rem;
    margin: 0.25rem 0;
    color: BLACK;
    word-break: break-all;
`;

const PrintMessage = styled.p`
    color: #991b1b;
    font-weight: 600;
    margin: 1rem 0;
    font-size: 1rem;
`

export default function ErrorScreen() {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.data);

    const { error, msg, screen: errorScreen, timestamp } = location.state || {};
    const currentScreen = errorScreen || window.location.pathname;

    React.useEffect(() => {
        dispatch(setIsLoading(false));
        
        if (error || msg) {
            const when = dateIntoString(new Date());
            
            // Tenta salvar o erro
            try {
                db.collection('app_errors').add({
                    error: error?.message,
                    msg: msg,
                    date: when,
                    screen: currentScreen,
                    solved: false,
                    user: user?.id
                });
            } catch (e) {
                console.error('Falha ao salvar erro:', e);
            }
        }
    }, [error, msg]);

    // Detecta se é erro de conexão
    const isOffline = error?.message?.includes('offline') || !navigator.onLine;

    const getErrorDetails = () => {
        if (error?.code?.includes('firebase')) {
            return error.message?.split('Firebase:')[1] || error.message;
        }
        return error?.message || msg || 'Erro desconhecido';
    };

    return (
        <Container>
            <Content>
                <LogoImage src={Logo} alt="Logo" />

                <Title>Interno é foda, né?</Title>

                <Joke>
                    Contamina o campo cirúrgico, lê ECG ao contrário, 
                    se perde buscando papel na impressora, e trava a plataforma.
                </Joke>

                <Message>
                    {isOffline ? (
                        <p>O problema é que <b>você está offline</b>. 
                           Verifique sua conexão e Firewall, e tente novamente.</p>
                    ) : (
                        <PrintMessage>
                            Envie um print na DM, por favor.
                        </PrintMessage>
                    )}
                </Message>

                {/* Seção de informações técnicas simplificada */}
                <ErrorInfo>
                    <ErrorDetail>{user?.id}</ErrorDetail>
                    <ErrorDetail>{currentScreen}</ErrorDetail>
                    <ErrorDetail>{new Date(timestamp).toLocaleString()}</ErrorDetail>
                    <ErrorDetail>{getErrorDetails()}</ErrorDetail>
                    {error?.stack && error.stack.split('\n').map((line, i) => (
                        <ErrorDetail key={i} style={{paddingLeft: i > 0 ? '1rem' : '0'}}>
                            {line}
                        </ErrorDetail>
                    ))}
                </ErrorInfo>

                <OslerButton
                    style={{
                        width: '10em',
                        marginTop: '2em',
                        alignSelf: 'center'
                    }}
                    color="blue"
                    text="Recarregar"
                    onClick={() => navigate('/app')}
                />
            </Content>
        </Container>
    );
}