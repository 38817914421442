import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { ColumnCSS, RowCSS } from './BasicComponents'
import { backgroundWhiteBlack } from '../tests/FlashcardsStyles'



// a


const Container = styled.div`
        display: flex;

        position: relative; /* Necessário para alinhar o dropdown */

        // Atenção a esse padding da esquerda, ele é fundamental para que o 
        // conteúdo sob a tela esteja alinhado
        // com o ícone do header e com os elementos da tablist
        margin: 0.5em auto 1em 2em;
        width: 80%;
        max-width: 300px;


        @media (max-width: 600px) {
        // Para ficar simétrico com OslerCard no mobile,
        // e com Title de ScreenContainerHeader
            width: 100%;
            max-width: 200px;
            margin: 0.5em 0em 1em 0.75em;
        }
`

const Button = styled.button`
    ${RowCSS}
    width: 100%;
    padding: 0.75em;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
    border-radius: 1em;
    font-weight: bold;
    font-size: 1em;
    color: ${props => props.theme.darkMode ? '#60a5fa' : '#06c'};

    background: ${props => props.theme.darkMode ? 
        'rgba(96, 165, 250, 0.15)' : 
        'rgba(0, 102, 204, 0.08)'};
    border: 1px solid ${props => props.theme.darkMode ? 
        'rgba(96, 165, 250, 0.2)' : 
        'rgba(0, 102, 204, 0.1)'};
    box-shadow: 0 2px 8px ${props => props.theme.darkMode ? 
        'rgba(0, 0, 0, 0.2)' : 
        'rgba(0, 0, 0, 0.05)'};

    transition: all 0.2s ease;

    &:hover {
        transform: translateY(-1px);
        background: ${props => props.theme.darkMode ? 
            'rgba(96, 165, 250, 0.2)' : 
            'rgba(0, 102, 204, 0.12)'};
    }

    &:active {
        transform: translateY(0);
    }

    .left-content {
        ${RowCSS}
        gap: 0.75em;
        align-items: center;

        img {
            height: 1.2em;
            width: auto;
        }
    }

    .arrow {
        font-size: 0.8em;
        transition: transform 0.3s ease;
        transform: ${props => props.isOpen ? 'rotate(180deg)' : 'rotate(0)'};
    }


    @media (max-width: 600px) {
        font-size: 0.8em;
        padding: 0.6em;
    }


`

const Content = styled.div`
    ${ColumnCSS}

    position: absolute;
    top: ${props => `${props.buttonBottom + 3}px`} !important; /* Alinha com a parte de baixo do botão */
    left: 0; /* Alinha à esquerda do botão */
    width: 100%; /* O mesmo tamanho do botão */

    max-width: 300px;
    z-index: 1000;
    overflow: hidden;
    border-radius: 12px;

    background: ${props => props.theme.darkMode ? 
        'rgba(41, 41, 41, 0.95)' : 
        'rgba(255, 255, 255, 0.95)'};
    backdrop-filter: blur(12px);
    border: 1px solid ${props => props.theme.darkMode ? 
        'rgba(96, 165, 250, 0.2)' : 
        'rgba(0, 102, 204, 0.1)'};
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.15);

 
    animation: dropdownSlide 0.3s ease;
    @keyframes dropdownSlide {
        from {
            opacity: 0;
            // transform: translateY(-10px); /* Suave de cima para baixo */
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }
`

const Item = styled.div`
    ${RowCSS}
    width: 100%;
    padding: 0.75em;
    cursor: pointer;
    align-items: center;
    gap: 0.75em;
    transition: all 0.2s ease;
    
    background: ${props => {
        if (props.active) {
            return props.theme.darkMode ? 
                'rgba(96, 165, 250, 0.15)' : 
                'rgba(0, 102, 204, 0.08)'
        }
        return 'transparent'
    }};
    
    color: ${props => {
        if (props.theme.darkMode) {
            return props.active ? '#60a5fa' : 'rgb(220, 220, 220)'
        }
        return props.active ? '#06c' : '#1d1d1f'
    }};

    img {
        height: 1.2em;
        width: auto;
        opacity: ${props => props.active ? 1 : 0.7};
        transition: opacity 0.2s ease;
    }

    &:hover {
        background: ${props => props.theme.darkMode ? 
            'rgba(96, 165, 250, 0.1)' : 
            'rgba(0, 102, 204, 0.05)'};
        
        img {
            opacity: 1;
        }
    }

    &:not(:last-child) {
        border-bottom: 1px solid ${props => props.theme.darkMode ? 
            'rgba(96, 165, 250, 0.1)' : 
            'rgba(0, 102, 204, 0.05)'};
    }

    @media (max-width: 600px) {
        padding: 0.6em;
        font-size: 0.9em;
    }   
`

export function HeaderDropdown({ tabs = [], selectedTab, onTabChange }) {
    const [isOpen, setIsOpen] = useState(false)
    const dropdownRef = useRef(null)
    const activeTab = tabs.find(tab => tab.id === selectedTab)
    
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false)
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => document.removeEventListener('mousedown', handleClickOutside)
    }, [])


    const buttonRef = useRef(null)

    // Não podemos colocar exatamente no bottom do botão, porque uma coisa é calculado
    // em relação ao viewport, e outra em relação ao container que está como relative, ao que parece
    const rect = buttonRef.current?.getBoundingClientRect()
    const containerRect = dropdownRef.current?.getBoundingClientRect()
    const correctedTop = rect ? rect.bottom - containerRect.top : 0


    return (
        <Container ref={dropdownRef}>

            <Button 
                onClick={() => setIsOpen(!isOpen)}
                isOpen={isOpen}
                ref={buttonRef}
            >
                <div className="left-content">
                    {activeTab?.icon && <img src={activeTab.icon} alt={activeTab.label || activeTab.id} />}
                    <span>{activeTab?.label || ''}</span>
                </div>
                <span className="arrow">▼</span>
            </Button>

            {isOpen && (
                <Content buttonBottom={correctedTop}>
                    {tabs.map((tab) => (
                        <Item
                            key={tab.id}
                            active={selectedTab === tab.id}
                            onClick={() => {
                                onTabChange?.(tab.id)
                                setIsOpen(false)
                            }}
                        >
                            {tab.icon && <img src={tab.icon} alt={tab.label || tab.id} />}
                            {tab.label && <span>{tab.label}</span>}
                        </Item>
                    ))}
                </Content>
            )}
        </Container>
    )
}