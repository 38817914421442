import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useToast } from "../components/OslerToast"
import SessionBuilder from "../controllers/SessionBuilder"
import { STUDY_MODES } from "../controllers/SessionConfig"
import { setIsLoading } from "../redux/loadingSlice"
import Session from "../controllers/Session"

export function useSessionStarter() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { addToast } = useToast()

    const startSession = async ({ sessionBuilderOptions = null, savedSession = null }) => {
        dispatch(setIsLoading(true))
        
        try {
            if (savedSession) {
                await Session.loadFromSaved(savedSession)
            } else {
                await SessionBuilder.start(sessionBuilderOptions)
            }

            dispatch(setIsLoading(false))

            if (Session.sessionSize > 0) {
                const nextScreen = Session.mode === STUDY_MODES.CONSULT_MODE ? '/consult' : '/test'

                console.log(`Navigating to ${nextScreen}`)
                navigate(nextScreen)
            } else {
                addToast('Não esperávamos por esse erro... Manda um print por DM. Desculpa. 🫠', {
                    duration: 10000,
                    position: 'TOP'
                })
                return false
            }
        } catch (error) {
            dispatch(setIsLoading(false))
            console.log(error)
            addToast('Erro ao iniciar sessão. Tente novamente mais tarde. 😢', {
                duration: 10000,
                position: 'TOP'
            })
            return false
        }
    }

    const startNewSession = async (sessionBuilderOptions) => {
        return startSession({ sessionBuilderOptions })
    }

    const startSavedSession = async (savedSession) => {
        return startSession({ savedSession })
    }

    return {
        startNewSession,
        startSavedSession
    }
}