import React from 'react'
import styled, { css } from 'styled-components'
import { darken, lighten } from 'polished'

const buttonColors = {
    red: '#d1301f',
    blue: '#1883f0',
    grey: '#878787',
    darkGrey: '#595e67',
    green: '#27ae60'
}

function getButtonStyle(color, theme) {
    const buttonColor = buttonColors[color] || buttonColors.darkGrey
    return css`
        background-color: ${theme.darkMode ? darken(0.1, buttonColor) : buttonColor};
        color: white;
        
        &:hover {
            background-color: ${theme.darkMode ? lighten(0.1, buttonColor) : lighten(0.1, buttonColor)};
        }
    `
}

const ButtonContainer = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${props => props.$size === 'small' ? '0.5em 1em' : '0.7em 1.2em'};
    font-size: ${props => props.$size === 'small' ? '0.8em' : '1em'};
    border: none;
    border-radius: 1em;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: 500;
    letter-spacing: 0.5px;
    
    ${props => getButtonStyle(props.$color, props.theme)}
    
    ${props => props.$selected && css`
        opacity: 0.8;
        cursor: default;
    `}
    
    ${props => props.$shadow && css`
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    `}
    
    ${props => props.$animated && css`
        &:hover {
            transform: translateY(-2px);
        }
    `}
    
    ${props => props.$uppercase && css`
        text-transform: uppercase;
    `}

    ${props => props.$width && css`
        width: ${props.$width};
    `}

    @media (max-width: 768px) {
        padding: ${props => props.$size === 'small' ? '0.6em 0.8em' : '0.8em 1em'};
        font-size: ${props => props.$size === 'small' ? '0.75em' : '0.9em'};
        border-radius: 0.8em;
        
        ${props => props.$animated && css`
            &:hover {
                transform: none;
            }
            
            &:active {
                transform: scale(0.98);
            }
        `}

        ${props => props.$shadow && css`
            box-shadow: 0 2px 4px rgba(50, 50, 93, 0.1), 0 1px 2px rgba(0, 0, 0, 0.07);
        `}
    }

    @media (max-width: 480px) {
        padding: ${props => props.$size === 'small' ? '0.5em 0.7em' : '0.7em 0.9em'};
        font-size: ${props => props.$size === 'small' ? '0.7em' : '0.85em'};
    }
`

const IconWrapper = styled.span`
    display: flex;
    align-items: center;
    margin-${props => props.$iconRight ? 'left' : 'right'}: 0.5em;
    font-size: 1.2em;

    @media (max-width: 768px) {
        font-size: 1.1em;
        margin-${props => props.$iconRight ? 'left' : 'right'}: 0.4em;
    }

    @media (max-width: 480px) {
        font-size: 1em;
        margin-${props => props.$iconRight ? 'left' : 'right'}: 0.3em;
    }
`

const ImageWrapper = styled.img`
    max-height: ${props => props.$size === 'small' ? '1.2em' : '1.4em'};
    max-width: ${props => props.$size === 'small' ? '1.2em' : '1.4em'};
    margin-${props => props.$iconRight ? 'left' : 'right'}: 0.5em;
    object-fit: contain;

    @media (max-width: 768px) {
        max-height: ${props => props.$size === 'small' ? '1.1em' : '1.3em'};
        max-width: ${props => props.$size === 'small' ? '1.1em' : '1.3em'};
        margin-${props => props.$iconRight ? 'left' : 'right'}: 0.4em;
    }

    @media (max-width: 480px) {
        max-height: ${props => props.$size === 'small' ? '1em' : '1.2em'};
        max-width: ${props => props.$size === 'small' ? '1em' : '1.2em'};
        margin-${props => props.$iconRight ? 'left' : 'right'}: 0.3em;
    }
`

export default function OslerButton({
    icon,
    img,
    text,
    color = 'darkGrey',
    selected = false,
    onClick,
    style,
    size = 'normal',
    children,
    animated = false,
    shadow = false,
    uppercase = false,
    iconRight = false,
    width = 'auto',
}) {

    function renderContent() {
        const contentArray = [
            icon && <IconWrapper key="icon" $iconRight={iconRight}>{icon}</IconWrapper>,
            img && <ImageWrapper key="img" src={img} alt="" $size={size} $iconRight={iconRight} />,
            text && <span key="text">{text}</span>,
            ...(React.Children.toArray(children))
        ].filter(Boolean)

        return iconRight ? contentArray.reverse() : contentArray
    }

    return (
        <ButtonContainer
            $width={width}
            $color={color}
            $size={size}
            $selected={selected}
            $animated={animated}
            $shadow={shadow}
            $uppercase={uppercase}
            onClick={!selected ? onClick : undefined}
            style={style}
        >
            {renderContent()}
        </ButtonContainer>
    )
}