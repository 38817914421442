// Out, 2024
import React from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import HelpIcon from './../assets/question-new.png'

const IconButton = styled(motion.div)`
    // width: 2em;
    height: 2em;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    background: transparent;
    padding: 0;

    outline: none;
`

const Icon = styled(motion.img)`
    width: 16px;
    height: 16px;
    opacity: 0.6;
    transition: opacity 0.2s ease;

    ${IconButton}:hover & {
        opacity: 1;
    }
`

export default function HelpBttn({ onClick, visible = true, style }) {
    return (
        <AnimatePresence>
            {visible && (
                <IconButton
                    style = {style}
                    onClick={onClick}
                    initial={{ scale: 0.8, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ scale: 0.8, opacity: 0 }}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.95 }}
                    transition={{ 
                        duration: 0.2,
                        ease: [0.4, 0, 0.2, 1]
                    }}
                >
                    <Icon 
                        src={HelpIcon} 
                        alt="Ajuda"
                        initial={{ rotate: -180 }}
                        animate={{ rotate: 0 }}
                        transition={{ 
                            duration: 0.3,
                            ease: "easeOut"
                        }}
                    />
                </IconButton>
            )}
        </AnimatePresence>
    )
}