import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { db, functions} from '../firebase/firebase-setup'
import UserReviewsInfo from '../controllers/UserReviewsInfo';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import OslerData from '../controllers/OslerData';
import React, { useCallback } from 'react';



export function useAssertAppData() {
    const navigate = useNavigate()

    return useCallback(() => {
        if (!OslerData || !OslerData.ready) {
            console.log(` * useAssertAppData() - dados do app não estão carregados, indo para MainScreen`)
            navigate('/app')
            return false
        }
        return true
    }, [navigate])
}


export function useErrorHandler() {
    const navigate = useNavigate();
    const user = useSelector(state => state.user.data);

    return async function handleError(error) {
        // Guarda o básico: data, usuário, tela e erro
        const errorData = {
            error: error,  // Passa o erro direto
            msg: error?.message || 'Erro desconhecido',  // Mensagem amigável
            screen: window.location.pathname,
            timestamp: new Date().toISOString(),
            userId: user?.id
        };

        // Tenta salvar no Firestore
        try {
            await db.collection('errors_log').add(errorData);
        } catch (e) {
            console.error('Falha ao salvar erro:', e);
        }

        // Navega para a tela de erro passando os dados corretamente
        navigate('/error', { 
            state: errorData 
        });
    }
}