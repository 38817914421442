// Outubro, 2024
import { MoneyRounded } from '@mui/icons-material'
import OslerData, { KEYS } from '../controllers/OslerData'
import SessionBuilder from '../controllers/SessionBuilder'
import { SessionConfig, TEST_TYPES } from '../controllers/SessionConfig'
import UserReviewsInfo from '../controllers/UserReviewsInfo'
import { tagSequenceToPath, tagSequenceToPathS } from '../utils/Utils'
import TreeNode from './TreeNode'
import TreeNodeJSX from './TreeNodeJSX'
import { useWindowWidth } from '../components/useWindowWidth'


export const TreeFilters = {
    INCLUDE_ANULADAS: 'includeAnuladas',
    NEW_TESTS_ONLY: 'newTestsOnly',
    PENDING_REVIEWS_ONLY: 'pendingReviewsOnly',
    ALL_REVIEWS_ONLY: 'allReviewsOnly',
    SEEN_TESTS_ONLY: 'seenTestsOnly'
}


class Tree {
    constructor() {
        this.testType = false
        this.studyMode = false
        this.tagHierarchy = {}
        this.nodes = [] 
        this.onNodeChecked = false
        this.triggerTreeRender = false
        this.sessionConfig = false
        this.index = false
        this.screenWidth = -1
        this.searchedTheme = ''
    }


    start(testType, onNodeChecked, triggerTreeRender) {
        /*
            A construção da árvore depende de duas coisas.
            
            1) A hierarquia das tags, que dá a relação entre os nós que serão criados. É um documento do Firebase, já 
            baixado, que copiamos deliberadamente por valor baixo -- cuidado, pois a cópia tradicional é por referência.

            2) Para cada nó, precisamos saber especificamente o número de testes novos, de revisões pendentes,
            e de revisões futuras -- e como isso muda em função de filtros.

            Atualmente, os filtros podem ser mudados dinamicamente e o modo mais rápido de calcularmos os novos números
            é através de SessionBuilder, permitindo uma abstração única e mais clara (e.g., não temos que tratar
            incluir anuladas aqui e lá).
        */
            console.log(" * Tree: carregando dados...")
            this.testType = testType
            this.tagHierarchy = JSON.parse(JSON.stringify(UserReviewsInfo.tagHierarchy[this.testType]))
            this.nodes = []
            this.triggerTreeRender = triggerTreeRender
            this.onNodeChecked = onNodeChecked
            this.index = UserReviewsInfo.indexation[this.testType]
    }


    setScreenWidth(screenWidth) {
        this.screenWidth = screenWidth
    }
        

    setSearchedTheme(searchedTheme) {
        this.searchedTheme = searchedTheme
    }


    update(studyMode, config) {
        console.log(' * Tree: atualizando a árvore...')
        this.studyMode = studyMode
        this.sessionConfig = config     
        
        this.createTreeIndex()
        
        // Limpa o array de nós antes de criar a nova árvore
        this.nodes = []
        
        console.time('createSubTree total')
        this.createSubTree(this.tagHierarchy, [], undefined)
        console.timeEnd('createSubTree total')
    }


    createTreeIndex() {
        // A árvore simplesmente não funciona sem isso.
        // 
        // Foi o melhor modo robusto e rápido que encontrei de atualizá-la rapidamente
        // a cada alteração do SessionConfig.
        // 
        // É fundamental a vigilância para que o loop seja O(n x 1). Então, tudo precisa
        // ser indexado antes.
        //
        // A ideia é que, dado um conjunto de filtros, elencaremos os testes válidos
        // e eles serão dividos em função do seu status de revisão.

        console.log(' * Tree: criando índoce com a config: ', this.sessionConfig)

        function getDefaultDict() {
            return {
                'pendingReviews': 0,
                'futureReviews': 0,
                'availableTests': 0,
                'solved': 0
            }
        }

        const allTestsIDs = Object.keys(OslerData.data[this.testType][KEYS.ALL_TESTS_IDS])
        const institutionsNameToID = OslerData.data[TEST_TYPES.RESIDENCIA][KEYS.INSTITUTIONS_IDS]        
    
        // Vários testes vão ter a mesma tagpath, então fazemos um cache
        const tagpathToPathsCache = {}
        const index = {}
        const yearFilter = this.sessionConfig.years?.length > 0 ? 
                            Object.fromEntries(this.sessionConfig.years.map(year => [year, true])) : false

        const institutionFilter = this.sessionConfig.institutions?.length > 0 ? 
                            Object.fromEntries(this.sessionConfig.institutions.map(institution => [institutionsNameToID[institution], true])) : false





        for (let ID of allTestsIDs) {
            if (this.testType === TEST_TYPES.RESIDENCIA) {

                // Checamos: anuladas, extensivo, instituição, ano
                if (this.sessionConfig.removeAnuladas) {
                    const isAnulada = UserReviewsInfo.anuladasInfo.allIDs[ID]
                    if (isAnulada) {
                        continue
                    }
                }

                const [, institutionID, year] = ID.split('_')
                if (institutionFilter) {
                    if (!institutionFilter[institutionID]) {
                        continue
                    }
                }

                if (yearFilter) {
                    if (!yearFilter[year]) {
                        continue
                    }
                }
                
                if (this.sessionConfig.onlyExtensivo) {
                    const isExtensivo = UserReviewsInfo.extensivo[ID]
                    if (!isExtensivo) {
                        // console.log(' * Excluindo, pois não é extensivo')
                        continue
                    }
                }
            }

            // Checamos: enterrado
            if (this.sessionConfig.removeBuried) {
                const isBuried = UserReviewsInfo.buried[this.testType][ID]
                if (isBuried) {
                    continue
                }
            }

            // Se passou por todos os filtros, então deve ser computado!
            const status = UserReviewsInfo.getTestStatus(this.testType, ID)
            const tagpath = UserReviewsInfo.getGivenTestTagPath(this.testType, ID)

            let allTagpaths = ''
            if (tagpathToPathsCache[tagpath]) {
                // console.log('Was cashed')
                allTagpaths = tagpathToPathsCache[tagpath]
            }
            else {
                allTagpaths = UserReviewsInfo.getTestTagPathS(this.testType, ID)
                tagpathToPathsCache[tagpath] = allTagpaths
            }


            for (let path of allTagpaths) {
                if (!index[path]) {
                    index[path] = getDefaultDict()
                }

                index[path][status] += 1
            }
        }

        this.treeInfo = index

    }   


    createSubTree(tagHierarchy, tagsUntilHere, parentNode) {
        /*
            Essa função é recursiva com de manter a hierarquia entre os nós (objetos
            TreeNode) que serão criados.
            
            Para cada nível da tagHierarchy, que corresponde a um tagpath, vemos as 
            próximas tags, que correspondem aos nós-filhos. Criamos eles, passando as
            informações den úmero de testes totais, revisões pendentes, e revisões futuras.

            Guardamos esses nós em um array -- currentNodes -- que será retornado ao nó pai (função que
            chamou essa), para que seja guardado sob ele.

            Ainda, tagHierarchy são as tags *sob* este nó, que vamos iterando de modo recursivo.
        */  

        let currentNodes = []
        const keys = Object.keys(tagHierarchy)
        const ordered = keys.sort((a, b) => a < b ? -1 : 1)

        for (let tag of ordered) {
            const nodeTagSequence = [...tagsUntilHere]
            nodeTagSequence.push(tag)

            const path = tagSequenceToPath(nodeTagSequence)
            const info = this.treeInfo[path]

            const node = new TreeNode(tag, info, parentNode, nodeTagSequence)
            
            this.nodes.push(node)
            currentNodes.push(node)

            const subHierarchy = tagHierarchy[tag]

            if (subHierarchy != null) {
                const children = this.createSubTree(subHierarchy, nodeTagSequence, node)
                node.setChildren(children)
            }
        }

        return currentNodes
    }




    // createInfo(tags) {
    //     const paths = tagSequenceToPathS(tags)
    //     const result = {}
        
    //     for (let path of paths) {
    //         const indexDataForPath = this.index[path]
    //         if (!indexDataForPath) {
    //             console.log(`\t\t* Tree - ERRO INESPERADO - Não encontramos indexação para ${path}`)
    //             continue
    //         }
    
    //         // Considera apenas os dados 'general'
    //         const generalData = indexDataForPath.general
    //         if (generalData) {
    //             for (const [status, statusData] of Object.entries(generalData)) {
    //                 if (!result[status]) {
    //                     result[status] = {}
    //                 }
                    
    //                 for (const [category, counts] of Object.entries(statusData)) {
    //                     if (!result[status][category]) {
    //                         result[status][category] = { valid: 0, anulada: 0 }
    //                     }
    //                     result[status][category].valid += counts.valid || 0
    //                     result[status][category].anulada += counts.anulada || 0
    //                 }
    //             }
    //         }
    //     }
    
    //     return result
    // }


    createTreeJSX() {
        console.log(` * Tree: vamos renderizar, com screenWidth = ${this.screenWidth}, e search = ${this.searchedTheme}`)
        this.computeVisibleNodes(this.searchedTheme)

        
        // Bruxaria fundamental para cada TreeNodeJSX saber quantas "colunas"
        // de status existem na árvore como um todo, e assim deduzir se inserimos
        // um espaço vazio ou nào
        const treeFlags = this.computeTreeFlags()

        return this.nodes.map(node => {
            if (node.available) {
                return (<TreeNodeJSX
                    id = {node.id}
                    visible={node.visible}
                    testType = {this.testType}
                    node = {node}
                    mode = {this.studyMode}
                    config = {this.sessionConfig}
                    treeFlags = {treeFlags}
                    triggerTreeRender = {this.triggerTreeRender}
                    onNodeChecked = {this.onNodeChecked}
                    screenWidth = {this.screenWidth} // precisa ficar aqui, senão laga o app todo
                    // triggerTreeRender = {this.triggerTreeRender}
                    onTagpathSelected = {this.signalTagpathSelected}
                    userIsSearching = { this.searchedTheme !== '' } />)
            }
        })
    }


    computeTreeFlags() {
        const [maxAvailable, maxPending, maxFuture, maxSolved] = [
            n => n.info?.availableTests || 0,
            n => n.info?.pendingReviews || 0,
            n => n.info?.futureReviews || 0,
            n => n.info?.solved || 0
        ].map(fn => Math.max(0, ...this.nodes.map(fn)))
    
        return {
            maxAvailable,
            maxPending,
            maxFuture,
            maxSolved
        }
    }

    computeVisibleNodes(searchedString) {
        console.log(` * Tree: computando nós visíveis (termo buscado: "${searchedString}")...`)
        this.computeBasicVisibility()
        this.computeVisibilityBySearch(searchedString)
        // this.computeVisibilityByFilter(filter)

        this.computeVisibilityByFilter()
    }


    computeBasicVisibility() {
        this.nodes.forEach(node => {
            const isRootNode = (node.parent === undefined)
            const parentExpanded = (node.parent && node.parent.isExpanded)
            node.setBeingVisible( isRootNode || parentExpanded)
            node.setBeingSearched(false)
        })
    }


    computeVisibilityByFilter() {
        // Se não existe *NENHUM* teste que atende, nem mesmo em função dos ajustes do usuário,
        // não exibimos nada. Isso é particularmente importante na escolha de instituições/anos
        // de questões de residência.
        console.log(' * Computando visibilidade em função da busca...')
        this.nodes.forEach(node => {
            if (!node.info) {
                node.setAvailable(false)
                node.setBeingVisible(false)
            }
            else {
                let d = node.info
                
                    // Se for clear-history, e não tiver teste nenhum já visto, não mostramos
                    if (this.studyMode === 'clear-history') {
                        let s = d['pendingReviews'] + d['futureReviews'] + ( d['solved'] ?? 0)
                        node.setAvailable(s > 0)
                    }
                    else {
                        // Em função do config, quantos testes tempo disponíveis de fato?
                        let total = 0
                        if (!this.sessionConfig.removeNewTests) {
                            total += d['availableTests']
                        }
                        if (!this.sessionConfig.removePendingReviews) {
                            total += d['pendingReviews']
                        }
                        if (!this.sessionConfig.removeFutureReviews) {
                            total += d['futureReviews']
                        }
                        if (this.testType === TEST_TYPES.RESIDENCIA && !this.sessionConfig.removeSolved) {
                            total += d['solved']
                        }

                        node.setAvailable(total > 0)
                    }
                
            }
        })
    }

    computeVisibilityBySearch(searchedString) {      
        let adjSearchedString = this.adjustString(searchedString)
        if (adjSearchedString !== '') {
            console.log(`Tree: computando visibilidade pela busca de ${searchedString}...`)
            this.previousSearch = searchedString
            this.nodes.forEach(node => node.setBeingVisible(false))

            this.nodes.forEach(node => {   
                if ( this.adjustString(node.title).includes( adjSearchedString ) ) {
                    node.setBeingVisible(true)
                    node.setBeingSearched(true)

                    let parent = node.parent
                    while (parent) {
                        parent.setBeingVisible(true)
                        parent = parent.parent
                    }
                    
                    // Hoje, fazemos todas as crianças sob o node visíveis. *TODAS.
                    // Uma alternativa futura é só fazeras crianças imediatas, e devolver
                    // o botão de "expand". Ambas são igualmente fáceis, é que essa aqui
                    // ressalta a quantidade de conteúdo.
                    this.makeChildrenVsibile(node)
                }
            })
        }
    }   


    makeChildrenVsibile(node) {
        let children = node.children

        if (children) {
            for (let child of node.children) {
                child.setBeingVisible(true)
                this.makeChildrenVsibile(child) 
            }
        }
    }


    adjustString(str) {
        var unidecode = require('unidecode')
        return unidecode(str.trim().toLowerCase())
    }


    anyNodeVisible() {
        return this.nodes.some(node => node.visible)
    }

    anyNodeAvailable() {
        // this.nodes.forEach(node => {
        //     if (node.available) {
        //         // console.log('nó disponível:')
        //         // console.log(node)
        //     }
        // })

        return this.nodes.some(node => node.available)
    }

    // computeVisibilityByFilter(filter) {
    //     if (filter) {
    //         this.nodes.forEach(node => {
    //             // Na lógica do "se satisfizer a condição, então é visível", (quase) toda a 
    //             // árvore ficaria visível e expandida, e não é o que queremos.
    //             //
    //             // A ideia é que "se não satisfaz a condição, nunca deve ser visível".



    //            if ( !this.satisfyFilterCondition(filter, node) ) {
    //                 node.setBeingVisible(false)
    //                 node.setBeingFiltered(true)
    //            }
    //         })
    //     }
    // }


    // satisfyFilterCondition(filter, node) {
    //     // ATenção que precisa ser processado em treeJSX
        
    //     if (filter === TreeFilters.NEW_TESTS_ONLY) {  
    //         return node.info['availableTests'] > 0
    //     }
    //     else if (filter === TreeFilters.PENDING_REVIEWS_ONLY) {
    //         return node.info['pendingReviews'] > 0
    //     }
    //     else if (filter === TreeFilters.ALL_REVIEWS_ONLY) {
    //         return (node.info['pendingReviews'] + node.info['futureReviews']) > 0
    //     }        
    //     else if (filter === TreeFilters.SEEN_TESTS_ONLY) {
    //         // Lembrando que flashcards não tem
    //         let d = node.info
    //         let s = d['pendingReviews'] + d['futureReviews'] + ( d['solved'] ?? 0)
    //         return s > 0
    //     }
    // }


    extractCheckedNodes() {
        return this.nodes.flatMap(node => {

            if (node.available && node.isChecked) {
                return [tagSequenceToPath(node.tagSequence)]                
            }
            else {
                return []
            }
        })
    }


    extractCheckedLeafNodes() {
        return this.nodes.flatMap(node => {
            if (node.available && node.isChecked && !node.children) {
                return [tagSequenceToPath(node.tagSequence)]                
            }
            else {
                return []
            }
        })
    }


    extractNodeBackup() {
        return this.nodes.reduce((backup, node) => {
            if (node.isChecked || node.isMildlyChecked || node.isExpanded) {
                const path = tagSequenceToPath(node.tagSequence)            
                backup[path] = {
                    isChecked: node.isChecked,
                    isMildlyChecked: node.isMildlyChecked,
                    isExpanded: node.isExpanded,
                }
            }
            return backup
        }, {})
    }

    loadTreeFromPreviousNodes(previousCheckedNodes) {
        console.log('Tree: carregando a partir de um backup...')
        Object.entries(previousCheckedNodes).forEach(([path, status]) => {
            for (let node of this.nodes) {
                if (tagSequenceToPath(node.tagSequence) === path) {
                    node.isChecked = status.isChecked
                    node.isMildlyChecked = status.isMildlyChecked
                    node.isExpanded = status.isExpanded
                }
            }
        })
    
        let somethingChanged = false;
    
        // Primeiro passo: obter os leaf nodes
        const checkedLeafNodes = this.nodes.filter(node => 
            !node.children && // é leaf node
            node.isChecked // está marcado
        );
    
        // Para cada leaf node checado
        for (let leafNode of checkedLeafNodes) {
            // Verifica se ainda tem info
            if (!leafNode.info) {
                somethingChanged = true;
                
                // Percorre a cadeia de pais
                let currentParent = leafNode.parent;
                while (currentParent) {
                    if (currentParent.isChecked) {
                        // Se o pai era checked, muda para mildly checked
                        currentParent.isChecked = false;
                        currentParent.isMildlyChecked = true;
                        somethingChanged = true;
                    } 
                    else if (currentParent.isMildlyChecked) {
                        // Se o pai era mildlyChecked, verifica se tem siblings checked
                        const hasCheckedSiblings = currentParent.children.some(sibling => 
                            sibling !== leafNode && 
                            (sibling.isChecked || (sibling.children && sibling.isMildlyChecked))
                        );
                        
                        if (!hasCheckedSiblings) {
                            // Se não tem siblings checked, remove o mildlyChecked
                            currentParent.isMildlyChecked = false;
                            somethingChanged = true;
                        }
                    }
                    
                    currentParent = currentParent.parent;
                }
                
                // Desmarca o próprio leaf node
                leafNode.isChecked = false;
            }
        }
    
        return somethingChanged;
    }

    clearSelectedNodes() {
        // Iremos resetar a árvore de todas as seleções.
        this.nodes.forEach(node => {
            node.isChecked = false
            node.isMildlyChecked = false
        })
        
        this.triggerTreeRender()
    }
}

export default new Tree()