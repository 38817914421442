import LikedBuriedController from "../controllers/LikedBuriedController";
import SavedTestsScreen from "./SavedTestsScreen";
import { useEffect, useState } from "react";

import UserReviewsInfo from "../controllers/UserReviewsInfo";
import { useNavigate } from "react-router-dom";
import { setIsLoading } from "../redux/loadingSlice";

import { useDispatch, useSelector } from "react-redux";
import SkullColor from '../assets/skull_color.png'


export default function BuriedScreen(props) {
    const [selectedTests, setTests] = useState(false)
    const navigate = useNavigate() 
    const darkMode = useSelector(state => state.theme.darkModeOn)
    const dispatch = useDispatch()


    useEffect(() => {
        // Se não tem os dados necessários, enviamos à tela inicial.
        if (UserReviewsInfo.isReady()) {
            update()
        }
        else {
            console.log("LikedScreen(): exiting to /app because data not ready")
            navigate('/app')
        }
    }, [])


    function update() {
        /*
            Quando você usa setTests(obj), o estado do React cria uma referência ao objeto obj. 
            Qualquer modificação subsequente em obj também refletirá diretamente no estado, 
            porque ambos compartilham a mesma referência de memória. Isso significa que mesmo 
            sem uma chamada explícita a setTests(), mudanças em obj podem inadvertidamente 
            alterar o estado, levando a comportamentos imprevistos e bugs difíceis de rastrear. 
            Para evitar isso, é essencial usar {...obj} para passar uma cópia superficial do objeto
            a setTests(), garantindo que o estado mantenha uma referência única e independente.
        */
        setTests({...LikedBuriedController.buried})

        console.log(JSON.parse(JSON.stringify(LikedBuriedController.buried)))
    }


    async function eraseTests(testType, listTests) {
        dispatch(setIsLoading(true))

        await LikedBuriedController.buryOrUnburyAll(testType, listTests)
        update()
        
        dispatch(setIsLoading(false))
    }

    
    return (
       <>
       { selectedTests && 
            <SavedTestsScreen
                title = {'Testes Enterrados'}
                icon  = {SkullColor}

                selectedTests = { selectedTests }
                hideBury = {false}
                hideLike = {true}

                updateLikedScreen = {update}
                eraseTests = {eraseTests}


                bttnCall = 'Desenterrar todos'
                eraseMsg = {<><p>Se prosseguir, você irá <strong>desenterrar</strong> todos estes testes da sua lista de salvos. Não será mais possível acessá-los por aqui, e eles voltarão a aparecer.</p><p>Isso é <u>irreversível</u>.</p></>}
                
                flashcardsMsg = {'Você não tem flashcards enterrados.'}
                residenciaMsg = {'Você não tem questões enterradas.'} />
        }
        </>
    )
}