import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import AppContainer from '../app-container/AppContainer';
import CardList from '../components/CardList';
import GoToTopButton from '../components/GoToTopButton';
import SeeMoreButton from '../components/SeeMoreButton';
import { setIsLoading } from '../redux/loadingSlice';
import session from './../controllers/Session'
import "bootstrap/dist/css/bootstrap.css"
import { sleep } from '../utils/Utils';
import { useNavigate } from "react-router-dom";

import './ConsultScreen.css'
import IsLoading from '../main/IsLoading';
import styled from 'styled-components';
import { backgroundWhiteBlack } from '../tests/FlashcardsStyles';
import { ColumnCSS } from '../components/BasicComponents';


const ConsultScreenContainer = styled.div`
    ${ColumnCSS}
    max-width: 900px;
    margin-top: 2em;
    ${backgroundWhiteBlack}

    align-self: center;
`;


const ConsultScreenButtons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 15em;
`;



export default function ConsultScreen() {
    const dispatch = useDispatch()
    const navigate = useNavigate()


    useEffect(() => {
        if (!session || !session.listIDs || session.listIDs.length == 0) {
            console.log("ConsultScreen: session not loaded, going back.")
            // TODO Esse é o tipo de coisa que não é útil. Deveria throw um erro,
            // e jogar para outra tela.

            // Tecnicamente, não é necessário, pois só entramos nessa tela 
            // após pelo menos um dos testes ter sido carregado na Session. 
            // No fundo, é uma robustez contra bugs.
            navigate('/app')
        }   
    }, [])



    return (

            <ConsultScreenContainer>
                <CardList
                    tests = {session.listIDs}
                    testType = {session.testType}
                    sessionBuilt = {true} />    
            </ConsultScreenContainer>
    )
}