


export default class StopwatchController {
    constructor() {
        this.startTime = null
        this.elapsedTime = 0
        this.isRunning = false
    }
 
    start() {
        if (!this.isRunning) {
            this.startTime = Date.now()
            this.isRunning = true
        }
    }
 
    stop() {
        if (this.isRunning) {
            this.elapsedTime += Date.now() - this.startTime
            this.isRunning = false
        }
    }
 
    reset() {
        this.elapsedTime = 0
        this.isRunning = false
    }
 
    getElapsedTime(now = Date.now()) {
        const currentElapsed = this.isRunning 
            ? this.elapsedTime + (now - this.startTime) 
            : this.elapsedTime
    
        return Math.max(1, Math.round(currentElapsed / 1000))
    }
 }