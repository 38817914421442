import React, { useEffect, useState } from 'react'
import CustomStudy from './CustomStudy'
import AppContainer from '../app-container/AppContainer'
import ScreenContainerHeader from '../components/ScreenContainerHeader'
import FlashcardsIcon from './../assets/flashcard.png'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { guaranteeSubscription } from '../firebase/firebaseUtils'


export default function FlashcardsScreen() {
    const user = useSelector(state => state.user.data)
    const navigate = useNavigate()

    useEffect(() => {
        console.log("CustomStudy: checking subscription")
        guaranteeSubscription(user, navigate)
    }, [])


    const tabs = [
        { 
            id: 'themes', 
            label: 'Temas',
            content: <CustomStudy testType={'Flashcards'} />
        }
    ]

    return (
            <ScreenContainerHeader
                icon={FlashcardsIcon}
                title={'Flashcards'} 
                tabs={tabs} />
    )
}