
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts'
import { 
    Book, 
    BookOpen, 
    Bell, 
    Coffee, 
    ArrowRight, 
    Calendar, 
    Award, 
    Brain, 
    TrendingUp, 
    Target, 
    Clock,
    ChevronRight,
    Star,
    Timer,
    Tag,
    User,
    BarChart2,
    Check
} from 'lucide-react'

// Assets da plataforma
import ResidenciaIcon from './../assets/residencia.png'
import FlashcardIcon from './../assets/flashcard.png'
import DumbbellIcon from './../assets/dumbbell.png'
import TitleCard from '../custom/TitleCard'


import Balloons from './../assets/tree/balloons.png'
import { ColumnCSS, RowCSS } from '../components/BasicComponents'
import { PredefinedSessionConfig, SORT_MODES, STUDY_MODES, TEST_TYPES } from '../controllers/SessionConfig'
import { useSessionStarter } from '../utils/StartSessionMethods'
import UserReviewsInfo from '../controllers/UserReviewsInfo'





const SectionHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
`

const SectionTitle = styled.h2`
    font-size: 1.5rem;
    font-weight: 600;
    color: #2d3748;
    display: flex;
    align-items: center;
    gap: 12px;
`



// Seções base com design atualizado
const Section = styled.section`
    background: white;
    border-radius: 24px;
    padding: 32px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
    }
`

// Quick Actions com design mais elaborado
const QuickActionsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
`

const ActionCard = styled.button`
    display: flex;
    align-items: center;
    padding: 24px;
    background: ${props => props.$primary ? 'linear-gradient(135deg, #1a365d 0%, #2c5282 100%)' : 'white'};
    border: 1px solid ${props => props.$primary ? 'transparent' : '#e2e8f0'};
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: left;
    position: relative;
    overflow: hidden;
    
    &:hover {
        transform: translateY(-4px);
        box-shadow: ${props => props.$primary ? 
            '0 12px 24px rgba(26, 54, 93, 0.3)' : 
            '0 12px 24px rgba(0, 0, 0, 0.1)'};
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 80px;
        background: ${props => props.$primary ? 
            'linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.1))' : 
            'linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.02))'};
        transform: skewX(-15deg) translateX(100%);
        transition: transform 0.5s ease;
    }

    &:hover::after {
        transform: skewX(-15deg) translateX(-180%);
    }
`

const ActionIcon = styled.div`
    width: 56px;
    height: 56px;
    border-radius: 16px;
    background: ${props => props.$primary ? 'rgba(255, 255, 255, 0.15)' : '#f7fafc'};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    transition: transform 0.3s ease;

    img {
        width: 32px;
        height: 32px;
        filter: ${props => props.$primary ? 'brightness(0) invert(1)' : 'none'};
        transition: transform 0.3s ease;
    }

    ${ActionCard}:hover & {
        transform: scale(1.1);
    }
`
// lig
const ActionContent = styled.div`
    color: ${props => props.$primary ? 'white' : '#2d3748'};
    flex: 1;
`

const ActionTitle = styled.div`
    font-weight: 600;
    font-size: 1.1rem;
    margin-bottom: 6px;
`

const ActionDescription = styled.div`
    font-size: 0.9rem;
    opacity: 0.9;
    display: flex;
    align-items: center;
    gap: 6px;
`

const ActionProgress = styled.div`
    margin-top: 8px;
    height: 4px;
    background: ${props => props.$primary ? 'rgba(255, 255, 255, 0.2)' : '#edf2f7'};
    border-radius: 2px;
    overflow: hidden;
`

const ProgressBar = styled.div`
    width: ${props => props.value}%;
    height: 100%;
    background: ${props => props.$primary ? 'rgba(255, 255, 255, 0.8)' : '#4299e1'};
    border-radius: 2px;
    transition: width 0.3s ease;
`

////////



const ReviewGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5em;
    padding-top: 2em;
`


////

const ReviewItem = styled.div`
    ${RowCSS}

    width: 100%;
    padding: 1.5em;

    gap: 1em;

    // background: #f1f5f9;
    border: 1px solid rgba(0, 102, 204, 0.3);
    background-color: rgba(0, 102, 204, 0.05);

    border-radius: 1em;
    cursor: pointer;

    // Determina a velocidade do hover na sequência
    transition: all 0.3s ease;
    &:hover {
        border: 1px solid rgba(0, 102, 204, 0.9);
        background-color: rgba(0, 102, 204, 0.1);
    }
`


const IconWrapper = styled.div`
    ${RowCSS}    
    width: 20%;

    img {
        width: 3em;
        height: 3em;
        transition: transform 0.3s ease;
    }

    // Ao hover de ReviewItem, modifica a imagem
    ${ReviewItem}:hover & img {
        transform: scale(1.1) rotate(5deg);
    }
`

const TextContent = styled.div`
    ${ColumnCSS}
    align-items: flex-start;
    width: 80%;
    text-align: left;
`


const Text = styled.p`
    // Define a altura da linha como exatamente igual 
    // ao tamanho da fonte (100% da altura da fonte).
    line-height: 1;
    margin: 0;
    padding: 0;
`

const Count = styled(Text)`
    font-size: 2rem;
    font-weight: bold;
    color: #8E0011;
`

const Label = styled(Text)`
    font-size: 1rem;
    color: #475569;
`

const CompletedMessage = styled.div`
    font-size: 1rem;
    font-weight: 600;
    color: #16A34A;
    display: flex;
    align-items: center;
    gap: 0.5em;

    svg {
        width: 1.2em;
        height: 1.2em;
    }
`

const BalloonIcon = styled.img`
    width: 2em;
    height: 2em;
    position: absolute;
    top: -1em;
    right: -0.5em;
    animation: float 3s ease-in-out infinite;

    @keyframes float {
        0%, 100% { transform: translateY(0); }
        50% { transform: translateY(-5px); }
    }
`


function ReviewBox({ icon, count, label, onClick}) {
    return (
        <ReviewItem onClick = {onClick}>
            <IconWrapper>
                <img src={icon} alt={label} />
                {count === 0 && <BalloonIcon src={Balloons} alt="Celebração" />}
            </IconWrapper>
            <TextContent>
                {count === 0 ? (
                    <CompletedMessage>
                        <Check /> Tudo em dia!
                    </CompletedMessage>
                ) : (
                    <>
                        <Count>{count}</Count>
                        <Label>{label}</Label>
                    </>
                )}
            </TextContent>
        </ReviewItem>
    )
}


// Modificar o componente PremiumReviews para incluir todas as variações
export default function PremiumReviews() {
    const { startNewSession } = useSessionStarter()
    const [nFlashcards, setN] = useState( UserReviewsInfo.info['Flashcards'].pendingReviews.length)
    const [nResidencia, setR] = useState( UserReviewsInfo.info['Residencia'].pendingReviews.length)


    function startReviews(testType) {
        const config = PredefinedSessionConfig.create({
            ordering: SORT_MODES.SHUFFLE
        })

        startNewSession({
            testType: testType,
            builder: 'predefined',
            sessionConfig: config,
            studyMode: STUDY_MODES.TEST_MODE,
            saveAsLastSession: false,
            testIDs: UserReviewsInfo.info[testType].pendingReviews
        })
    }


    return (
        <TitleCard
            style = {{width: 'auto'}}
            title='Revisões'
            icon={DumbbellIcon}>
            

            <ReviewGrid>

                <ReviewBox
                    icon={FlashcardIcon}
                    count={nFlashcards}
                    label='cards pendentes'
                    onClick = {() => startReviews(TEST_TYPES.FLASHCARDS)} />

                <ReviewBox
                    icon={ResidenciaIcon}
                    count={nResidencia}
                    label='questões pendentes'
                    onClick = {() => startReviews(TEST_TYPES.RESIDENCIA)} />

                
            </ReviewGrid>
            
        </TitleCard>
    )
}