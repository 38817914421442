// Novembro, 2024
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useIdleTimer } from 'react-idle-timer'
import styled from 'styled-components'
import AppContainer from '../app-container/AppContainer'
import TestScreenHeader from './TestScreenHeader'
import TestScreenNavBar from './TestScreenNavBar'
import FlashcardQuestionJSX from './FlashcardQuestionJSX'
import McqQuestionJSX from './McqQuestionJSX'
import FlashcardAnswerScreen from './FlashcardAnswerScreen'
import McqAnswerScreen from './McqAnswerScreen'
import session from '../controllers/Session'
import { setIsLoading } from '../redux/loadingSlice'
import { sleep, toastMsg } from '../utils/Utils'
import { TEST_TYPES } from '../controllers/SessionConfig'
import GeneralDialog from './GeneralDialog'
import { ColumnCSS } from '../components/BasicComponents'
import { useToast } from '../components/OslerToast'
import IdleNotification from './IdleNotification'
import { TestArea } from './FlashcardsStyles'


const Content = styled.div`
    width: 100%;
    min-height: 95vh;
    padding: 0;
    margin: 0;
    z-index: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    ${props => {
        return props.theme.darkMode
            ? `     
                background-color: rgba(41, 41, 41, 1);  
            `
            : `
                background-color: #FBFBFB;

                @media (max-width: 500px) {
                    background-color: white;
                }
            `;
    }}
`

const TestContent = styled.div`
    position: relative;

    ${ColumnCSS}

    width: ${props => props.showingNotebook ? `90%` : `60%`};

    @media (max-width: 1200px) {
        width: ${props => props.showingNotebook ? `90%` : `80%`};
    }

    @media (max-width: 900px) {
        width: ${props => props.showingNotebook ? `90%` : `90%`};
    }
    
    @media (max-width: 500px) {
        width: ${props => props.showingNotebook ? `90%` : `100%`};
        min-height: 95vh;
    }
`


const IdleWarning = styled.div`
    padding: 0.75rem 1rem;
    border-radius: 0.375rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    
    ${props => props.severity === 'warning' ? `
        background: #fefcbf;
        border: 1px solid #f6e05e;
        color: #744210;
    ` : `
        background: #fde8e8;
        border: 1px solid #f98080;
        color: #c81e1e;
    `}
`


export default function TestScreen() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const showingNotebook = useSelector(state => state.notebook.showNotebook)
    
    const [screenType, setScreenType] = useState('question')
    const [currentTest, setCurrentTest] = useState(null)
    const currentTestID = useRef()


    const [showIdleDialog, setShowIdleDialog] = useState(false)


    useEffect(() => {
        if (!session?.created) {
            console.log("TestScreen: session not loaded, going back.")
            navigate('/app')
            return
        }

        if (session.areAllTestsAnswered()) {
            console.log("TestScreen: no more tests, going back")
            exitSession()
            return
        }

        console.log(`TestScreen: session loaded, will add listener`)
        session.addPermanentListener(updateScreen)
        updateScreen()

    }, [])


    async function exitSession() {
        console.log("TestScreen: will exit session...")
        dispatch(setIsLoading(true))

        await session.updateStatistics(false)
        await session.waitForAllStatisticsToBeUpdated()

        // Convém que seja após todas as estatísticas serem registradas porque...
        // essa é uma das variáveis que está sendo salva (Session >> log >> submittedToFirebase)
        // e seria estranho do contrário.
        await session.updateLastSession()
        
        dispatch(setIsLoading(false))
        navigate('/app')  
    }



    async function updateScreen() {
        const newID = session.getCurrentTestID()

        // Problema: quando enterra, o curentIndex não muda. 
        // É melhor comparar testes.
        if (currentTestID.current !== newID) {
            currentTestID.current = newID
            
            while (!session.currentTestIsReady()) {
                dispatch(setIsLoading(true))
                await sleep(100)
            }
            dispatch(setIsLoading(false))


            const isAnswered = session.isTestAnswered()
            setScreenType(isAnswered ? 'answer' : 'question')
            
            // Se não foi respondido ainda, iniciamos o timer
            if (!isAnswered) {
                console.log(` \t Iniciando mensuração do tempo para o teste atual`)
                session.startTestStopwatch()
            }
            
            setCurrentTest(session.getCurrentTest())
        }
    }


    const TEST_COMPONENTS = {
        [TEST_TYPES.FLASHCARDS]: {
            question: (props) => (
                <FlashcardQuestionJSX
                    moveEndQueue={moveEndQueue}
                    moveForward={showAnswer}
                    goToPreviousQuestion={goToPreviousQuestion}
                    {...props}
                />
            ),
            answer: (props) => (
                <FlashcardAnswerScreen
                    registerAnswer = {nextQuestion}
                    alreadyAnswered = {session.isTestAnswered()}
                    {...props}
                />
            )
        },
        [TEST_TYPES.RESIDENCIA]: {
            question: (props) => (
                <McqQuestionJSX
                    moveEndQueue={moveEndQueue}
                    moveForward = {showAnswer}
                    goToPreviousQuestion = {goToPreviousQuestion}
                    {...props}
                />
            ),
            answer: (props) => (
                <McqAnswerScreen
                    registerAnswer  = {nextQuestion}
                    alreadyAnswered = {session.isTestAnswered()}
                    {...props}
                />
            )
        }
    }


    function renderContent() {
        if (!currentTest) return null
        const components = TEST_COMPONENTS[session.testType]
        if (!components) return null

        const Component = components[screenType]
        return <Component test = {currentTest}/>
    }



    async function showAnswer() {
        if (!session.currentTestValid()) {
            console.log('\t\t\t - ERRO - Teste inválido no currentIndex atual')
            forward()
        } else {
            console.log('changing screen type')
            setScreenType('answer')
        }
    }
    

    async function nextQuestion(feedback) {
        console.log(`nextQuestion >>>>> CHAMADA! feedback: ${feedback}`)
        if (session.mode === 'test-mode') {
            session.logUserAnswer(feedback)
        }

        forward()
    }


    async function forward() {
        if (session.canMovetoNext()) {
            await session.moveToNext()
        } else {
            console.log(`TestScreen >> não há próximo teste...`)
            exitSession()
        }
    }
    

    function moveEndQueue() {
        session.moveCurrentTestToEndOfQueue()
    }

    async function goToPreviousQuestion() {
        if (session.canUndo()) {
            await session.undoLastAction()
        } else {
            toastMsg('⚠️ Você pode voltar apenas uma questão por vez!')
        }
    }




    return (
        <>


            <TestScreenHeader />
            <Content>
                <TestContent showingNotebook={showingNotebook}>
                    <TestScreenNavBar
                        allowGoBack={true}
                        onGoBackAction={exitSession} 
                    />


                    <TestArea>
                        <IdleNotification onExit={exitSession} />
                        
                        {renderContent()}

                    </TestArea>

                </TestContent>
            </Content>
        </>
    )
}