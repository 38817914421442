import React, { createContext, useContext, useState, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import { X } from 'lucide-react'

const ProgressBar = styled(motion.div)`
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    background: ${props => props.theme.darkMode ? '#60A5FA' : '#3B82F6'};
    border-radius: 0 0 12px 12px
`

const ToastItem = styled(motion.div)`
    position: relative;
    background: ${props => props.theme.darkMode ? '#1E40AF' : '#EFF6FF'};
    color: ${props => props.theme.darkMode ? 'white' : '#1E40AF'};
    padding: 1.25em 1.5em;
    margin-bottom: 16px;
    min-width: 400px;
    font-size: 1.3em;
    border-radius: 12px;
    box-shadow: 0 12px 32px -4px rgba(0, 0, 0, 0.2);
    border: 1px solid ${props => props.theme.darkMode ? '#2563EB' : '#BFDBFE'};
    backdrop-filter: blur(8px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    &:hover {
        transform: scale(1.02);
        transition: all 0.2s ease;
        box-shadow: 0 16px 40px -8px rgba(0, 0, 0, 0.25);
    }
`

const ToastContainer = styled.div`
    position: fixed;
    ${props => {
        switch(props.position) {
            case 'TOP':    return 'top: 5rem; left: 50%; transform: translateX(-50%);'
            case 'BOTTOM': return 'bottom: 5rem; left: 50%; transform: translateX(-50%);'
            default:       return 'top: 5rem; right: 5rem;'
        }
    }}
    z-index: 9999;
`

const CloseButton = styled(motion.button)`
    background: none;
    border: none;
    padding: 8px;
    color: ${props => props.theme.darkMode ? 'rgba(255,255,255,0.8)' : '#1E40AF'};
    cursor: pointer;
    border-radius: 6px;
    margin-left: 16px;
    display: flex;
    align-items: center;

    &:hover {
        background: ${props => props.theme.darkMode ? 'rgba(255,255,255,0.1)' : 'rgba(30,64,175,0.1)'};
    }
`
const ToastContext = createContext()

export const useToast = () => {
    const context = useContext(ToastContext)
    if (!context) throw new Error('useToast must be used within ToastProvider')
    return context
}

const Toast = ({ id, message, duration, onRemove }) => {
    const animationRef = useRef(null)
    const [progress, setProgress] = useState(100)
    
    useEffect(() => {
        const startTime = Date.now()
        const animate = () => {
            const elapsed = Date.now() - startTime
            const remaining = Math.max(0, 100 - (elapsed / duration) * 100)
            setProgress(remaining)
            
            if (remaining > 0) {
                animationRef.current = requestAnimationFrame(animate)
            } else {
                onRemove(id)
            }
        }
        animationRef.current = requestAnimationFrame(animate)
        return () => cancelAnimationFrame(animationRef.current)
    }, [])


    return (
        <ToastItem
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
        >
            <span>{message}</span>
            <CloseButton onClick={onRemove}>
                <X size={16} />
            </CloseButton>
            <ProgressBar
                initial={{ width: '100%' }}
                animate={{ width: `${progress}%` }}
                transition={{ duration: 0.01 }}
            />
        </ToastItem>
    )
}

export function OslerToast({ children }) {
    const [toasts, setToasts] = useState([])

    const [position, setPosition] = useState('TOP-RIGHT')
    
    const addToast = (message, options = {}) => {
        const { duration = 5000, position: newPosition } = options
        const id = Date.now()
        if (newPosition) setPosition(newPosition)
        setToasts(prev => [...prev, { id, message, duration }])
    }

    return (
        <ToastContext.Provider value={{ addToast }}>
            {children}
            {createPortal(
                <ToastContainer position={position}>
                    <AnimatePresence>
                        {toasts.map(toast => (
                            <Toast
                                key={toast.id}
                                id={toast.id}
                                message={toast.message}
                                duration={toast.duration}
                                onRemove={() => setToasts(prev => 
                                    prev.filter(t => t.id !== toast.id)
                                )}
                            />
                        ))}
                    </AnimatePresence>
                </ToastContainer>,
                document.body
            )}
        </ToastContext.Provider>
    )
}