import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Residencia from './../assets/residencia.png'
import GeneralDialog from '../tests/GeneralDialog'
import OslerButton from '../components/OslerButton'
import { ColumnCSS, RowCSS } from '../components/BasicComponents'
import SessionBuilder from '../controllers/SessionBuilder'

import SaveIcon from './../assets/save_folder.png'
import StartIcon from './../assets/tree/forward.png'
import CloseIcon from './../assets/x-icon.png'
import BackIcon from './../assets/left-arrow-black.png'
import EditIcon from './../assets/edit.png'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setIsLoading } from '../redux/loadingSlice'
import { db } from '../firebase/firebase-setup'
import Session from '../controllers/Session'
import { toastMsg } from '../utils/Utils'
import { nTestsPerPathIntoPredefined } from '../utils/SessionUtils'
import { TEST_TYPES } from '../controllers/SessionConfig'


const DialogContent = styled.div`
    width: 100%;
    padding: 1.25em;
    max-height: 70vh;
    overflow-y: auto;

    padding-bottom: 5em;

    ${ColumnCSS}

    @media (max-width: 768px) {
        padding: 0em;
    }
`



const PathRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.9375em;
    padding: 0.625em;
    background-color: #fff;
    border-radius: 0.5em;
    border: 1px solid #e0e0e0;
    transition: all 0.3s ease;

    ${props => props.theme.darkMode ? `
        background-color: rgb(51, 51, 51);
        border: 1px solid rgba(255, 255, 255, 0.1);
    ` : `
        background-color: #fff;
        border: 1px solid #e0e0e0;
    `}
    
    &:hover {
        transform: translateY(-2px);
        box-shadow: ${props => props.theme.darkMode ? 
            '0 4px 6px rgba(0, 0, 0, 0.2)' : 
            '0 4px 6px rgba(0, 0, 0, 0.1)'};
    }

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: stretch;
        gap: 0.5em;
        padding: 0.8em;
    }
`

const Column = styled.div`
    ${ColumnCSS}
`

const PathName = styled.span`
    font-size: 1.05em;
    color: ${props => props.theme.darkMode ? 'rgb(220, 220, 220)' : '#34495e'};
    font-weight: 600;
    flex-grow: 1;
    margin-right: 1em;

    @media (max-width: 768px) {
        font-size: 0.9em;
        margin-right: 0;
        margin-bottom: 0.3em;
    }
`

const InputWrapper = styled.div`
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
        justify-content: space-between;
    }
`


const SaveListSection = styled.div`
    margin-top: 0em;
    display: ${props => props.show ? 'block' : 'none'};
    width: 90%;

    @media (max-width: 768px) {
        width: 100%;
    }
`

const SaveListInput = styled.input`
    width: 100%;
    padding: 0.5em;
    margin-bottom: 1em;
    border: 1px solid #bdc3c7;
    border-radius: 0.25em;
    font-size: 1em;

    &:focus {
        outline: none;
        border-color: #3498db;
        box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
    }
`


const SaveButtonContainer = styled.div`
    width: 50%;
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 2em;

    @media (max-width: 768px) {
        width: 100%;
        flex-direction: column;
        gap: 1em;
    }
`


const TestInput = styled.input`
    width: 6em;
    padding: 0.5em 0.2em 0.5em 0.5em;
    border: 1px solid #bdc3c7;
    border-radius: 0.25em;
    font-size: 0.9em;
    text-align: center;
    margin-right: 0.5em;

    ${props => props.theme.darkMode ? `
        background-color: rgb(61, 61, 61);
        border: 1px solid rgba(255, 255, 255, 0.2);
        color: rgb(220, 220, 220);
        
        &:focus {
            border-color: #60a5fa;
            box-shadow: 0 0 0 2px rgba(96, 165, 250, 0.2);
        }
    ` : `
        background-color: white;
        border: 1px solid #bdc3c7;
        color: black;
        
        &:focus {
            border-color: #3498db;
            box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
        }
    `}
    
    &:focus {
        outline: none;
        border-color: #3498db;
        box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
    }

    @media (max-width: 768px) {
        width: 3em;
        padding: 0.3em;
    }
`

const MaxTests = styled.span`
    font-size: 0.8em;
    color: ${props => props.theme.darkMode ? 'rgb(180, 180, 180)' : '#7f8c8d'}; 
    width: 4em;
    text-align: left;
`

const NoFiltersMessage = styled.div`
    background-color: #dae9f7;
    padding: 0.25em 0.75em;
    border-radius: 0.8em;
    margin-top: 1em;

    p {
        font-size: 0.9rem;
        color: #555;
        margin: 1em 0;
    }

    @media (max-width: 600px) {
        padding: 0.1em 0.5em;
        margin-bottom: 0.5em;

        p {
            font-size: 0.8rem;
        }
    }
`

const ButtonContainer = styled.div`
    width: 80%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1em;
    margin-top: 2em;
    align-self: center;

    @media (max-width: 768px) {
        width: 100%;
        flex-direction: column;
        
        > * {
            width: 100%;
        }
    }
`

const ActionBttns = styled.div`
    ${RowCSS}

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 1em;
        width: 100%;

        > * {
            width: 100%;
            margin: 0 !important;
        }
    }
`



// a

const CommonSection = styled.div`
    width: 90%;
    align-self: center;
    margin: 0 0;

    @media (max-width: 768px) {
        width: 100%;
        padding: 0em !important;
        margin: 0;
    }
`

const EmptyPathMessage = styled(CommonSection)`
    text-align: center;
    padding: 2em;

    background-color: ${props => props.theme.darkMode ? 
        'rgb(51, 51, 51)' : 
        props.theme.colors?.lightBlue || '#f0f7ff'};
    
    border-radius: 1em;

    @media (max-width: 768px) {
        padding: 1.5em;
    }

    h4 {
        color: ${props => props.theme.darkMode ? 'rgb(220, 220, 220)' : '#2c3e50'};
        font-size: 1.15em;
        font-weight: 500;
        margin-bottom: 0.8em;

        @media (max-width: 768px) {
            font-size: 1em;
        }
    }

    p {
        color: ${props => props.theme.darkMode ? 'rgb(180, 180, 180)' : '#64748b'};
        font-size: 0.95em;
        line-height: 1.6;

        @media (max-width: 768px) {
            font-size: 0.9em;
        }
    }
`

const InfoSection = styled(CommonSection)`
    border-bottom: 1px solid ${props => props.theme.darkMode ? 
        'rgba(255, 255, 255, 0.1)' : 
        'rgba(0, 0, 0, 0.06)'};

    padding-bottom: 2em;
    margin-bottom: 2em !important;

    @media (max-width: 768px) {
        padding-bottom: 1.5em;
        margin-bottom: 1.5em !important;
    }
`

const InfoTitle = styled.h3`
    font-size: 1.1em;
    color: ${props => props.theme.darkMode ? 'rgb(220, 220, 220)' : '#1a1a1a'};
    font-weight: bold;
    margin-bottom: 0em;
    letter-spacing: -0.01em;
`

const TagsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 0.6em;
    margin-top: 0.8em;

    @media (max-width: 768px) {
        gap: 0.4em;
    }
`

const Tag = styled.span`
    background-color: ${props => props.theme.darkMode ? 
        'rgba(96, 165, 250, 0.1)' : 
        props.theme.colors?.superLightBlue || '#f0f7ff'};
    color: ${props => props.theme.darkMode ? 
        '#60a5fa' : 
        props.theme.colors?.primary || '#1672f6'};
    font-size: 0.9em;
    padding: 0.5em 1em;
    border-radius: 0.8em;
    transition: all 0.2s ease;
    font-weight: 450;

    &:hover {
        transform: translateY(-1px);
    }

    @media (max-width: 768px) {
        font-size: 0.8em;
        padding: 0.4em 0.8em;
        margin-bottom: 0.5em;
    }
`

const FiltersInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.8em;

    strong {
        color: #64748b;
        font-size: 0.9em;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 0.03em;
    }
`


// chique

const EditButton = styled.div`
    ${props => props.theme.darkMode ? `
        background-color: rgb(51, 51, 51);
        border: 1px solid rgba(255, 255, 255, 0.1);
        color: rgb(200, 200, 200);
    ` : `
        background-color: gray;
        border: 1px solid rgba(0, 0, 0, 0.06);
        color: white;
    `}
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 0.8em;
    padding: 1em 1.5em;
    font-size: 0.95em;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 0.8em;

    margin-top: 2em;
    margin-bottom: 2em;

    align-self: center;
    width: 50%;

    &:hover {
        background-color: ${props => props.theme.darkMode ? 
            'rgb(61, 61, 61)' : 
            '#f1f5f9'};
        transform: translateY(-1px);
    }

    @media (max-width: 800px) {
        width: 100%;
        padding: 0.8em 1.2em;
        font-size: 0.8em;
        margin-top: 1.5em;
        margin-bottom: 1.5em;
    }

    img {
        width: 1.2em;
        height: 1.2em;
        opacity: ${props => props.theme.darkMode ? 0.8 : 0.7};
        filter: ${props => props.theme.darkMode ? 'invert(1)' : 'none'};

        @media (max-width: 768px) {
            width: 1em;
            height: 1em;
        }
    }
`

const EditHelpText = styled(CommonSection)`
    background-color: ${props => props.theme.darkMode ? 
        'rgb(51, 51, 51)' : 
        '#dae9f7'};
    padding: 1.4em;
    border-radius: 0.8em;
    margin: 1em 0em 1em 0em;
    
    p {
        text-align: justify;
        color: ${props => props.theme.darkMode ? 
            'rgb(200, 200, 200)' : 
            '#334155'};
        font-size: 0.9em;
        line-height: 1.6;

        margin: 0;
        padding: 0;

        @media (max-width: 800px) {
            font-size: 0.85em;
        }
    }

    @media (max-width: 800px) {
        padding: 1em 0.75em !important;
        margin: 0 0 1em 0em !important;
    }
`

const NoTestsMessage = styled.div`
    ${ColumnCSS}
    margin-top: 1.5em;
    padding-top: 1.5em;
    
    p {
        color: #64748b;
        font-size: 0.85em;
    }
`

const ShowTagsButton = styled.span`
    background: none;
    border: none;   
    color: ${props => props.theme.darkMode ? '#60a5fa' : '#3b82f6'};
    cursor: pointer;
    padding: 0;
    margin-left: 0.5em;

    
    &:hover {
        text-decoration: underline;
    }
`

const MiniTag = styled(Tag)`
    font-size: 0.75em;
    padding: 0.3em 0.6em;
    opacity: 0.8;
    background-color: #ff365e;  
`

export default function CreateSessionDialog({ testType = 'Residencia', showDialog, setShowDialog, mode, paths, sessionConfig }) {
    const [pathData, setPathData] = useState(false)
    const [showSaveInput, setShowSaveInput] = useState(false)
    const [listName, setListName] = useState('')
    const [showPathInputs, setShowPathInputs] = useState(false)
    const [showNoTestsPaths, setShowNoTestsPaths] = useState(false)

    const user = useSelector(state => state.user.data)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        // Só executamos quando o diálogo está *visível*, porque ele já está montado continuamente
        // e não queremos que fique executando.
        if (showDialog) {
            // Ou o usuário selecionou tagpaths em específico (paths !== []), ou em não selecionar
            // nenhuma ele selecionou... todas. Se paths for vazio, não irá executar, mas também
            // não mostraremos possibilidade de edição.
            const newPathData = paths.map(path => {
                const pathParts = path.split('/')
                const name = pathParts[pathParts.length - 1]
    
                const tests = SessionBuilder.simulate({
                    testType,
                    builder: 'custom',
                    selectedTagpaths: [path],
                    sessionConfig
                 })
    
                const nTests = tests.length
    
                return { path, name, nTests, selectedTests: nTests }  // Inicializa com o máximo de testes
            })

            setPathData(newPathData)
        }
    }, [showDialog, paths, mode, sessionConfig])


    function handleTestChange(path, value) {
        // Não podemos fazer por index porque o array foi filtrado para a exibição (!)

        const index = pathData.findIndex(p => p.path === path)
        const newPathData = [...pathData]

        console.log(newPathData[index].nTests)

        newPathData[index].selectedTests = Math.max(0, Math.min(value, newPathData[index].nTests))
        setPathData(newPathData)
    }


    function extractInputs() {
        console.log(pathData)

        return pathData.reduce((acc, path) => {
            acc[path.path] = path.selectedTests
            return acc;
        }, {})
    }


    async function saveSession() {
        // Além de começar, salva como lista
        dispatch(setIsLoading(true))


        let IDs
        let nTestsPerPath = false

        if (paths && paths.length > 0) {
            nTestsPerPath = extractInputs()
            IDs = nTestsPerPathIntoPredefined(nTestsPerPath, sessionConfig)
        } 
        else {
            // Não há paths selecionados; o SessionBuilder carregará todos os IDs
            nTestsPerPath = false

            IDs = SessionBuilder.simulate({
                testType,
                builder: 'custom',
                selectedTagpaths: [],
                sessionConfig,
             })
        }


        const saveAsLastSession = (mode == 'test-mode')


        const docRef = await db.collection(`users/${user.id}/Residencia/lists/custom_lists`).add({
            name: listName,
            mode: mode,
            sessionConfig: sessionConfig,
            nTestsPerPath: nTestsPerPath,
            testIDs: IDs,
            createdAt: new Date().toLocaleString('pt-BR')
        })


        // Sem tagpaths -> todos os temas -> não tem IDs
        //      -> precisa ser carregado como custom
        // const builder = pathData.length === 0 ? 'custom' : 'predefined'
        const listID = docRef.id

        SessionBuilder.start({
            testType: TEST_TYPES.RESIDENCIA,
            builder: 'predefined',
            studyMode: mode,
            testIDs: IDs,
            sessionConfig,
            saveAsLastSession,
            descriptor: 'Estudo Customizado'
        })

        prepareSession(mode, listID)
    }


    function startSession() {
        dispatch(setIsLoading(true))

        const nTestsPerPath = extractInputs()
        const saveAsLastSession = (mode == 'test-mode')
        const IDs = nTestsPerPathIntoPredefined(nTestsPerPath, sessionConfig)

        const builder = pathData.length === 0 ? 'custom' : 'predefined'

        SessionBuilder.start({
            testType: TEST_TYPES.RESIDENCIA,
            builder,
            studyMode: mode,
            testIDs: IDs,
            sessionConfig,
            saveAsLastSession,
            descriptor: 'Estudo Customizado'
         })

        prepareSession(mode)
    }


    function prepareSession(mode, customList = false) {
        if (Session.sessionSize > 0) {
            if (customList) {
                Session.addList(customList)
            }
            Session.addTemporaryListener(mode === 'consult-mode' ? moveToConsult : moveToTests)
        }
        else {
            dispatch(setIsLoading(false))
            toastMsg("Não esperávamos por esse erro... Manda um print por DM. Desculpa. 🫠")
        }
    }


    function moveToTests() {
        navigate("/test")
        dispatch(setIsLoading(false))
    }


    function moveToConsult() {
        navigate("/consult")
        dispatch(setIsLoading(false))
    }



    if (!showDialog || !pathData) return
    return (
        <GeneralDialog
            open={showDialog}
            icon={Residencia}
            title='Crie sua sessão'
            onClose={() => setShowDialog(false)}
            bigWidth={true}
        >
            <DialogContent>                
                <InfoSection>
                    <InfoTitle>Temas selecionados</InfoTitle>
                    
                    {pathData.length === 0 ? (
                        <EmptyPathMessage>
                            <h4>Todos os temas foram selecionados.</h4>
                            <p>Você selecionou algumas instituições/anos, mas nenhum tema, então presumimos que você quer fazer todos.</p>
                        </EmptyPathMessage>
                    ) : (
                        <>
                            {!showPathInputs ? (
                                <Column>


                                <EditButton onClick={() => setShowPathInputs(true)}>
                                    <img src={EditIcon} alt="Editar" />
                                    Editar número de testes por tema
                                </EditButton>
                                </Column>
                            ) : (
                                <>

                                    <EditButton onClick={() => setShowPathInputs(false)}>
                                        <img src={BackIcon} alt="Voltar" />
                                        Não quero editar
                                    </EditButton>

                                    <EditHelpText>
                                        <p>Você pode ajustar o número de testes para cada tema; atente-se ao número máximo de questões que existem.</p>
                                        <p>Se quiser mais, você precisa selecionar mais instituições ou anos, e/ou remover ajustes (e.g., só questões novas).</p>
                                    </EditHelpText>

                                    {/* Paths com testes */}
                                    {pathData
                                        .filter(path => path.nTests > 0)
                                        .map((path, index) => (
                                            <PathRow key={index}>
                                                <PathName>{path.name}</PathName>
                                                <InputWrapper>
                                                    <TestInput
                                                        type='number'
                                                        min={1}
                                                        max={path.nTests}
                                                        value={path.selectedTests}
                                                        onChange={(e) => handleTestChange(path.path, parseInt(e.target.value))}
                                                    />
                                                    <MaxTests>máx. {path.nTests}</MaxTests>
                                                </InputWrapper>
                                            </PathRow>
                                        ))}

                                    {/* Paths sem testes */}
                                    {pathData.some(path => path.nTests === 0) && (
                                        <NoTestsMessage>
                                            <p>Não encontramos questões de alguns temas para os filtros que você selecionou.

                                            <ShowTagsButton onClick={() => setShowNoTestsPaths(!showNoTestsPaths)}>
                                                {showNoTestsPaths ? ' Ocultar temas.' : 'Mostrar quais.'}
                                            </ShowTagsButton>
                                            </p>

                                            
                                            {showNoTestsPaths && (
                                                <TagsContainer style={{ marginTop: '0.8em' }}>
                                                    {pathData
                                                        .filter(path => path.nTests === 0)
                                                        .map((path, index) => (
                                                            <MiniTag key={index}>{path.name}</MiniTag>
                                                        ))}
                                                </TagsContainer>
                                            )}
                                        </NoTestsMessage>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </InfoSection>

                <InfoSection>
                    <InfoTitle>Filtros aplicados</InfoTitle>
                    {(sessionConfig.institutions.length === 0 && sessionConfig.years.length === 0) ? (
                        <NoFiltersMessage>
                            <p>Não há filtros habilitados no momento.</p>
                        </NoFiltersMessage>
                    ) : (
                        <FiltersInfo>
                            {sessionConfig.institutions.length > 0 && (
                                <>
                                    <div>
                                        <strong>Instituições</strong>
                                        <TagsContainer>
                                            {sessionConfig.institutions.map((inst, index) => (
                                                <Tag key={index}>{inst}</Tag>
                                            ))}
                                        </TagsContainer>
                                    </div>
                                </>
                            )}
                            
                            {sessionConfig.years.length > 0 && (
                                <>
                                    <div>
                                        <strong>Anos</strong>
                                        <TagsContainer>
                                            {sessionConfig.years.map((year, index) => (
                                                <Tag key={index}>{year}</Tag>
                                            ))}
                                        </TagsContainer>
                                    </div>
                                </>
                            )}
                        </FiltersInfo>
                    )}
                </InfoSection>
                
                <SaveListSection show={showSaveInput}>
                    <InfoTitle>Salvar lista</InfoTitle>
                    <SaveListInput
                        type='text'
                        placeholder='Digite um nome para sua lista'
                        value={listName}
                        onChange={(e) => setListName(e.target.value)}
                    />
                </SaveListSection>


                {!showSaveInput &&
                <ButtonContainer>
                    <OslerButton
                        color='gray'
                        img={CloseIcon}
                        text='Fechar'
                        onClick={() => {
                            setShowSaveInput(false)
                            setListName('')
                            setShowDialog(false)
                        }}
                    />

                    <ActionBttns>
                        <OslerButton
                            color='blue'
                            img = {SaveIcon}
                            text='Salvar'
                            onClick={() => setShowSaveInput(true)}
                            style={{ marginRight: '1em' }}
                        />

                        <OslerButton
                            color='green'
                            img = {StartIcon}
                            text='Iniciar'
                            onClick={startSession}
                        />
                    </ActionBttns>
                </ButtonContainer>
                }
                { showSaveInput &&
                <SaveButtonContainer>
                    <OslerButton
                        color='gray'
                        img = {BackIcon}
                        text='Voltar'
                        onClick={() => {
                            setShowSaveInput(false)
                            setListName('')
                        }}
                    />

                    <OslerButton
                        color='green'
                        img = {StartIcon}
                        text='Salvar & Ir'
                        onClick={saveSession}
                    />
                </SaveButtonContainer>
                }


            </DialogContent>
        </GeneralDialog>
    )
}