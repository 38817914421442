import React, { cloneElement, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import Checkbox from './Checkbox'

// Importando ícones

import Notebook from './../assets/tree/notebook.png'
import Kettlebell from './../assets/tree/kettlebell.png'
import Rocket from './../assets/rocket.png'
import OwlIcon from './../assets/owl.png'
import SandboxIcon from './../assets/sandbox.png'
import BooksIcon from './../assets/books.png'

import MouseIcon from './../assets/mouse-cursor.png'
import HelpIcon from './../assets/question-new.png'
import GeneralDialog from '../tests/GeneralDialog'
import { SessionConfig, SORT_MODES, STUDY_MODES, TEST_TYPES } from '../controllers/SessionConfig'
import { isEqual } from 'remirror'
import { ColumnCSS, GlassyContainer, RowCSS } from '../components/BasicComponents'
import CollapsibleCard from './CollapsibleCard'
import HelpBttn from './HelpBttn'
import OslerCard from '../components/OslerCard'

import { Cog, Menu, MonitorCog, Settings, X } from 'lucide-react'
import { StudyModeStudyDialog, StudyModeConfigDialog } from './CustomStudyHelpDialogs'
import FloatingButton from './FloatingButton'
import StartBttnLuxury from './StartBttnLuxury'


import ControlIcon from './../assets/control.png'
import BroomIcon from './../assets/dust.png'
import Dumbbell from './../assets/tree/dumbbell.png'
import StartBttnFloat from './StartBttnFloat'
import { useWindowWidth } from '../components/useWindowWidth'
import CustomStudyPill from './CustomStudyPill'


// const SidebarWrapper = styled.div`
//     ${ColumnCSS}
//     width: 22%;
//     min-height: 100vh;

//     @media (max-width: 1200px) {
//         width: 100%;
//     }
// `



const Sidebar = styled.div`
    position: sticky;
    top: 0em;    


    ${ColumnCSS}
    width: 22%;

    // Distância *VERTICAL* entre os elementos
    gap: 1.2em;

    // Para nunca encostar no fim...
    padding-bottom: 2em;
`




const FilterSection = styled.div`
    ${ColumnCSS}
    align-items: flex-start;
    width: 100%;

    // background-color: cyan;

    // Importante para colocar a borda que separa as seções
    &:not(:last-child) {
        padding-bottom: 1em;
        border-bottom: 1px solid ${props => props.theme.darkMode ? 
            'rgba(255, 255, 255, 0.1)' : 
            'rgba(0, 0, 0, 0.06)'};
    }
`

const FilterGroup = styled.div`
    ${ColumnCSS}
    width: 100%;
    align-items: flex-start;

`

const FilterLabel = styled.div`
    ${RowCSS}

    cursor: pointer;
    color: ${props => props.theme.darkMode ? 'rgb(220, 220, 220)' : '#2c2c2c'};
 

    margin-bottom: 0.25em;
    padding: 0em 0.5em 0.5em 0.5em;

    background: transparent;

    &:hover {
        border-radius: 0.5em;
        background: ${props => props.theme.darkMode ? 
            'rgba(96, 165, 250, 0.1)' : 
            'rgba(22, 114, 246, 0.05)'};
        color: ${props => props.theme.darkMode ? '#60a5fa' : '#1672f6'};

        @media (max-width: 768px) {
            background: transparent;
        }
    }

    span {
    }

`


const ContentIcon = styled.img`
    width: 1.2em;
    height: 1.2em;
    margin: 0 0.25em 0 0.25em;
        
    opacity: 1;

    @media (max-width: 1100px) {
        width: 1em;
        height: 1em;
        margin: 0 0.25em 0 0em;
    }
`

const RecommendedBadge = styled.span`
    font-size: 0.7em;

    background-color: ${props => props.theme.darkMode ? 
        'rgba(96, 165, 250, 0.2)' : 
        'rgba(22, 114, 246, 0.1)'};
    color: ${props => props.theme.darkMode ? '#60a5fa' : '#1672f6'};

    padding: 0.2em 0.6em;
    border-radius: 4px;


    @media (max-width: 1100px) {
        margin-left: 0.5em;
    }
`




const Label = styled.div`
    ${ColumnCSS}
    align-items: flex-start;
    margin-left: 0.5em;

    p {
        font-size: 1em;
        margin: 0;
    }

    // É fundamental que, no float, isso aqui seja uma row, para não ficar alterando 
    // a altura do container quando muda o tipo de sessão e, assim, altera a presença da tag
    @media (max-width: 1100px) {
        ${RowCSS}
        align-items: center;
        margin-left: 0;
    }
`


const StudyModeContainer = styled.div`
    ${ColumnCSS}
    width: 100%;
    gap: 1em;


    @media (max-width: 1100px) {
        // Para adciionar barra no float
        gap: 0.5em;
        &:not(:last-child) {
            padding-top: -0.5em;
            padding-bottom: 0.5em;
            border-bottom: 1px solid ${props => props.theme.darkMode ? 
                'rgba(255, 255, 255, 0.1)' : 
                'rgba(0, 0, 0, 0.06)'};
        }
    }

`

const StudyModeOption = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    
    padding: 1em 0 1em 1.2em;
    border-radius: 16px;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    background-color: ${props => {
        if (props.theme.darkMode) {
            return props.selected ? 'rgba(96, 165, 250, 0.15)' : 'transparent';
        }
        return props.selected ? 'rgba(22, 114, 246, 0.08)' : 'transparent';
    }};
    
    border: 1px solid ${props => {
        if (props.theme.darkMode) {
            return props.selected ? 'rgba(96, 165, 250, 0.25)' : 'transparent';
        }
        return props.selected ? 'rgba(22, 114, 246, 0.15)' : 'transparent';
    }};

    position: relative;

    @media (max-width: 1200px) and (min-width: 769px) {
        padding: 1em;
    }

    @media (max-width: 768px) {
        padding: 0.5em;
        border-radius: 12px;
        height: 3em;
        align-items: center;
    }

    &:hover {
        background-color: ${props => {
            if (props.theme.darkMode) {
                return props.selected ? 'rgba(96, 165, 250, 0.2)' : 'rgba(255, 255, 255, 0.05)';
            }
            return props.selected ? 'rgba(22, 114, 246, 0.1)' : 'rgba(0, 0, 0, 0.03)';
        }};
        transform: translateY(-2px);
        box-shadow: ${props => {
            if (props.theme.darkMode) {
                return props.selected ? '0 8px 24px rgba(96, 165, 250, 0.15)' : '0 4px 12px rgba(0, 0, 0, 0.2)';
            }
            return props.selected ? '0 8px 24px rgba(22, 114, 246, 0.12)' : '0 4px 12px rgba(0, 0, 0, 0.05)';
        }};

        @media (max-width: 768px) {
            transform: none;
            box-shadow: none;
        }
    }

    &:active {
        transform: translateY(0);
    }
`

const ModeIcon = styled.img`
    width: 1.8em;
    height: 1.8em;
    margin-right: 1.2em;
    opacity: ${props => props.selected ? 1 : 0.7};
    transition: all 0.4s ease;
    filter: ${props => {
        if (props.theme.darkMode) {
            return props.selected ? 'brightness(1.2) invert(0.8)' : 'brightness(0.9) invert(0.8)';
        }
        return props.selected ? 'none' : 'grayscale(30%)';
    }};
    transform: ${props => props.selected ? 'scale(1.25) rotate(15deg)' : 'scale(1) rotate(0)'};

    @media (max-width: 1200px) and (min-width: 769px) {
        width: 1.4em;
        height: 1.4em;
        margin-right: 0.6em;    
    }

    @media (max-width: 768px) {
        width: 1.2em;
        height: 1.2em;
        margin-right: 0.6em;
        transform: ${props => props.selected ? 'scale(1.1) rotate(10deg)' : 'scale(1) rotate(0)'};
    }
`

const ModeLabel = styled.span`
    font-size: 1em;
    color: ${props => {
        if (props.theme.darkMode) {
            return props.selected ? '#60a5fa' : 'rgb(220, 220, 220)';
        }
        return props.selected ? '#1672f6' : '#444';
    }};
    font-weight: ${props => props.selected ? '600' : '450'};
    transition: all 0.3s ease;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        bottom: -4px;
        left: 0;
        width: ${props => props.selected ? '100%' : '0'};
        height: 2px;
        background: ${props => props.theme.darkMode ? '#60a5fa' : '#1672f6'};
        transition: all 0.3s ease;
        opacity: ${props => props.selected ? '1' : '0'};
        transform: translateY(${props => props.selected ? '0' : '4px'});
    }

    @media (max-width: 1200px) and (min-width: 769px) {
        font-size: 0.9em;
    }

    @media (max-width: 768px) {
        font-size: 0.8em;
    }   
`


const SectionTitle = styled.h4`
    font-size: 0.9em;
    color: ${props => props.theme.darkMode ? 'rgb(180, 180, 180)' : '#666'};

    // ATENÇÃO. O melhor não é editar a margem aqui, mas no próprio <div>, 
    // porque deixar uniforme é péssimo.



    font-weight: bold;

    // Importante para o método de estudo
    align-self: flex-start;

`


const FloatContainer = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 200;

    // Se mudar Height, precisa alterar MotionOverlay
    // em FloatingButton.js
    height: 5em;
    width: ${props => props.width}px;
    left: ${props => props.left}px;



    &::before {
        content: '';
        position: absolute;
        top: 0; // Aqui é a mudança principal - o glassy começa do topo do container
        left: 0;
        width: ${props => props.width}px;
        height: ${({ overlapHeight }) => overlapHeight}px; /* Altura do overlap */

        ${props => !props.expanded && `
            ${GlassyContainer};  
        `}

        // ${GlassyContainer};
        z-index: 201; /* Posicionado acima do background */
    }

    /* Garantir que o conteúdo esteja acima do ::before */
    > * {
        position: relative;
        z-index: 202;
    }


    ${RowCSS}
    justify-content: space-between;
    align-items: center;

    @media (max-width: 1100px) {
        padding-bottom: 1em;
    }

    @media (max-width: 800px) {
        padding: 0 1em 1em 1em;
    }

    @media (max-width: 600px) {
        // Se mudar Height, precisa alterar MotionOverlay
        // em FloatingButton.js
        height: 4.5em;

        width: 100vw !important;

        // É maior na direita devido à barra, para
        // dar a impressão correta
        padding: 0 1em 1em 0.5em;
    }
`


const SettingsBttns = styled.div`
    ${RowCSS}
    height: 100%;
    // background-color: yellow;
    gap: 0.5em;
`




const PillsContainer = styled.div`
    ${RowCSS}
    flex-wrap: wrap;
    gap: 0.5em;
    margin-bottom: 1em;
`

const FloatTitle = styled.div`
    ${RowCSS}
    justify-content: space-between;

    margin-bottom: 0.5em;
    p {
        margin: 0;
        padding: 0;
        font-weight: bold
    }
`


export default function CustomStudySideBar({ testType, bttns, onConfigChange, onModeChange, startBttn, saveFilterBttn, children, treeContainerWidth, nTests, hasSelections, pills, onStart, treeContainerRef}) {
    const [studyMode, setStudyMode] = useState(STUDY_MODES.TEST_MODE)
    const [config, setConfig] = useState(testType === 'Residencia' ?
        SessionConfig.RESIDENCIA_DEFAULT : SessionConfig.FLASHCARDS_DEFAULT)

    const previousConfig = useRef()

    const [expandedCards, setExpandedCards] = useState({
        studyMode: false,
        settings: false
    })


    // O scroll não é na window, mas no ScrollContainer de AppContainer
    const scrollContainer = document.querySelector('[tabindex="-1"]')

    useEffect(() => {
        // Only trigger if config actually changed
        if (!isEqual(previousConfig.current, config)) {
            previousConfig.current = config;
            onConfigChange?.(config)
        }
    }, [config, onConfigChange])


    useEffect(() => {
        onModeChange?.(studyMode)
    }, [studyMode, onModeChange])


    const contentOptions = [
        {
            id: 'NewTests',
            label: 'Novos',
            configKey: 'removeNewTests',
            recommended: studyMode === STUDY_MODES.TEST_MODE,
            icon: Notebook,
        },
        {
            id: 'PendingReviews',
            label: 'Revisões pendentes',
            configKey: 'removePendingReviews',
            recommended: studyMode === STUDY_MODES.TEST_MODE,
            icon: Dumbbell
        },
        {
            id: 'FutureReviews',
            label: 'Revisões futuras',
            configKey: 'removeFutureReviews',
            icon: Kettlebell
        },
        ...(testType === TEST_TYPES.RESIDENCIA ? [{
            id: 'Solved',
            label: 'Já resolvidas',
            configKey: 'removeSolved',
            icon: Rocket
        }] : [])
    ]


    const otherOptions = [
        ...(testType === TEST_TYPES.RESIDENCIA ? [{
            id: 'Anuladas',
            label: 'Incluir anuladas',
            configKey: 'removeAnuladas'
        }] : []),
        {
            id: 'Buried',
            label: 'Incluir enterrados',
            configKey: 'removeBuried'
        },
        {
            id: 'Random',
            label: 'Aleatorizar',
            configKey: 'ordering',
            isOrdering: true
        }
    ]

    const toggleContent = (configKey) => {
        setConfig(prev => ({
            ...prev,
            [configKey]: !prev[configKey]
        }))
    }

    const toggleSort = () => {
        setConfig(prev => ({
            ...prev,
            ordering: prev.ordering === SORT_MODES.SORT ? SORT_MODES.SHUFFLE : SORT_MODES.SORT
        }))
    }

    const toggleStudyMode = (newMode) => {
        setStudyMode(newMode)
        const newConfig = newMode === STUDY_MODES.TEST_MODE ?
            (testType === TEST_TYPES.RESIDENCIA ? SessionConfig.RESIDENCIA_DEFAULT : SessionConfig.FLASHCARDS_DEFAULT) :
            SessionConfig.createPlaygroundOrConsultMode(testType)
        setConfig(newConfig)
    }

    const studyModeOptions = [
        { id: STUDY_MODES.TEST_MODE, label: 'Teste', icon: OwlIcon },
        { id: STUDY_MODES.PLAYGROUND_MODE, label: 'Playground', icon: SandboxIcon },
        { id: STUDY_MODES.CONSULT_MODE, label: 'Consulta', icon: BooksIcon }
    ]

    const [showStudyModeDialog, setShowStudyModeDialog] = useState(false)
    const [showConfigDialog, setShowConfigDialog] = useState(false)

    
    function getModeOptions() {
        return (
            <StudyModeContainer>
                {/* <SectionTitle style={{ marginBottom: '0.6em' }}>
                    Qual o modo de estudo?
                </SectionTitle> */}

                {studyModeOptions.map(mode => (
                    <StudyModeOption
                        key={mode.id}
                        selected={studyMode === mode.id}
                        onClick={() => toggleStudyMode(mode.id)}
                    >
                        <ModeIcon
                            src={mode.icon}
                            alt={mode.label}
                            selected={studyMode === mode.id}
                        />
                        <ModeLabel selected={studyMode === mode.id}>
                            {mode.label}
                        </ModeLabel>
                    </StudyModeOption>
                ))}
        </StudyModeContainer>
        )
    }


    function getConfigOptions() {
        return (
            <>
                <FilterSection style={{ }}>
                    <SectionTitle style={{ marginBottom: '1em' }}>
                        {testType === TEST_TYPES.RESIDENCIA ? 'Quais questões incluir?' : 'Quais flashcards incluir?'}
                    </SectionTitle>
                    <FilterGroup style={{ gap: '0.3em' }}>
                        {contentOptions.map(option => (
                            <FilterLabel
                                key={option.id}
                                onClick={() => toggleContent(option.configKey)}
                            >
                                <Checkbox
                                    checked={!config[option.configKey]}
                                    action={(e) => {
                                        e.stopPropagation();
                                        toggleContent(option.configKey);
                                    }}
                                    style = {{marginRight: '0.5em'}}
                                />
                                {option.icon && (
                                    <ContentIcon 
                                        src={option.icon} 
                                        alt={option.label}
                                    />
                                )}
                                <Label>
                                    <p>{option.label}</p>
                                    {option.recommended && <RecommendedBadge>recomendado</RecommendedBadge>}
                                </Label>
                            </FilterLabel>
                        ))}
                    </FilterGroup>
                </FilterSection>

                <FilterSection style={{ margin: '1em 0', paddingBottom: '0' }}>
                    <SectionTitle style={{ marginBottom: '0.8em' }}>
                        Outras opções
                    </SectionTitle>
                    <FilterGroup style={{ gap: '0.2em' }}>
                        {otherOptions.map(option => (
                            <FilterLabel
                                key={option.id}
                                onClick={() => option.configKey === 'ordering' ?
                                    toggleSort() : toggleContent(option.configKey)} >

                                <Checkbox
                                    checked={option.configKey === 'ordering' ?
                                        config.ordering === SORT_MODES.SHUFFLE :
                                        !config[option.configKey]}
                                    action={(e) => {
                                        e.stopPropagation();
                                        option.configKey === 'ordering' ?
                                            toggleSort() : toggleContent(option.configKey);
                                    }}
                                    style = {{marginRight: '0.5em'}}
                                />
                                <Label>
                                {option.label}
                                </Label>
                            </FilterLabel>
                        ))}
                    </FilterGroup>
                </FilterSection>
            </>
        )
    }


    function getCurrentStudyModeIcon() {
        switch (studyMode) {
            case STUDY_MODES.TEST_MODE:
                return OwlIcon
            case STUDY_MODES.PLAYGROUND_MODE:
                return SandboxIcon
            case STUDY_MODES.CONSULT_MODE:
                return BooksIcon
            default:
                return OwlIcon
        }
    }


    const useWidth = () => {
        const [width, setWidth] = useState(window.innerWidth);
        useEffect(() => {
            const onResize = () => setWidth(window.innerWidth);
            window.addEventListener('resize', onResize);
            return () => window.removeEventListener('resize', onResize);
        }, []);
        return width;
    }

    const activateFloat = useWidth() < 1100


    const [anyDialogOpen, setAnyDialogOpen] = useState(false)

    useEffect(() => {
        setAnyDialogOpen(showConfigDialog || showStudyModeDialog)
    }, [showConfigDialog, showStudyModeDialog])

    
    const [expandedButtonId, setExpandedButtonId] = useState(null) // Qual botão está expandido?
    function handleToggle(buttonId) {
        setExpandedButtonId(prevId => (prevId === buttonId ? null : buttonId)) // Alterna entre expandido e recolhido
    }


    const floatingContainerRef = useRef(null)
    const [treeDimensions, setTreeDimensions] = useState(false)

    
    useEffect(() => {
        if (!treeContainerRef) return
        
        let timeoutId
        const resizeObserver = new ResizeObserver(() => {
            clearTimeout(timeoutId)
            timeoutId = setTimeout(() => {
                if (!treeContainerRef.current) return
                
                const rect = treeContainerRef.getBoundingClientRect()
                setTreeDimensions({
                    height: rect.height,
                    width: rect.width,
                    left: rect.left,
                })
            }, 100)
        })

        resizeObserver.observe(treeContainerRef)

        return () => {
            clearTimeout(timeoutId)
            resizeObserver.disconnect()
        }
    }, [treeContainerRef])


    const [overlapData, setOverlapData] = useState(false)

    useEffect(() => {
        function checkOverlap() {
            if (treeContainerRef && floatingContainerRef.current) {
                const tree = treeContainerRef.getBoundingClientRect()
                const floating = floatingContainerRef.current.getBoundingClientRect()

                // PRECISA TOMAR CUIDADO. Primeiro, o eixo y é no sentido inverso ao habitual.
                // Segundo, o getBoundingClientRect devolve as coordenadas renderizadas atualmente no viewport.
                //      Para elementos com position: fixed, as coordenadas serão relativas ao viewport.
                //      Para elementos scrolláveis, as coordenadas refletem sua posição atual dentro do viewport,
                //      que muda com o scroll.
                // Ou seja, paradoxalmente (?), quem está se movendo é quem está scrollando
                const isOverlapping = tree.bottom > floating.top && tree.top < floating.bottom
                const overlapHeight = Math.min(tree.bottom - floating.top, floating.height)
                const overlapTop = floating.top

                // Passar informações para FloatContainer
                setOverlapData({
                    isOverlapping,
                    overlapHeight,
                    overlapTop,
                })
            }
        }

        // Sem esse true, o scroll não dispara
        window.addEventListener('scroll', checkOverlap, true)
        window.addEventListener('resize', checkOverlap)
        checkOverlap()

        return () => {
            window.removeEventListener('scroll', checkOverlap, true)
            window.removeEventListener('resize', checkOverlap)
        }

        // Absurdo do zelo, mas garante que atualiza em tempo real 
        // quandoexpande a árvore do nada
    }, [treeContainerRef, treeDimensions])



    useEffect(() => {
        // Extremo do zelo; do contrário trava o scroll
        const handleScroll = (e) => {
            e.preventDefault()
            
            // O scroll não é na window, mas no scrollContainer
            if (scrollContainer) {
                scrollContainer.scrollTop += e.deltaY
            }
        }

        const floatContainer = floatingContainerRef.current
        if (floatContainer) {
            floatContainer.addEventListener('wheel', handleScroll, { passive: false })
        }

        return () => {
            if (floatContainer) {
                floatContainer.removeEventListener('wheel', handleScroll)
            }
        }
    }, [])
      


    const [showScrollBttn, setShowScrollBttn] = useState(false)

    useEffect(() => {
        const toggleVisibility = () => {
            // Mostra botão quando scroll > 300px

            // O scroll não é na window, mas no scrollContainer
            setShowScrollBttn(scrollContainer.scrollTop > window.innerHeight * 0.2)
        }

        // Adiciona o listener
        window.addEventListener('scroll', toggleVisibility, true)

        // Cleanup - remove o listener quando o componente é desmontado
        return () => {
            window.removeEventListener('scroll', toggleVisibility, true)
        }
    }, [])


    // const hasValidPills = () => pills.some(pill => pill.count > 0)
    const isLastPill = () => pills.filter(pill => pill.count > 0).length === 1


    return (
        <>
        <StudyModeStudyDialog
            open={showStudyModeDialog}
            onClose={() => setShowStudyModeDialog(false)} />


        <StudyModeConfigDialog
            open={showConfigDialog}
            onClose={() => setShowConfigDialog(false)} />

                    
        { !activateFloat &&
            <Sidebar>
                
                <CollapsibleCard
                    title="Modo de Estudo"
                    isExpanded={expandedCards.studyMode}
                    onToggle={() => setExpandedCards(prev => ({ ...prev, studyMode: !prev.studyMode }))}
                    rightComponent = { 
                        <HelpBttn onClick={e => {
                                e.stopPropagation()
                                e.preventDefault()
                                setShowStudyModeDialog(true)
                            }} />
                    }
                >

                    { getModeOptions() }
    
                </CollapsibleCard>


                <CollapsibleCard
                    title="Ajustes"
                    isExpanded={expandedCards.settings}
                    onToggle={() => setExpandedCards(prev => ({ ...prev, settings: !prev.settings }))}
                    rightComponent={
                        <HelpBttn onClick={() => setShowConfigDialog(true)} />
                    }
                >
                    
                    { getConfigOptions() }

                </CollapsibleCard>

                { saveFilterBttn }

                <StartBttnLuxury
                    testType={testType}
                    nTests = {nTests}
                    hasSelections = {hasSelections}
                    pills = {pills}
                    onStart={onStart} />

            </Sidebar>
        }


        { activateFloat && 
                <FloatContainer width = {treeDimensions.width} left = {treeDimensions.left} ref={floatingContainerRef}
                                expanded = {expandedButtonId !== null} 
                    isOverlapping = {overlapData.isOverlapping}
                    overlapTop={overlapData.overlapTop}
                    overlapHeight={overlapData.overlapHeight}>
                        
                    <SettingsBttns>
                        <FloatingButton 
                            icon={getCurrentStudyModeIcon()} 
                            expanded={expandedButtonId === 'studyMode'}
                            onToggle={() => handleToggle('studyMode')} >
                            <FloatTitle>
                                <p>Modo de estudo</p>
                                <HelpBttn onClick={() => setShowStudyModeDialog(true)} />
                            </FloatTitle>

                            {getModeOptions()} 
                        </FloatingButton>

                        <FloatingButton 
                            icon={ControlIcon} 
                            expanded={expandedButtonId === 'settings'}
                            onToggle={() => handleToggle('settings')} >
                            <FloatTitle style={{ marginTop: '1em' }} >
                                <p>Ajustes</p>
                                <HelpBttn onClick={() => setShowConfigDialog(true)} />
                            </FloatTitle>

                            {getConfigOptions()} 
                        </FloatingButton>

                        { showScrollBttn && (
                            <FloatingButton 
                                icon={MouseIcon}
                                expanded={expandedButtonId === 'scroll'}
                                onToggle={() => {
                                    scrollContainer.scrollTo({
                                        top: 0,
                                        behavior: 'smooth'
                                    })
                                }}>
                                {saveFilterBttn}
                            </FloatingButton>

                        )}

                        {hasSelections && (
                            <FloatingButton 
                                icon={BroomIcon}
                                expanded={expandedButtonId === 'pills'}
                                onToggle={() => handleToggle('pills')}
                            >
                                <PillsContainer>
                                    {pills.map((pill, index) => (
                                        <CustomStudyPill 
                                            key={index} 
                                            data={pill}
                                            onClearExtra={() => {
                                                if (isLastPill()) {
                                                    handleToggle(false)
                                                }
                                            }} />
                                    ))}
                                </PillsContainer>
                                {saveFilterBttn}
                            </FloatingButton>
                        )}
                    </SettingsBttns>

                    <StartBttnFloat
                        testType={testType}
                        nTests={nTests}
                        hasSelections={hasSelections}
                        onStart={onStart} />
                </FloatContainer>
            }

        </>
    )
}



// { activateFloat && (
//     <Float>
        // <FloatingButton
        //     icon={mobileExpanded ? <X size={24} /> : <Menu size={24} />}
        //     position="left"
        //     expanded={mobileExpanded}
        //     onToggle={setMobileExpanded}
        // >
        //     {/* {getModeOptions()} */}
        //     {/* {getConfigOptions()} */}
        // </FloatingButton>

//     { cloneElement(startBttn, { hideOnMobile: mobileExpanded }) }

//     </Float>
// )}