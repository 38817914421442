// Outubro, 2024
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import ExpandIcon from './../assets/tree/right-chevron2.png'
import CollapseIcon from './../assets/tree/down-arrow2.png'


const IconContainer = styled.div`
    cursor: pointer;

    margin-right: 0.5em;
    filter: ${props => props.theme.darkMode ? 'invert(0.5) brightness(1.5)' : 'none'};


    transition: transform 0.2s ease-in-out;
    &:hover {
        transform: scale(1.1);
    }


    // background-color: pink;

`

const Icon = styled.img`
    width: 0.9em;
    
    opacity: ${props => props.theme.darkMode ? 0.8 : 1};
    transition: filter 0.3s ease, opacity 0.3s ease;

`

const Empty = styled.div`
    margin-right: 0.5em;
    width: 0.9em;
    opacity: ${props => props.theme.darkMode ? 0.5 : 1};

`

export default function ExpandCollapse({ expanded, action, empty = false, style}) {

    const iconSrc = expanded ? CollapseIcon : ExpandIcon

    if (empty) {
        return <Empty/>
    }

    return (
        <IconContainer onClick={(e) => action(e)} style = {style}>
            <Icon src={iconSrc} alt={expanded ? 'Collapse' : 'Expand'} />
        </IconContainer>
    )
}