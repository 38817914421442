import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { ColumnCSS, RowCSS } from './BasicComponents'
import { HeaderDropdown } from './HeaderDropdown'
import { useWindowWidth } from './useWindowWidth'

const Screen = styled.div`
    ${ColumnCSS}
    display: flex;
    flex-direction: column;

    min-height: 100dvh;
    width: 100%;

    // CRUCIAL para o conteúdo nunca encostar no fim da tela, o que é muuuuuito feio.
    padding-bottom: 5em;
    
    @media (max-width: 1200px) {
        padding-bottom: 4em;
    }

    @media (max-width: 800px) {
        padding-bottom: 3em;
    }


`

const Header = styled.header`
    ${RowCSS}
    width: 100%;

    // Atenção a esse padding, ele é fundamental para que o 
    // conteúdo sob a tela esteja alinhado
    // com o ícone do header e com os elementos da tablist
    padding: 2em 0 1.25em 2em;

    background-color: ${props => props.theme.darkMode ? 'rgb(41, 41, 41, 1)' : 'white'};

    border-bottom: ${props => props.theme.darkMode ? '1px solid rgba(255, 255, 255, 0.1)' : 'none'};

    @media (max-width: 600px) {
        // Para ficar simétrico com OslerCard no mobile
        padding: 1em 0em 0.5em 0.75em;
    }
`

const Icon = styled.img`
    // Nem todas as imagens são quadradas, então determinamos só a altura.
    height: 3em;

    @media (max-width: 1100px) {
        height: 2.8em;
    }

    @media (max-width: 800px) {
        height: 2.5em;
    }


    @media (max-width: 600px) {
        height: 2.25em;
    }

`

const Title = styled.h1`
    color: ${props => props.theme.darkMode ? 'rgb(220, 220, 220)' : 'black'};
    font-size: 2.4em;

    // precisa zerar, senão entra o default do h1
    padding: 0;
    margin: 0 0 0 0.5em;

    @media (max-width: 1100px) {
        font-size: 2.2em;
    }

    @media (max-width: 800px) {
        font-size: 2em;
    }

    @media (max-width: 600px) {
        font-size: 1.6em;
    }
`


const Content = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;

    // O padding à esquerda garante que o conteúdo da página está alinhado com o ícone do header
    // e, se houver, com o primeiro elemento da tab. O padding à direita mantém simetria. O padding
    // do topo impedeq que o conteúdo encoste no header..
    padding: 1.5em 2em 0 2em;

    // Cor cinza claro, para facilitar contrast com cards brancos
    background-color: ${props => props.theme.darkMode ? 'rgb(41, 41, 41, 1)' : 'rgb(245, 245, 245)'};


    @media (max-width: 850px) {
        // *CRÍTICO* à CustomStudy; a partir desta width, colabamos tudo
        // para tentar fazer a árvore caber sem conflito.
        padding: 1.25em 0.5em 0em 0.5em;
    }

    @media (max-width: 450px) {
        // **CRÍTICO**. Para surpresa de todos, um iPhone é muito pequeno, e o resto
        // é menor. No Brasil, as widths vão de 380 a 420, mas há até 360...
        padding: 1.25em 0.1em 0em 0.1em;

    }
`


// a


export const TabListGeneric = styled.div`
    ${RowCSS}
    justify-content: space-between;
    width: 100%;
    margin: 0;

    border-bottom: 1px solid ${props => props.theme.darkMode ? 'rgba(255, 255, 255, 0.1)' : '#d2d2d7'};
`


const TabList = styled(TabListGeneric)`
    padding: 1em 2em 0 2em;
    flex-wrap: wrap;

    @media (max-width: 600px) {
        padding: 0.5em 1em 0.75em 1em; // Reduzido
        flex-direction: column;
        gap: 0.4em; // Reduzido
        border-bottom: 1px solid ${props => props.theme.darkMode ? 
            'rgba(255, 255, 255, 0.1)' : 
            'rgba(0, 0, 0, 0.1)'};
    }
`;

const TabsGroup = styled.div`
    ${RowCSS}
    gap: 1em;
`;


// Container para envolver o TabList em telas pequenas
const TabListContainer = styled.div`
    width: 100%;
    background-color: ${props => props.theme.darkMode ? 'rgb(41, 41, 41, 1)' : 'white'};

    ${RowCSS}
    justify-content: flex-start;
`;


export const TabItem = styled.div`
    ${RowCSS}
    gap: 0.5em;
    align-items: center;

    cursor: pointer;

    font-size: 1.1em;
    font-weight: bold;

    color: ${props => {
        if (props.theme.darkMode) {
            return props.active ? '#60a5fa' : 'rgb(220, 220, 220)';
        }
        return props.active ? '#06c' : '#1d1d1f';
    }};

    p {
        margin: 0;
        padding: 0;
        white-space: nowrap;
    }


    // Para a bottom-bar que aparece na animação
    transition: all 0.3s ease;

    padding: 0.6em 0.6em 0.6em 0.6em;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;

    position: relative;

    &::after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: ${props => props.theme.darkMode ? '#60a5fa' : '#06c'};
        transform: scaleX(${props => props.active ? 1 : 0});
        transition: transform 0.3s ease;
        z-index: 1;
    }

    &:hover {
        color: ${props => props.theme.darkMode ? '#60a5fa' : '#06c'};
        img {
            opacity: 1;
        }
    }

    
    img {
        height: 1.4em;
        width: auto;
        opacity: ${props => props.active ? 1 : 0.7};
        transition: opacity 0.3s ease;
        margin-right: 0.5em;  // Padronizado para todas as telas
        filter: ${props => props.theme.darkMode ? 'none' : 'none'};

    }



        &:hover {
            background-color: ${props => props.theme.darkMode ?
                'rgba(96, 165, 250, 0.1)' :
                'rgba(0, 118, 255, 0.05)'};
            transform: none; // Removido o efeito de elevação
        }

        ${props => !props.label && `
            width: auto;
            padding: 0.4em;
            justify-content: center;
            
            &:hover {
                background-color: ${props.theme.darkMode ?
                    'rgba(255, 255, 255, 0.05)' :
                    'rgba(0, 0, 0, 0.03)'};
                border-radius: 6px;
            }
        `}

        img {
            height: 1.2em; // Reduzido
            margin-right: ${props => props.label ? '0.5em' : '0'};
        }
    }
`



const ScreenContainerHeader = ({icon, title, 
    tabs = [],
    onTabChange,
    children
}) => {


    const [selectedTab, setSelectedTab] = useState()

    useEffect(() => {
        // Só muda a tab se:
        // - Não tiver tab selecionada ainda
        // - Ou se a tab atual não existir mais no novo array
        if (!selectedTab || !tabs.some(tab => tab.id === selectedTab)) {
            console.log('ScreenContainerHeader >> setting tab')
            setSelectedTab(tabs[0]?.id)
        }
    }, [tabs, selectedTab])

    
    const handleTabChange = (tabId) => {
        console.log('Mudando a aba para ', tabId)
        setSelectedTab(tabId)
        onTabChange?.(tabId)
    }

    const [isDropdownOpen, setIsDropdownOpen] = useState(false)


    const normalTabs = tabs.filter(tab => tab.label)
    const iconOnlyTabs = tabs.filter(tab => !tab.label)

    const width = useWindowWidth()
                        
    return (
        <Screen>
            <Header>
                <Icon src={icon} alt={`${title} icon`} />
                <Title>{title}</Title>
            </Header>

            {tabs.length > 1 && width > 800 && (
                <TabListContainer>
                    <TabList>
                        <TabsGroup>
                            {normalTabs.map((tab) => (
                                <TabItem
                                    key={tab.id}
                                    active={selectedTab === tab.id}
                                    onClick={() => handleTabChange(tab.id)}
                                    label={tab.label}
                                >
                                    {tab.icon && <img src={tab.icon} alt={tab.label} />}
                                    <p>{tab.label}</p>
                                </TabItem>
                            ))}
                        </TabsGroup>

                        <TabsGroup>
                            {iconOnlyTabs.map((tab) => (
                                <TabItem
                                    key={tab.id}
                                    active={selectedTab === tab.id}
                                    onClick={() => handleTabChange(tab.id)}
                                >
                                    <img src={tab.icon} alt={tab.id} />
                                </TabItem>
                            ))}
                        </TabsGroup>
                    </TabList>
                </TabListContainer>
            )}
            {tabs.length > 1 && width <= 800 && ( 
                <TabListContainer>
                    <HeaderDropdown
                        tabs={tabs}
                        selectedTab={selectedTab}
                        onTabChange={handleTabChange}
                    />
                </TabListContainer>

            )}

            
            <Content>
                {tabs.find(tab => tab.id === selectedTab)?.content}

                { children }
            </Content>
        </Screen>
    )
}

export default ScreenContainerHeader