import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Rating } from 'ts-fsrs';
import OslerFSRS from './../controllers/OslerFSRS'

const Container = styled.div`
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    max-width: 600px;
    margin: 20px auto;
    text-align: center;
`


const ButtonGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    max-width: 400px;
    margin: 20px auto;
`


const Button = styled.button`
    padding: 10px;
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    width: 100%;

    &.again { background-color: #ef4444; }
    &.hard { background-color: #eab308; }
    &.good { background-color: #22c55e; }
    &.easy { background-color: #3b82f6; }
    &.reset { 
        background-color: #6b7280;
        margin: 20px auto;
        max-width: 200px;
    }
`


const NextTime = styled.div`
    font-size: 14px;
    margin-top: 4px;
`


const Stats = styled.div`
    margin: 20px auto;
    padding: 10px;
    background-color: #f3f4f6;
    border-radius: 4px;
    max-width: 400px;
`;

const History = styled.div`
    margin: 20px auto;
    padding: 10px;
    max-height: 200px;
    overflow-y: auto;
    max-width: 400px;
    border: 1px solid #e5e7eb;
    border-radius: 4px;

    p {
        margin: 5px 0;
        padding: 5px;
        border-bottom: 1px solid #e5e7eb;
    }
`;

const CardInfo = styled.div`
    text-align: left;
    margin: 20px auto;
    padding: 10px;
    background-color: #f3f4f6;
    border-radius: 4px;
    max-width: 400px;

    h3 {
        margin-bottom: 10px;
        text-align: center;
    }

    p {
        margin: 5px 0;
        font-family: monospace;
    }
`

const Section = styled.div`
    margin: 20px 0;
`

const SectionTitle = styled.h4`
    margin-bottom: 10px;
    text-align: center;
`

const LogEntry = styled.div`
    margin-bottom: 10px;
    padding: 5px;
    border-bottom: 1px solid #ccc;
`

const Entry = styled.p`
    font-family: monospace;
    margin: 2px 0;
`

const LogIndex = styled.div`
    background-color: #e5e7eb;
    color: #374151;
    font-weight: bold;
    font-size: 12px;
    padding: 2px 8px;
    border-radius: 12px;
    display: inline-block;
    margin-bottom: 5px;
`




const getFakeNow = () => {
    // Deixei como 23:50 para testar a virada do dia, mas a ideia é poder variar mesmo

    
    const now = new Date()
    now.setHours(23, 50, 0, 0)

    return now
}


export default function SimulatorFSRS() {

    const [nextTimes, setNextTimes] = useState({})
    const [fakeNow, setFakeNow] = useState( getFakeNow() )
    const [flashcard, setFlashcard] = useState(() => {
        return OslerFSRS.createNewFlashcard()
    })
    

        

    useEffect(() => {
        setNextTimes(calculateNextTimes())
    }, [flashcard, fakeNow])


    const calculateNextTimes = () => {
        const reviews = OslerFSRS.getReadableTimeUntilNextReview(flashcard, fakeNow)

        return {
            [Rating.Again]: reviews[0],
            [Rating.Hard]:  reviews[1],
            [Rating.Good]:  reviews[2],
            [Rating.Easy]:  reviews[3],
        }
    }

    // const [history, setHistory] = useState([])



    // const formatCardInfo = (card) => {
    //     return Object.entries(card).map(([key, value]) => {
    //         if (value instanceof Date) {
    //             return `${key}: ${value.toISOString()}`;
    //         }
    //         return `${key}: ${value}`;
    //     });
    // };

    // const formatDate = (date) => {
    //     const diff = date.getTime() - fakeNow.getTime();
    //     const minutes = Math.round(diff / (1000 * 60));
        
    //     if (minutes < 60 * 24) return `${minutes} minutes`;
    //     const days = Math.round(minutes / (60 * 24));
    //     return `${days} days`;
    // };


    
    
    const handleRating = (rating) => {
        const nextReview = OslerFSRS.getNextReviewForRating(flashcard, rating, fakeNow)

        // const updatedCard = nextReview.card
        // const updatedFakeNow = new Date(updatedCard.due)
        // const ratingName = Rating[rating]

    
        setFlashcard(nextReview)


        const reviewDate = new Date(nextReview.card.due)
        reviewDate.setHours(23, 50, 0, 0)

        setFakeNow(reviewDate)


        // // setHistory(prev => [...prev, `${ratingName}: ${formatDate(updatedFakeNow)}`])
    
        // const newNextTimes = calculateNextTimes(updatedCard)
        // setNextTimes(newNextTimes)
    }


    const handleReset = () => {
        setFlashcard(OslerFSRS.createNewFlashcard())
        setFakeNow( getFakeNow() )
        // setHistory([])
        // setNextTimes(calculateNextTimes(newFlashcard.card))
    }
    
    
    return (
        <Container>
            <h2>Osler FSRS Simulator</h2>

            {fakeNow.toString()}

            <ButtonGrid>
                <div>
                    <Button className="again" onClick={() => handleRating(Rating.Again)}>
                        Again
                    </Button>
                    <NextTime>{nextTimes[Rating.Again]}</NextTime>
                </div>
                <div>
                    <Button className="hard" onClick={() => handleRating(Rating.Hard)}>
                        Hard
                    </Button>
                    <NextTime>{nextTimes[Rating.Hard]}</NextTime>
                </div>
                <div>
                    <Button className="good" onClick={() => handleRating(Rating.Good)}>
                        Good
                    </Button>
                    <NextTime>{nextTimes[Rating.Good]}</NextTime>
                </div>
                <div>
                    <Button className="easy" onClick={() => handleRating(Rating.Easy)}>
                        Easy
                    </Button>
                    <NextTime>{nextTimes[Rating.Easy]}</NextTime>
                </div>
            </ButtonGrid>

            <CardInfo>
    <h3>Card Info</h3>
    <Section>
        <SectionTitle>Card Details</SectionTitle>
        {flashcard.card
            ? Object.entries(flashcard.card).map(([key, value], index) => (
                <Entry key={`card-${index}`}>
                    <strong>{key}:</strong> {value?.toString() || 'N/A'}
                </Entry>
              ))
            : <Entry>Card details not available</Entry>}
    </Section>


    <Button className="reset" onClick={handleReset}>
                Reset
            </Button>

    
    <Section>
    <SectionTitle>Review Log</SectionTitle>
    {flashcard.log && flashcard.log.length > 0
        ? flashcard.log.slice().reverse().map((log, index) => (
            <LogEntry key={`log-${index}`}>
                <LogIndex>#{flashcard.log.length - index}</LogIndex>
                {Object.entries(log).map(([key, value], subIndex) => (
                    <Entry key={`log-${index}-${subIndex}`}>
                        <strong>{key}:</strong> {value?.toString() || 'N/A'}
                    </Entry>
                ))}
            </LogEntry>
          ))
        : <Entry>No review logs available</Entry>}
</Section>



</CardInfo>


            {/* <History>
                <h3>History</h3>
                {history.map((entry, index) => (
                    <p key={index}>{entry}</p>
                ))}
            </History> */}
        </Container>
    );
}