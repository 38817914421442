import React from 'react';
import styled from 'styled-components';
import ForwardIcon from './../assets/tree/forward.png';
import { formatNumber } from '../utils/Utils';

const FloatingContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const StartFloatButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 7em;
  height: 90%;
  padding: 0.8em;
  border-radius: 0.8em;
  border: none;

  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  
  background: ${(props) => (props.disabled ? 'gray' : 'linear-gradient(135deg, #005fbb 0%, #4ea1f5 100%)')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  box-shadow: ${(props) => (props.disabled ? 'none' : '0 4px 12px rgba(0, 95, 187, 0.3)')};

  &:hover {
    background: ${(props) => (props.disabled ? 'gray' : 'linear-gradient(135deg, #0075d9 0%, #60b3ff 100%)')};
    transform: ${(props) => (props.disabled ? 'none' : 'translateY(-2px)')};
    box-shadow: ${(props) => (props.disabled ? 'none' : '0 8px 16px rgba(0, 95, 187, 0.3)')};
  }

  &:active {
    transform: ${(props) => (props.disabled ? 'none' : 'scale(0.95)')};
  }


  @media (min-width: 600px) {
    // está colabado na esquerda
    margin-left: 2em;
  }
`;

const Txt = styled.p`
  margin: 0;
  padding: 0;
  color: white;
  font-size: ${(props) => (props.disabled ? '0.7em' : '0.8em')};
  font-weight: 600;
  text-align: center;
  flex: 1;
`;

const Num = styled.span`
  font-size: 0.9em;
  font-weight: bold;
`;

const BttnImg = styled.img`
  width: 1em;
  height: 1em;
  opacity: ${(props) => (props.disabled ? '0' : '0.95')};
  margin-left: 0.5em;
  visibility: ${(props) => (props.disabled ? 'hidden' : 'visible')};
`;

export default function StartBttnFloat({
  testType,
  nTests,
  hasSelections,
  onStart
}) {
  const canStart = hasSelections && nTests > 0;
  const buttonText = canStart ? (
    <>
      <Num>{formatNumber(nTests)}</Num> {testType === 'Residencia' ? 'questões' : 'cards'}
    </>
  ) : (
    <>(selecione tema ou instituição)</>
  );

  return (
    <FloatingContainer>
      <StartFloatButton onClick={canStart ? onStart : undefined} disabled={!canStart}>
        <Txt disabled={!canStart}>{buttonText}</Txt>
        {!canStart ? null : <BttnImg src={ForwardIcon} alt="Iniciar" disabled={!canStart} />}
      </StartFloatButton>
    </FloatingContainer>
  );
}
