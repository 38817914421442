import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import NextIcon from './../assets/right.png'
import DownIcon from './../assets/down.png'

import NextIconDark from './../assets/right dark.png'
import DownIconDark from './../assets/down dark.png'

import NextIconUnavailable from './../assets/tileGrid_notAvailable.png'
import { backgroundWhiteBlack } from '../tests/FlashcardsStyles'
import { useSelector } from 'react-redux'


const GridContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-self: center;
    padding: 1em 0 0 0;

    @media (max-width: 768px) {
        padding: 0.5em 0 0 0;
        border-radius: 0.5em;
    }
`


const TileContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    ${props => props.clicked ? `
        border: none;
    `: `

        border-bottom: 0.5px solid gray;    
    `}
`

const Tile = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;


    padding: 0.6em;
    
    
    ${props => props.available ? `
        cursor: pointer;
    `:`
        cursor: not-allowed;
    `
    }

    // Fica menos quadrado-escroto no hover
    border-radius: 0.25em;

    ${props => props.theme.darkMode ? `
        // background-color: rgb(87, 87, 87);

        ${props.available && css`
            &:hover {
                background-color: rgb(120, 120, 120);
            }       
        `}
    `:`
        background-color: white;

        ${props.available && css`
            &:hover {
                background-color: #e0e0e0;
            }
        `}
    `}

    
    @media (max-width: 768px) {
        padding: 0.5em;
        min-height: 2.5em; // Garante altura mínima consistente
    }
`

const TileContent = styled.div`
    display: flex;
    align-items: center;
    
    @media (max-width: 768px) {
        gap: 0.3em; // Espaçamento consistente entre elementos
    }
`

const TileText = styled.p`
    margin: 0 0 0 1em;  
    font-weight: ${props => props.available ? 'bold' : 'normal'};

    color: ${props => {
        if (props.available) {
            return props.theme.darkMode ? 'rgb(240, 240, 240)' : 'black'; // Cor dependendo do modo escuro ou claro
        } else {
            return props.theme.darkMode ? '#8f8f8f' : '#666'; // Cor dependendo do modo escuro ou claro
        }
    }};

    @media (max-width: 768px) {
        margin: 0 0 0 0.5em;
        font-size: 0.9em;
        padding-top: 0.15em; // Alinha com os ícones
    }
`


const TileWarning = styled.p`
    margin: 0 1em 0 0;
    font-size: 0.9em;

    @media (max-width: 768px) {
        margin: 0 0.5em 0 0;
        padding-top: 0.15em; // Alinha com os outros elementos
    }

    p {
        ${props => props.theme.darkMode ? `
    color: rgba(255, 71, 87, 0.8);
    ` : `
    color: ;

    `};

    }
`

const TileArrow = styled.img`
    height: 0.8em;

    @media (max-width: 768px) {
        padding-top: 0.15em; // Alinha com o texto
    }
`

const TileIcon = styled.img`
    height: 1.2em;

    @media (max-width: 768px) {
        height: 1em;
        padding-top: 0.15em; // Alinha com o texto
    }
`

const JSXContainer = styled.div`
    max-height: ${props => props.expanded ? '9000px' : '0px'};
    overflow: hidden;

    transition: max-height 0.4s ease-in-out, padding 0.4s ease-in-out; 

    padding: ${props => props.expanded ? '0.5em 1em 1em 1em' : '0em'}; // Opcional: ajusta o padding com base no estado

    text-align: justify;

    display: flex;
    flex-direction: column;

    ${props => props.theme.darkMode ? `
        color: rgb(230, 230, 230)
    `:`
        color: black;
    `}
`

const TileSubtitle = styled.p`
    margin: 0;
    color: gray;
    font-weibht: normal;
    font-size: 0.8em;


    ${props => props.theme.darkMode ? `
        color: rgb(200, 200, 200);
    `:`
        color: gray;
    `}

    @media (max-width: 768px) {
        padding-top: 0.15em; // Alinha com os outros elementos
        font-size: 0.75em;
    }
`




export function prepareDataForTileData(idsByTagPath, func, subtitleFunc) {
    function defaultSubtitle(idsByTagPath, tagpath) {
        let testsIDs = idsByTagPath[tagpath]
        return (<>
                {testsIDs.length}
                {testsIDs.length > 1 ? ' testes' : ' teste'}
        </>)
    }

    if (!subtitleFunc) subtitleFunc = defaultSubtitle

    let data = Object.keys(idsByTagPath).map(tagpath => {
        const lastTag = tagpath.split("/").slice(-1)[0];

        return {
            title : lastTag,
            subtitle: subtitleFunc(idsByTagPath, tagpath),
            nTests: idsByTagPath[tagpath].length,
            available: true,
            action : () => func(idsByTagPath, tagpath)
        }
    })

    return data
}




export default function TileGrid(props) {


    const darkMode = useSelector(state => state.theme.darkModeOn);

    const [activeIndex, setActiveIndex] = useState(null)

    useEffect(() => {
        if (props.defaultExpanded !== undefined) {
            setActiveIndex(props.defaultExpanded)
        }
    }, [props.defaultExpanded])

    function action(index, option) {
        if (option.available) {
            if (option.action) {
                option.action()
            }
            else if (option.JSX) {
                setActiveIndex(prevIndex => prevIndex === index ? null : index)
            }
        }
    }


    function getIconSrc(option, index, activeIndex) {
        if (!option.available) {
            return NextIconUnavailable;
        } else if (activeIndex === index) {
            return darkMode ? DownIconDark : DownIcon;
        } else {
            return darkMode ? NextIconDark : NextIcon;
        }
    }
        
    return (
        <GridContainer style = {props.style}>
            {props.options.map((option, index) => (
                
                <TileContainer>
                    <Tile key={index} available={option.available} clicked = {activeIndex === index} onClick={() => action(index, option)}>
                        <TileContent>
                            <TileIcon src = {option.icon} />
                            <TileText available={option.available}>{option.title}</TileText>
                        </TileContent>

                        <TileContent>
                            <TileSubtitle>{option.subtitle}</TileSubtitle>
                            <TileWarning>{option.warning ? `🚨` : ``}</TileWarning>
                            <TileArrow src = { getIconSrc(option, index, activeIndex) } />
                        </TileContent>
                    </Tile>
                    
                    {/* O div precisa existir para permitir a transição, mas só criamos
                    o conteúdo quando está expanded para não ter overflow ingrato */}
                    {option.JSX && 
                        <JSXContainer expanded = {activeIndex === index}>
                            {activeIndex === index && 
                                option.JSX
                            }
                        </JSXContainer>
                    }

                </TileContainer>
            ))}
        </GridContainer>
    )
}
