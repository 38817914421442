// Outubro 2024
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import ExamsController from '../controllers/ExamsController'
import Checkbox from './Checkbox'
import { ColumnCSS, RowCSS } from '../components/BasicComponents'


export const Container = styled.div`
    ${ColumnCSS}
    align-items: flex-start;
    min-height: 100%;
    width: 100%;

    padding-top: 1em;

    @media (max-width: 768px) {
        padding-top: 0.5em;
    }
`

const StateTitle = styled.p`
    // Para limitar a width
    display: inline-block;
    padding: 0.3em;


    border-radius: 0.5em;

    font-size: 1.1em;
    font-weight: bold;

    margin: 0 0 -0.25em 0;
    ${props => props.searched && `
        margin: 0 0 0.5em 0;
        background-color: rgb(39, 120, 196, 0.3);    
    `}

    color: ${props => props.theme.darkMode ? 'rgb(220, 220, 220)' : '#1d1d1f'};

    @media (max-width: 768px) {
        font-size: 0.95em;
        padding: 0.25em 0.5em;
        margin: 0 0 0.5em 0.5em;
    }
`

const Institutions = styled.div`
       display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 0.5em 8%;
    padding-left: 2em;
    margin-bottom: 2em;
    width: 100%;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 0.3em;
        padding-left: 0.5em;
        padding-right: 0.5em;
        margin-bottom: 1em;
    }
`

const Institution = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5em 0.5em 0.5em 0.5em;
    cursor: pointer;
    border-radius: 0.5em;

    ${props => props.searched && `
        background-color: rgb(39, 120, 196, 0.3);    
    `}

    @media (max-width: 768px) {
        padding: 0.5em;
        margin: 0 0.5em;
        
        &:active {
            background-color: rgba(0, 0, 0, 0.05);
        }
    }
`

const TitleContainer = styled.div`
    // width: 80%;
    flex: 1;

    padding: 0 0 0 0.5em;

    // background-color: yellow;
    ${RowCSS}

    @media (max-width: 768px) {
        padding: 0 0 0 0.3em;
    }
`

const Title = styled.p`
    margin: 0;
    padding: 0;
    font-size: 1em;
    font-weight: bold;

    color: ${props => props.theme.darkMode
        ? props.searched
            ? '#ff6b6b' 
            : 'rgb(220, 220, 220)' 
        : props.searched
            ? '#ff4d4d' 
            : 'inherit' 
    };

    @media (max-width: 768px) {
        font-size: 0.75em;
    }
`


const Empty = styled.div`
    ${ColumnCSS}
    align-self: center;
    padding: 3em;
    background-color: #f5f5f7;
    border-radius: 1em;
    min-height: 16em;

    margin-top: 2em;
    width: 75%;
    align-self: center;

    @media (max-width: 800px) {
        width: 90%;
        padding: 2em;
        min-height: auto;
        margin-top: 1em;
    }
`

const Main = styled.div`
    font-size: 1.6em;
    font-weight: 600;
    color: #1d1d1f;
    margin-bottom: 0.8em;
    text-align: center;

    @media (max-width: 768px) {
        font-size: 1.2em;
        margin-bottom: 0.6em;
    }
`

const Sub = styled.div`
    font-size: 1.1em;
    color: #6e6e73;
    line-height: 1.4;
    max-width: 30em;
    text-align: left;
    
    & + & {
        margin-top: 0.5em;
    }

    @media (max-width: 768px) {
        font-size: 0.9em;
        line-height: 1.3;
    }
`


export default function InstitutionsCard({ searchedString, updateChosenInstitutions, previouslyChecked }) {
    const [preparedData, setPreparedData] = useState(false)
    const searchedRef = useRef()
    const checkedRef = useRef()

    useEffect(() => {
        if (searchedRef.current !== searchedString || checkedRef.current !== previouslyChecked) {
            console.log(' * InstitutionsCard: atualizando, pois algum props mudou...')

            const grouped = groupInstitutions()
            const data = processData(grouped, searchedString, previouslyChecked)
            searchedRef.current = searchedString
            checkedRef.current = previouslyChecked
            setPreparedData(data)
        }
    }, [searchedString, previouslyChecked])



    function groupInstitutions() {
        const exams = ExamsController.examsMetadata
        const grouped = Object.entries(exams).reduce((acc, [institution, info]) => {
            const state = info.stateFull
            if (!acc[state]) {
                acc[state] = []
            }
            acc[state].push({
                name: institution,
                id: info.id
            })
            return acc
        }, {})
    
        // Ordenar instituições dentro de cada estado
        for (let state in grouped) {
            grouped[state].sort((a, b) => a.name.localeCompare(b.name))
        }
    
        // Criar um novo objeto com os estados ordenados
        const orderedGrouped = Object.keys(grouped)
            .sort()
            .reduce((obj, key) => {
                obj[key] = grouped[key]
                return obj
            }, {})
    
        return orderedGrouped
    }


    function processData(data, searchedString, previouslyChecked) {
        const processedData = {}
        const lowercasedSearch = searchedString ? searchedString.toLowerCase() : ''
    
        for (const [state, institutions] of Object.entries(data)) {
            const stateSearched = lowercasedSearch && state.toLowerCase().includes(lowercasedSearch)
            
            const institutionsVisible = institutions
                .map(inst => {
                    const searched = lowercasedSearch && inst.name.toLowerCase().includes(lowercasedSearch)
                    const visible = !lowercasedSearch || stateSearched || searched
    
                    return {
                        ...inst,
                        searched: searched,
                        visible: visible,
                        checked: previouslyChecked.includes(inst.name)
                    }
                })
                .filter(inst => inst.visible)
            
            if (institutionsVisible.length > 0) {
                processedData[state] = {
                    stateSearched,
                    institutions: institutionsVisible
                }
            }
        }
        
        return processedData
    }


    function selectInstitution(institutionName) {
        const newData = { ...preparedData }

        for (const state in newData) {
            const institutions = newData[state].institutions
            const index = institutions.findIndex(inst => inst.name === institutionName)
            if (index !== -1) {
                console.log(institutions[index])
                
                institutions[index] = {
                    ...institutions[index],
                    checked: !institutions[index].checked
                }

                break
            }
        }

        let checkedInstitutions = []
        for (const state in newData) {
            const institutions = newData[state].institutions
            const checked = institutions.filter(inst => inst.checked).map(inst => inst.name)
            checkedInstitutions = checkedInstitutions.concat(checked)
        }

        setPreparedData(newData)
        updateChosenInstitutions(checkedInstitutions)
        checkedRef.current = checkedInstitutions
    }

    return (
        <Container>
            {preparedData && Object.entries(preparedData).map(([state, data]) => {
                return (
                    <>

                    <StateTitle searched={data.stateSearched}>{state}</StateTitle>
                        <Institutions>
                            {data.institutions.map(inst => {

                                return (
                                    <Institution onClick={() => selectInstitution(inst.name)} searched={inst.searched} key = {inst.name}>
                                        <Checkbox
                                            checked={inst.checked}
                                            mildlyChecked={false} />

                                        <TitleContainer>
                                            <Title>{inst.name}</Title>
                                        </TitleContainer>
                                    </Institution>
                                )
                            })}
                        </Institutions>
                        </>

                )
            })}
            {!preparedData || Object.keys(preparedData).length === 0 && (
                <Empty>
                    <Main>Não localizamos essa instituição.</Main>
                    <Sub>Você digitou direitinho?</Sub>
                    
                    <Sub>Se é uma instituição importante que está faltando, peça por DM!</Sub>
                </Empty>
            )}
        </Container>
    )
}