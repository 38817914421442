import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import Session from '../controllers/Session'

import StopwatchIcon from './../assets/stopwatch.png'
import PauseIconSrc from './../assets/pause.png'
import { RowCSS } from '../components/BasicComponents'

const Container = styled(motion.div)`
    display: flex;
    align-items: center;
`

const IconButton = styled(motion.button)`
    background: none;
    border: none;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 6px
`

const Icon = styled.img`
    height: 1.2em;
`

const TimeWrapper = styled(motion.div)`
    // Afasta um cronômetro do outro.
    ${RowCSS}
    gap: 0.4em;
`

const SessionTime = styled.p`
    margin: 0;
    color: ${props => props.theme.darkMode ? 'rgba(255, 255, 255, 0.9)' : 'rgba(0, 0, 0, 0.9)'}
`

const TestTime = styled.p`
    margin: 0;
    color: ${props => props.theme.darkMode ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)'}
`

export default function Stopwatch() {
    const [visible, setVisible] = useState(true)
    const [sessionTime, setSessionTime] = useState(0)
    const [testTime, setTestTime] = useState(0)
    const [isPaused, setIsPaused] = useState(false)

    useEffect(() => {
        const interval = setInterval(() => {
            setSessionTime(Session.sessionStopwatch.getElapsedTime())
            setTestTime(Session.testStopwatch.getElapsedTime())
            setIsPaused(!Session.sessionStopwatch.isRunning || !Session.testStopwatch.isRunning)
        }, 100)

        return () => clearInterval(interval)
    }, [])

    function formatTime(time) {
        const minutes = Math.floor(time / 60)
        const seconds = time % 60
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
    }

    return (
        <Container>
            <IconButton
                onClick={() => setVisible(!visible)}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.90 }}
            >
                <Icon src={isPaused ? PauseIconSrc : StopwatchIcon} />
            </IconButton>

            <AnimatePresence mode="wait">
                {visible && (
                    <TimeWrapper
                        initial={{ opacity: 0, x: 10 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: 10 }}
                        transition={{ 
                            duration: 0.15,
                            ease: "easeInOut"
                        }}
                    >
                        <SessionTime>
                            {formatTime(sessionTime)}
                        </SessionTime>
                        <TestTime>
                            ({testTime}s)
                        </TestTime>
                    </TimeWrapper>
                )}
            </AnimatePresence>
        </Container>
    )
}