import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {
    Tag,
} from 'lucide-react'



import ResidenciaIcon from './../assets/residencia.png'
import FlashcardIcon from './../assets/flashcard.png'
import { Column, ColumnCSS, RowCSS } from '../components/BasicComponents'
import LastSessionController from '../controllers/LastSessionController'
import { STUDY_MODES, TEST_TYPES } from '../controllers/SessionConfig'

import CalendarIcon from './../assets/calendar.png'
import LabelIcon from './../assets/label.png'
import NextIcon from './../assets/next_white.png'
import TagIcon from './../assets/tag_blue.png'
import TrashIcon from './../assets/trash.png'
import StarBlack from './../assets/star_black.png'
import StarColor from './../assets/star_color.png'

import ControlIcon from './../assets/control_copy.png'
import OwlIcon from './../assets/owlMarginless.png'
import SandboxIcon from './../assets/sandbox.png'
import BooksIcon from './../assets/books.png'
import { formatNumber, sleep } from '../utils/Utils'
import { startSavedSession, useSessionStarter } from '../utils/StartSessionMethods'
import { get } from 'react-scroll/modules/mixins/scroller'


import RightBlueLight from './../assets/right_blue_light.png'
import RightBlueDark from './../assets/right_blue_dark.png'
import GeneralDialog from '../tests/GeneralDialog'
import OslerButton from '../components/OslerButton'


const Session = styled.div`
    ${RowCSS}
    width: 100%;

    // background: gray;
`


const SessionCard = styled.div`
    ${ColumnCSS}
    width: 100%;
    align-items: flex-start;

    background: white;
    border: 1px solid #e2e8f0;

    border-radius: 1em;
    padding: 1.5em;

    transition: all 0.3s ease;
    position: relative;
    &:hover {
        transform: translateX(8px);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        border-color: #90cdf4;
    }

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 10px;
        bottom: 10px;
        width: 2px;
        background: #4299e1;

            border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    }


    // background: lightgray;
    // background: #f5f5f5;
`

const SessionRow = styled.div`
    ${RowCSS}
    height: 100%;
    margin-bottom: 12px;
    gap: 16px;
`

const SessionLeft = styled.div`
    ${ColumnCSS}
    align-items: flex-start;
    width: 85%;

    // background: green;
`

const SessionRight = styled.div`
    ${RowCSS}
    height: 100%;
    width: 100%;

    padding-right: 2em;

    margin-top: 1em;

    // background: cyan;
`

const TopRow = styled.div`
    ${RowCSS}
    justify-content: space-between;
    width: 100%;

    // background-color: yellow;
`

const ActionsRow = styled.div`
    ${RowCSS}

    gap: 0.5em;
    // background: red;
`


const Bttn = styled.div`
    ${ColumnCSS}

    width: 2em;
    cursor: pointer;

    border-radius: 0.25em;
    padding: 0.5em 1em 0.5em 1em;
    background-color: white;

    transform: 0.3s;
    &:hover {
        background-color: #f5f5f5;
    }
`



const ClearBttn = styled(Bttn)`
`

const FavBttn = styled(Bttn)`
`

const StartBttn = styled.div`

`



const TitleRow = styled.div`
    ${RowCSS}
    gap: 12px;
    margin-bottom: 4px;


    // background: yellow;
`

const TitleIcon = styled.img`
    height: 2em;
    // background-color: red;
`

const Title = styled.p`
    font-weight: bold;
    color: black;

    // background-color: 
    

    font-size: 1.25rem;
    line-height: 1;
    margin: 0;
    padding: 0;
`



const SessionContent = styled.div`
    ${RowCSS}
    width: 100%;
    margin-top: 1em;
`

const SessionMeta = styled.div`
    ${RowCSS}
    align-items: flex-start;
    gap: 0.75em;

    padding-left: 0em;
    width: 100%;

    // background: cyan;
`

const SessionMetaItem = styled.div`
    ${RowCSS}
    gap: 6px;

    padding: 0.25em 0.25em 0.25em 0.25em;
`


const ClickableSessionMetaItem = styled(SessionMetaItem)`
    cursor: pointer;

    border-radius: 0.25em;
    background-color: white;

    transform: 0.3s;
    &:hover {
        background-color: #f5f5f5;
    }
`


const MetaIcon = styled.img`
    height: 1.2em;
`

const MetaLabel = styled.p`
    font-size: 0.85rem;
    color: gray;
    font-weight: bold;
    margin: 0;
    padding: 0;
`



const SessionTags = styled.div`
    ${RowCSS}

    width: 100%;
    justify-content: center;

    padding: 1em;
    // border: 1px solid gray;
    // border-radius: 1em;
    // background: yellow;

    gap: 4px;
    flex-wrap: wrap;
`

const SessionTag = styled.span`
    font-size: 0.7rem;
    padding: 4px 12px;
    background: #ebf8ff;
    color: $#3182ce;
    border-radius: 20px;
    display: flex;
    align-items: center;
    gap: 4px;
`




const Right = styled.div`
    ${RowCSS}
    height: 100%;
    // background: yellow;
`


const SessionProgress = styled.div`
    width: 5em;
    height: 5em;
    border-radius: 50%;
    background: #f7fafc;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px solid ${props => props.completed > 75 ? '#48bb78' : 
                                props.completed > 50 ? '#4299e1' : 
                                props.completed > 25 ? '#ed8936' : '#e53e3e'};
`


const SessionDetails = styled.div`
    ${ColumnCSS}
    align-items: flex-start;
    margin-left: 0.6em;
`


const ProgressValue = styled.div`
    font-size: 1.2rem;
    font-weight: 600;
    color: #2d3748;
`

const ProgressLabel = styled.div`
    font-size: 0.7rem;
    color: #718096;
`


const Detail = styled.p`
    font-size: 0.9rem;
    color: gray;
    margin: 0;
    padding: 0;
    width: 100%;
`



const IconContainer = styled.div`
    ${ColumnCSS}
`

const Icon = styled.img`
    height: 1.6em;
    // background: red;
`



const SessionBttns = styled.div`
    ${ColumnCSS}

    width: 5%;
    // background: yellow;

    gap: 1em;
`





const BttnIcon = styled.img`
    height: 1.25em;
`



export default function LastSessionCard({ sessionID, session, toggleFavorite, deleteSession }) {
    const { startSavedSession } = useSessionStarter()
    const [showThemes, setShowThemes] = useState(false)
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)


    function getTestModeIcon(mode) {
        switch (mode) {
            case STUDY_MODES.TEST_MODE:
                return OwlIcon
            case STUDY_MODES.CONSULT_MODE:
                return BooksIcon
            case STUDY_MODES.PLAYGROUND_MODE:
                return SandboxIcon
            default:
                return OwlIcon
        }
    }


    function getTopTags(session) {
        const T = session.tags ?? []

        const topTags = T.slice(0, 10)

        if (T.length > 10) {
            topTags.push('(e outros temas)')
        }

        return topTags
    }


    function loadSession() {
        const lastSession = LastSessionController.getLastSession(sessionID)

        console.log(sessionID)
        console.log(lastSession)

        startSavedSession(lastSession)
    }


    function getRightElement(session) {

        if (session.mode === STUDY_MODES.TEST_MODE) {
            const answered = session.session.filter(test => test.answered).length
            const completed = (answered / session.session.length) * 100
            const percentage = Math.ceil(completed)

            const isCard = session.testType === TEST_TYPES.FLASHCARDS

            return (
                <>
                    <IconContainer>
                        <Icon src = {getTestModeIcon(session.mode)} />
                    </IconContainer>
                    
                    {/* <SessionProgress completed={percentage}>
                        <ProgressValue>{percentage}%</ProgressValue>
                        <ProgressLabel>Concluído</ProgressLabel>
                    </SessionProgress> */}

                    <SessionDetails>
                        {/* <Detail>Modo teste</Detail> */}
                        <Detail>{percentage}% concluído</Detail>
                        <Detail>{formatNumber(session.currentIndex)} de {formatNumber(session.session.length)} { isCard ? 'cards' : 'questões'}</Detail>
                    </SessionDetails>

                </>
            )
        }
        else {

            return (
                <IconContainer>
                    <Icon src = {getTestModeIcon(session.mode)} />
                </IconContainer>
            )
        }
    }
    

    function timestampToReadable(timestamp) {
        const date = timestamp.toDate ? timestamp.toDate() : timestamp
    
        const day = date.getDate()
        const hours = date.getHours().toString().padStart(2, '0')
        const minutes = date.getMinutes().toString().padStart(2, '0')
        
        const months = [
            'jan', 'fev', 'mar', 'abr', 'mai', 'jun',
            'jul', 'ago', 'set', 'out', 'nov', 'dez'
        ]
        
        const month = months[date.getMonth()]
        
        return `${hours}:${minutes} de ${day}/${month}`
    }


    function willDeleteSession() {
        setShowDeleteDialog(false)
        deleteSession(sessionID)
    }

    return (
        <> 
        <GeneralDialog
            open = {showDeleteDialog}
            icon = {TrashIcon}
            title = 'Apagar sessão'
            onClose = {() => setShowDeleteDialog(false)}
            actions={[
                { label: 'Voltar', onClick: () => setShowDeleteDialog(false), style: 'neutral'},
                { label: 'Apagar', onClick: () => willDeleteSession(sessionID), style: 'destructive'},
            ]} >

            <p>Você irá <u>apagar</u> o acesso rápido a essa sessão.</p>
            
            <p>Isso <u>não</u> modifica suas estatísticas, é só um atalho para voltar de onde estava.</p>

            </GeneralDialog>


        <Session>
            <SessionCard key={sessionID}>
                    {/* <SessionLeft> */}
                        <TopRow>
                            <TitleRow>
                                <TitleIcon src = {session.testType === TEST_TYPES.FLASHCARDS ? FlashcardIcon : ResidenciaIcon} alt={session.type} />
                                <Title>{session.mainTheme}</Title>
                            </TitleRow>

                            <ActionsRow>
                                <ClearBttn onClick = {() => setShowDeleteDialog(true)}>
                                    <BttnIcon src = {TrashIcon} />
                                </ClearBttn>

                                <FavBttn onClick = {() => toggleFavorite(sessionID) }>
                                    <BttnIcon src = {session.favorite ? StarColor : StarBlack} />
                                </FavBttn>

                            </ActionsRow>
                        </TopRow>


                        {/* <SessionContent> */}
                            <SessionMeta>
                                <SessionMetaItem>
                                    <MetaIcon src={CalendarIcon} />
                                    <MetaLabel>{timestampToReadable(session.dateSaved)} </MetaLabel>
                                </SessionMetaItem>


                                <SessionMetaItem>
                                    <MetaIcon src={ControlIcon} />
                                    <MetaLabel> {session.descriptor} </MetaLabel>
                                </SessionMetaItem>


                                <ClickableSessionMetaItem onClick = {() => setShowThemes(!showThemes)}>
                                    <MetaIcon src = { TagIcon } />
                                    <MetaLabel> { session.tags.length} temas </MetaLabel>
                                </ClickableSessionMetaItem>


                                </SessionMeta>

                                { showThemes && 
                                <SessionTags>
                                    { getTopTags(session).map((tag, index) => (
                                        <SessionTag key={index}>
                                            <Tag size={12} />
                                            {tag}
                                        </SessionTag>
                                    ))}
                                </SessionTags>                        
                                }

                        {/* </SessionContent> */}

                    {/* </SessionLeft> */}
                    

                    {/* <StartBttn
                        onClick = {() => loadSession(sessionID)}
                        onMouseEnter={() => setStartIsHovered(true)}
                        onMouseLeave={() => setStartIsHovered(false)} >
                        <BttnIcon src = {startIsHovered ? RightBlueDark : RightBlueLight} />
                    </StartBttn>                 */}


                <TopRow>

                    <SessionRight>
                        { getRightElement(session) }
                    </SessionRight>



                    <OslerButton
                        style = {{alignSelf: 'flex-end'}}
                        img   = {NextIcon}
                        color = 'blue'
                        iconRight = {true}
                        text = 'Retomar' 
                        onClick={loadSession}/>


                </TopRow>

            </SessionCard>

        </Session>

        </>
    )
}