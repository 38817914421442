import React from 'react'
import OslerCard from './../components/OslerCard'           // Certifique-se que a importação do OslerCard esteja correta
import FlatCard from './../components/FlatCard'             // Importando o FlatCard
import { useWindowWidth } from './../components/useWindowWidth'  // Importando o hook para monitorar a largura da tela
import styled from 'styled-components'




const TreeCard = styled(OslerCard)`
    width: 75%;

    @media (max-width: 1100px) {
        width: 100%;
        margin: 1em 0.25em auto 0.25em;

        // O default para essa width, em Oslercard,
        // é 1em
        padding: 1em 0.75em 1em 0.75em;
    }

`


export default function TreeContainer({ children }, ref) {
    const screenWidth = useWindowWidth()

    let isMobile = screenWidth < 850

    isMobile = false 

    const WrapperComponent = isMobile ? FlatCard : TreeCard


    return (
        <WrapperComponent ref={ref}> 
            {children}
        </WrapperComponent>
    )
}



// Sem isso daqui, quando atribuímos uma ref a um componente OslerCard ela não é 
// passada, ficando como nula.
TreeContainer = React.forwardRef(TreeContainer)