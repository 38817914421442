import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { db } from '../firebase/firebase-setup';
import { htmlToReact } from '../utils/HtmlToReact';
import CloseIcon from './../assets/x-icon.png';
import { useSelector } from 'react-redux';
import OslerButton from '../components/OslerButton';
import { Column, ColumnCSS, RowCSS } from '../components/BasicComponents';


import NoticeEmoji from './../assets/message.png'

import { 
    Book, 
    BookOpen, 
    Bell, 
    Coffee, 
    ArrowRight, 
    Calendar, 
    Award, 
    Brain, 
    TrendingUp, 
    Target, 
    Clock,
    ChevronRight,
    Star,
    Timer,
    Tag,
    User,
    BarChart2,
    Check
} from 'lucide-react'
import TitleCard from '../custom/TitleCard';





const NoticeContainer = styled.div`
    border-radius: 1em;
    margin-top: 5em;
    margin-left: 5em;
    width: 70%;
    padding: 2em;

    border: 1px solid lightslategrey;
    
    ${props => props.theme.darkMode ? `
        color: white;
        background-color: rgb(90, 90, 90);
    `: `
        color: black;
        background-color: white;

    ` }

    @media (max-width: 900px) {
        margin: 5em 0 0 0;
        width: 100%;
    }


    @media (max-width: 500px) {
        align-self: center;
        margin-left: 0;
        margin-top: 2em;
        width: 90%;
    }
`;

const NoticeHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 500px) {
        flex-direction: column-reverse;
    }

    margin-bottom: 1em;
`;

const NoticeTitle = styled.div`
    font-weight: bold;
    font-family: Lora;
    font-size: 1.4em;

    & > p {
        margin: 0;
        padding: 0;
    }
`

const Bttns = styled.div`
    ${RowCSS}

    @media (max-width: 900px) {
        width: 100%;
        margin-top: 2em;
        justify-content: flex-end;
    }
`




const SectionHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
`

const SectionTitle = styled.h2`
    font-size: 1.5rem;
    font-weight: 600;
    color: #2d3748;
    display: flex;
    align-items: center;
    gap: 12px;
`


// Seções base com design atualizado
const Section = styled.section`
    background: white;
    border-radius: 24px;
    padding: 32px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
    }
`


// Quadro de Avisos com design premium
const NoticeBoard = styled(Section)`
    background: linear-gradient(135deg, #fffaf0 0%, #fff5f5 100%);
    border: 1px solid #feebc8;
    position: relative;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        top: -50%;
        right: -50%;
        width: 200px;
        height: 200px;
        background: radial-gradient(circle, rgba(251, 211, 141, 0.1) 0%, transparent 70%);
        border-radius: 50%;
    }
`

const NoticeList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`

const Notice = styled.div`
    background: white;
    border-radius: 16px;
    padding: 20px;
    border: 1px solid #feebc8;
    transition: all 0.3s ease;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    &:hover {
        transform: translateX(8px);
        box-shadow: 0 4px 12px rgba(251, 211, 141, 0.2);
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 4px;
        height: 100%;
        background: ${props => props.$type === 'achievement' ? '#48bb78' : 
                              props.$type === 'content' ? '#4299e1' : 
                              props.$type === 'event' ? '#ed8936' : '#e53e3e'};
        border-radius: 2px;
    }
`

const NoticeHeader2 = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 12px;
`

const NoticeIcon = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 10px;
    background: ${props => props.$type === 'achievement' ? '#f0fff4' : 
                          props.$type === 'content' ? '#ebf8ff' : 
                          props.$type === 'event' ? '#fffaf0' : '#fff5f5'};
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        color: ${props => props.$type === 'achievement' ? '#48bb78' : 
                         props.$type === 'content' ? '#4299e1' : 
                         props.$type === 'event' ? '#ed8936' : '#e53e3e'};
    }
`

const NoticeInfo = styled.div`
    flex: 1;
`

const NoticeTitle2 = styled.h4`
    font-size: 1.1rem;
    color: #2d3748;
    font-weight: 600;
    margin-bottom: 4px;
`

const NoticeTime = styled.div`
    font-size: 0.85rem;
    color: #718096;
`

const NoticeContent = styled.p`
    color: #4a5568;
    font-size: 0.95rem;
    line-height: 1.6;
`

const NoticeAction = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 12px;
    font-size: 0.9rem;
    color: #4299e1;
    font-weight: 500;
    transition: gap 0.3s ease;

    ${Notice}:hover & {
        gap: 12px;
    }
`

const TextContainer = styled.div`
    ${ColumnCSS}
    align-items: flex-start;
    padding-top: 1em;
`


export default function NoticeCard(props) {

    const user = useSelector(state => state.user.data)

    const [showNotice, setShow] = useState(false)
    const [title, setTitle] = useState("")
    const [message, setMessage] = useState("")
    const [uuid, setUuid] = useState("")


    const [isDesktop, setDesktop] = useState(window.innerWidth > 900);


    useEffect(() => {
        async function load() {
            const lastNoticeDoc = await db.collection('notice').doc('lastNotice').get()
            
            if (lastNoticeDoc.exists) {   
                const lastNotice = lastNoticeDoc.data()   

                const noticeDate = lastNotice.date?.toDate()
                if (noticeDate) {
                    const hoursDiff = (new Date() - noticeDate) / (1000 * 60 * 60)
                    if (hoursDiff > 48) {
                        console.log("NoticeCard: aviso é antigo")
                        return
                    }
                }


                const alreadyReadDoc = await db.doc(`users/${user.id}/personal/readLastNotice`).get()

                if (alreadyReadDoc.exists) {
                    const alreadyRead = alreadyReadDoc.data()

                    console.log(alreadyRead)

                    if (alreadyRead['id'] === lastNotice['id']) {
                        console.log("NoticeCard: já leu o aviso");
                        setShow(false)
                        return
                    }
                }
                setTitle(lastNotice['title'])
                setMessage(htmlToReact(lastNotice['message']))
                setShow(true)
                setUuid(lastNotice['id'])
            } else {
                console.log("NoticeCard: não temos avisos novos")
            }
        }

        console.log("NoticeCard: loading...")
        load()
    }, [])

    
    function bttns() {
        return (
            <Bttns>
                <OslerButton
                    size = 'small'
                    color = 'red'
                    onClick = {async () => {
                        setShow(false)
                        await db.doc(`users/${user.id}/personal/readLastNotice`).set({id: uuid})
                    }}
                    text = 'Não mostrar mais'
                    style = {{marginRight: isDesktop ? '2em' : '1em'}} />

                <OslerButton
                    size = 'small'
                    color = 'gray'
                    onClick = {() => setShow(false)}
                    text = 'Fechar'  />
            </Bttns>
        )
    }

    const notices = [
        {
            id: 1,
            type: 'achievement',
            title: 'Conquista Desbloqueada!',
            content: 'Você completou 30 dias consecutivos de estudo. Sua dedicação está construindo uma base sólida de conhecimento.',
            time: 'Agora mesmo',
            icon: <Award size={20} />,
            action: 'Ver todas as conquistas'
        },
        {
            id: 2,
            type: 'content',
            title: 'Novo Conteúdo Disponível',
            content: 'Adicionamos 200 novos flashcards de Clínica Médica, focados em diagnóstico diferencial.',
            time: '2 horas atrás',
            icon: <BookOpen size={20} />,
            action: 'Começar agora'
        },
        {
            id: 3,
            type: 'event',
            title: 'Webinar: Método Osler',
            content: 'Não perca nossa próxima sessão sobre como maximizar seu aprendizado com o método Osler.',
            time: '5 horas atrás',
            icon: <Target size={20} />,
            action: 'Registrar-se'
        }
    ]


    return (
        <>
        { showNotice && 
            <TitleCard
                style = {{background: 'linear-gradient(135deg, #fffaf0 0%, #fff5f5 100%)', maxWidth: '900px'}}
                // style = {{background: '#fffec7', maxWidth: '900px'}}
                title = {title}
                icon = {NoticeEmoji}
                rightComponent={bttns()}
                >
                    <TextContainer>
                        {message}

                        {/* {bttns()} */}
                    </TextContainer>

            </TitleCard>
        }
        </>
    )
}
