// CollapsibleCard.js
import React from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import ToggleIcon from './../assets/tree/right-chevron2.png'
import { ColumnCSS, RowCSS } from '../components/BasicComponents'
import OslerCard from '../components/OslerCard'



const Card = styled(OslerCard)`
    padding: 2em;

    // background-color: yellow;
`


const Header = styled.div`
    ${RowCSS}
    justify-content: space-between;
    width: 100%;

    // padding-bottom: 1em;
    // background-color: cyan;
`


const Left = styled.div`
    ${RowCSS}
    gap: 1em;
`

const Icon = styled.div`
    ${ColumnCSS}
    height: 100%;

    img {
        // Originalmente, era 1.5em ~ 24px
        width: 1.8em;
    }
`

const Title = styled.p`
    font-size: 1.6rem;
    font-weight: bold;
    margin: 0;

    ${props => props.theme.darkMode ? `
        color: rgb(220, 220, 220);
    `:`
        color: black;
    `}
`

const Content = styled(motion.div)`
    ${ColumnCSS}
    justify-content: center;
    
    // Para ocupar todo o card
    width: 100%;
    height: 100%;

    // background-color: pink;
`





export default function TitleCard({ title, icon, rightComponent, children, style }) {

    return (
        <Card style = {style}>
            <Header>
                <Left>
                    <Icon>
                        <img src={icon}/>
                    </Icon>
                    <Title>{title}</Title>
                </Left>
                
                {rightComponent}
            </Header>

            <Content>
                {children}
            </Content>
        </Card>
    )
}