// Novembro, 2024
// Função usada para obtermos dinamicamente a width da janela
// O applyDebounce é importante porque a alteração do width pode trigger
// rerender que são custosos e lagam o app inteiro

import { useState, useEffect } from 'react'

export function useWindowWidth(applyDebounce = true, debounceDelay = 150) {
    const [width, setWidth] = useState(window.innerWidth)

    useEffect(() => {
        const handleResize = () => {
            console.log(`handleResize called - applyDebounce: ${applyDebounce}`)

            if (applyDebounce) {
                clearTimeout(handleResize.debounceTimer)
                handleResize.debounceTimer = setTimeout(() => {
                    console.log(`Debounced width update: ${window.innerWidth}`)
                    setWidth(window.innerWidth)
                }, debounceDelay)
            } else {
                console.log(`Immediate width update: ${window.innerWidth}`)
                setWidth(window.innerWidth)
            }
        }

        window.addEventListener('resize', handleResize)
        return () => {
            clearTimeout(handleResize.debounceTimer)
            window.removeEventListener('resize', handleResize)
        }
    }, [applyDebounce, debounceDelay])

    return width
}
