import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import CheckIcon from './../assets/tree/check (1).png'
import CheckIconDark from './../assets/tree/check dark.png'


// Para manter o mildly checked alinhado e evitar que o checkbox colapse, algumas
// medidas se mostraram fundamentais: estabelecer min-height e min-width; usar
// rem ao invés de em; e manter o box-sizing como content-box;

const CheckboxContainer = styled.div`
    // É fundamental ter o min-, para ele não colapsar se a width
    // apertar um pouco!
    width: 1.25rem !important;
    height: 1.25rem !important;
    min-width: 1.25rem !important; 
    min-height: 1.25rem !important;
    box-sizing: content-box;

    border: 1.85px solid ${props => props.darkMode ? '#ffffff' : '#86868B'};
    border-radius: 0.25em;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    filter: ${props => props.theme.darkMode ? 'brightness(1.2)' : 'none'};



    ${props => props.theme.darkMode ? `
        border: 1.85px solid ${props.checked ? '#60a5fa' : 'rgba(255, 255, 255, 0.7)'};
        background-color: ${props.checked ? 'rgb(51, 51, 51)' : 'transparent'};
    ` : `
        border: 1.85px solid ${props.checked ? '#1672f6' : '#86868B'};
        background-color: white;
    `}


    &:hover {
        transform: scale(1.1);
        ${props => props.theme.darkMode && `
            border-color: ${props.checked ? '#60a5fa' : 'rgba(255, 255, 255, 0.9)'};
        `}
    }


    @media (max-width: 1100px) {
        width: 1.1rem !important;
        height: 1.1rem !important;
        min-width: 1.1rem !important; 
        min-height: 1.1rem !important;
    }



    // background-color: red !important;
`

const CheckboxImage = styled.img`
    width: 1em;
    height: 1em;

    @media (max-width: 1100px) {
        width: 0.8em;
        height: 0.8em;
    }
`

const MildlyCheckedSquare = styled.div`
    // Equivalente à discussão acima, PRECISA ter para não ficar desformatado nunca
    min-width: 0.8rem !important;
    min-height: 0.8rem !important;
    width: 0.8rem !important;
    height: 0.8rem !important;

    
    border-radius: 0.125em;
    background-color: ${props => props.theme.darkMode ? '#60a5fa' : '#1672f6'};
    opacity: ${props => props.theme.darkMode ? 0.9 : 1};

    @media (max-width: 1100px) {
        min-width: 0.7rem !important;
        min-height: 0.7rem !important;
        width: 0.7rem !important;
        height: 0.7rem !important;
    }




`

export default function Checkbox({ checked, mildlyChecked, action, style }) {
    const darkMode = useSelector(state => state.theme.darkModeOn)

    function getContent() {
        if (checked) {
            return <CheckboxImage src={darkMode ? CheckIconDark : CheckIcon} alt="checked" />
        } else if (mildlyChecked) {
            return <MildlyCheckedSquare />
        }
        return null
    }

    function handleAction(e) {
        if (action) {
            action(e)
        }
    }

    return (
        <CheckboxContainer darkMode={darkMode} onClick={e => handleAction(e)} checked={checked} style = {style}>
            {getContent()}
        </CheckboxContainer>
    )
}