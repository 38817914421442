import React, { useState } from 'react'
import styled from 'styled-components'
import { Languages, Plus } from 'lucide-react'
import Signature from './../assets/signature.png'
import { ColumnCSS } from '../components/BasicComponents'

const QuoteSection = styled.div`
    width: 100%;
    margin-bottom: 48px;
    display: flex;
    flex-direction: column;
    gap: 24px
`

const TopRow = styled.div`
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    align-items: flex-start;
    gap: 0px
`

const Quote = styled.div`
    font-family: 'Fraunces', serif;
    font-size: 2.2rem;
    font-weight: 400;
    line-height: 1.4;
    letter-spacing: -0.01em;
    color: #a01d26;
    flex: 1;

    &::before {
        content: '"';
        margin-right: 0.1em
    }

    &::after {
        content: '"';
        margin-left: 0.1em
    }

    // background: cyan;
`

const Actions = styled.div`
    ${ColumnCSS}
    gap: 8px;
    align-self: center;

    // background: yellow;
    margin-left: 1em;
`

const IconButton = styled.button`
    background: transparent;
    border: 1px solid rgba(160, 29, 38, 0.15);
    color: #a01d26;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    
    &:hover {
        background: rgba(160, 29, 38, 0.05);
        border-color: rgba(160, 29, 38, 0.3)
    }
`

const BottomRow = styled.div`
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    justify-content: flex-end
`

const SignatureWrapper = styled.div`    
    img {
        height: 48px;
        filter: contrast(1.1);
        transition: transform 0.2s ease
    }

    &:hover img {
        transform: scale(1.02)
    }
`

const Discussion = styled.div`
    max-width: 800px;
    margin: 32px auto 0;
    padding: 32px;
    background: rgba(160, 29, 38, 0.04);
    border-radius: 12px;
    font-family: 'Source Serif Pro', Georgia, serif;
    font-size: 1.1rem;
    color: #333;
    line-height: 1.6
`

export default function MainScreenHeader() {
    const oslerQuotes = [
        {
            'short': 'Decida por não perder tempo',
            'en': 'Bury the past and start afresh today with the firm resolve to waste not an hour of the short and precious time which is before you.',
            'pt': 'Enterre o passado e recomece hoje, com a firme determinação de não perder uma única hora do curto e precioso tempo que você dispõe.',
            'explanation': 'Esta citação enfatiza a importância de não se prender ao passado e fazer um uso consciente do tempo presente. Osler frequentemente destacava como o tempo é um recurso precioso e finito na vida de um médico, incentivando seus alunos a manterem o foco no momento presente e nas oportunidades de aprendizado e crescimento que cada dia oferece.',
            'ref': 'Quotable (102)'
        }
    ]

    const [currentQuoteIndex] = useState(() => {
        return Math.floor(Math.random() * oslerQuotes.length)
    })
    
    const [showPortuguese, setShowPortuguese] = useState(false)
    const [showDiscussion, setShowDiscussion] = useState(false)

    const currentQuote = oslerQuotes[currentQuoteIndex]
    const displayedQuote = showPortuguese ? currentQuote.pt : currentQuote.en

    return (
        <QuoteSection>
            <TopRow>
                <Quote>{displayedQuote}</Quote>
                <Actions>
                    <IconButton 
                        onClick={() => setShowPortuguese(!showPortuguese)}
                        title={showPortuguese ? "Show in English" : "Mostrar em Português"}
                    >
                        <Languages size={16} />
                    </IconButton>
                    <IconButton 
                        onClick={() => setShowDiscussion(!showDiscussion)}
                        title="Show discussion"
                    >
                        <Plus size={16} />
                    </IconButton>
                </Actions>
            </TopRow>
            
            <BottomRow>
                <SignatureWrapper>
                    <img src={Signature} alt="William Osler" />
                </SignatureWrapper>
            </BottomRow>
            
            {showDiscussion && (
                <Discussion>
                    {currentQuote.explanation}
                </Discussion>
            )}
        </QuoteSection>
    )
}