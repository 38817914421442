import React, { useEffect, useState, useRef, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { setIsLoading } from '../redux/loadingSlice'
import tree from './Tree'
import { toastMsg } from '../utils/Utils';
import IsLoading from '../main/IsLoading'
import UserReviewsInfo from '../controllers/UserReviewsInfo'
import InstitutionsCard from './InstitutionsCard'
import YearsCard from './YearsCard'
import CustomFiltersCard from './CustomFiltersCard'
import SaveFilterDialog from './SaveFilterDialog'
import OslerData, { KEYS } from '../controllers/OslerData'
import styled, { css } from 'styled-components'
import { backgroundWhiteBlack } from '../tests/FlashcardsStyles.js'
import CreateSessionDialog from './CreateSessionDialog.js'
import { ColumnCSS, RowCSS } from '../components/BasicComponents.js'
import QuestionIcon from './../assets/question-new.png'
import SearchBar from './SearchBar.js'
import OslerButton from '../components/OslerButton.js'
import CustomFiltersController from '../controllers/CustomFiltersController.js'
import CustomStudySideBar from './CustomStudySideBar.js'
import StartBttnLuxury from './StartBttnLuxury.js'
import SessionBuilder from '../controllers/SessionBuilder.js'
import Session from '../controllers/Session.js'
import { StudyModeGeneralDialog } from './CustomStudyHelpDialogs.js'
import { TabItem, TabListGeneric } from '../components/ScreenContainerHeader.js'
import TreeContainer from './TreeContainer.js'
import { useWindowWidth } from '../components/useWindowWidth.js'

import Hospital from './../assets/hospital.png'
import Schedule from './../assets/schedule.png'
import Cedar from './../assets/cedar2.png'
import Custom from './../assets/custom.png'
import CustomStudyPills from './CustomStudyPill.js'
import { TEST_TYPES } from '../controllers/SessionConfig.js'


const BttnsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
    padding: 1em 0em 1em 0em;
    flex-wrap: wrap; /* Para botões quebrarem linha se necessário */

    @media (max-width: 1100px) {
        width: 100%;
        justify-content: center;
    }
`

const TabButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
    background: white;
    border: 1px solid #ddd;
    border-radius: 0.8em;
    padding: 0.5em 1em;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
        background: #f5f5f5;
        transform: scale(1.05);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    }

    &.active {
        background: #e0e0e0;
        border-color: #bbb;
    }

    @media (max-width: 500px) {
        border-radius: 50%;
        padding: 0.8em;
        gap: 0;
    }
        
`;

const TabIcon = styled.img`
    width: 1.8em;
    height: 1.8em;

    @media (max-width: 500px) {
        width: 1.5em;
        height: 1.5em;
    }
`;

const TabName = styled.p`
    font-size: 1em;
    color: #333;
    margin: 0;

    @media (max-width: 500px) {
        display: none; /* Esconde o texto quando a largura for < 500 */
    }
`;



const Container = styled.div`
    ${ColumnCSS}
    align-items: flex-start;
    width: 100%;
    
    ${backgroundWhiteBlack}

    @media (max-width: 1100px) {
        // Com menos de 1100px, os botões da side bar viram floating buttons no bottom
        // e precisamos garantir espaço até o fim do scroll
        //
        // não sabemos a altura correta/determinística, está indo no olhômetro
        ${props => props.sessionAvailable ? 'padding-bottom: 14em;' : 'padding-bottom: 7em;'}
    }
`


const TabContainer = styled(TabListGeneric)`
    width: 100%;
    padding: 0;
    justify-content: flex-start;
    gap: 2em;
    margin-bottom: 2em !important;

    // background-color: pink;
`

const Tab = styled(TabItem)`
    font-weight: normal !important;
`

const ContentWrapper = styled.div`
    ${RowCSS}

    justify-content: space-between;
    width: 100%;    

    // No desktop, fundamental para a <SideBar> começar no topo
    align-items: flex-start;

    // background-color: pink;
`



const ContentArea = styled.div`
    ${ColumnCSS}

    width: 100%;
    min-height: 400px;
`



const Empty = styled.div`
    ${ColumnCSS}
    align-self: center;

    width: 75%;
    padding: 3em;
    margin-top: 2em;

    border-radius: 1em;

    background-color: ${props => props.theme.darkMode ?
        'rgb(51, 51, 51)' :
        '#f5f5f7'};

    @media (max-width: 600px) {
        padding: 1em;
        width: 95%;
    }
`

const Main = styled.div`
    font-size: 1.6em;
    font-weight: 600;
    color: ${props => props.theme.darkMode ?
        'rgb(220, 220, 220)' :
        '#1d1d1f'};
    margin-bottom: 0.8em;
    text-align: center;


    @media (max-width: 600px) {
        font-size: 1.2em;
        margin-bottom: 0.6em;
    }
`

const Sub = styled.div`
    font-size: 1.1em;
    color: ${props => props.theme.darkMode ?
        'rgb(160, 160, 160)' :
        '#6e6e73'};

    text-align: left;
    width: 100%;

    margin-bottom: 1em;

    @media (max-width: 600px) {
        font-size: 0.85em;
        margin-bottom: 1em;
    }
`


export default function CustomStudy({ testType }) {
    const user = useSelector(state => state.user.data)
    const darkMode = useSelector(state => state.theme.darkMode)
    const navigate = useNavigate()
    const dispatch = useDispatch()


const screenWidth = useWindowWidth()

    // Estados base que são necessários para ambos os tipos
    const [loadedInfo, setLoadedInfo] = useState(false)
    const [showCreationDialog, setShowCreationDialog] = useState(false)
    const [themeSearch, setThemeSearch] = useState('')
    const [treeJSX, setTreeJSX] = useState(false)
    const [studyMode, setStudyMode] = useState('Teste')
    const [selectedTagpaths, setSelectedTagpaths] = useState([])
    const [numAvailableTestsForSession, setNumAvailableTestsForSession] = useState(false)
    const [sessionConfig, setSessionConfig] = useState(false)
    const [showHelp, setShowHelp] = useState(false)

    // Estados específicos para Residencia
    const [selectedTab, setSelectedTab] = useState('Temas')
    const [institutionsSearch, setInstitutionsSearch] = useState('')
    const [chosenInstitutions, setChosenInstitutions] = useState([])
    const [chosenYears, setChosenYears] = useState([])
    const [appliedFilters, setAppliedFilters] = useState([])
    const [showSaveFilterDialog, setShowSaveFilterDialog] = useState(false)
    const [tabs, setTabs] = useState([])

    // Refs específicos para Residencia
    const institutionsTabData = useRef()
    const yearsTabData = useRef()
    const customFiltersData = useRef()



    useEffect(() => {
        async function load() {
            console.log('CustomStudy: mounting...')
            if (!UserReviewsInfo.isReady()) {
                console.log("CustomStudy(): exiting to /app because data not ready")
                navigate('/app')
                return
            }


            if (testType === 'Residencia') {
                loadResidenciaData()

                const studyTabs = [
                    {
                        name: 'Temas',
                        icon: Cedar,
                    },
                    {
                        name: 'Instituições',
                        icon: Hospital,
                    },
                    {
                        name: 'Anos',
                        icon: Schedule,
                    },
                    {
                        name: 'Meus filtros',
                        icon: Custom,
                    },
                    {
                        name: 'Ajuda',
                        icon: QuestionIcon,
                        onClick: () => setShowHelp(true)
                    }
                ]
                

                

                setTabs(studyTabs)
            }


            CustomFiltersController.start(user)
            tree.start(testType, onTagpathSelected, triggerTreeRender)
            tree.setScreenWidth(screenWidth)
            setLoadedInfo(true)
        }


        function loadResidenciaData() {
            institutionsTabData.current = OslerData.data[KEYS.RESIDENCIA][KEYS.INSTITUTIONS]['institutions'].sort()
            yearsTabData.current = OslerData.data[KEYS.RESIDENCIA][KEYS.YEARS]['years'].sort()
            const filters = OslerData.data[KEYS.RESIDENCIA][KEYS.USER_FILTERS]
            customFiltersData.current = filters && filters['filters'] ? filters['filters'] : []
        }

        load()
    }, [])


    useEffect(() => {
        tree.setScreenWidth(screenWidth)
    }, [screenWidth])


    useEffect(() => {
        tree.setSearchedTheme(themeSearch)
    }, [themeSearch])

    function changeSelectedTab(newTab) {
        if (selectedTab != newTab) {
            setSelectedTab(newTab)
        }
    }


    function updateChosenInstitutions(newList) {
        setChosenInstitutions(newList)
        setSessionConfig(prev => ({
            ...prev,
            institutions: newList
        }))
    }

    function updateChosenYears(newList) {
        setChosenYears(newList)
        setSessionConfig(prev => ({
            ...prev,
            years: newList
        }))
    }


    function onConfigChange(newSidebarConfig) {
        // Atenção à nuance. Quem chama essa função é a <SideBar>, mas ela não sabe quais as instituições e anos
        // selecionados, pois isso é determinado pelas abas.
        //
        // Alémd isso, flashcards não tem esses dados.
        if (testType === 'Residencia') {
            setSessionConfig({
                ...newSidebarConfig,
                institutions: chosenInstitutions,
                years: chosenYears
            })
        } else {
            setSessionConfig(newSidebarConfig)
        }
    }


    function updateAppliedFilters(newFilter) {
        const isRemovingFilter = appliedFilters.some(f => f.filter_name === newFilter.filter_name)

        let newAppliedFilters;
        let newInstitutions;
        let newYears;

        if (isRemovingFilter) {
            console.log(` * CustomStudy: desmarcando o filtro ${newFilter.filter_name}...`)

            newAppliedFilters = appliedFilters.filter(f => f.filter_name !== newFilter.filter_name)
            newInstitutions = chosenInstitutions.filter(inst => !newFilter.institutions.includes(inst))
            newYears = chosenYears.filter(year => !newFilter.years.includes(year))

        } else {
            console.log(` * CustomStudy: selecionando o filtro ${newFilter.filter_name}...`)

            newAppliedFilters = [...appliedFilters, newFilter]
            newInstitutions = [...new Set([...chosenInstitutions, ...newFilter.institutions])]
            newYears = [...new Set([...chosenYears, ...newFilter.years])]
        }

        setAppliedFilters(newAppliedFilters)
        updateChosenInstitutions(newInstitutions)
        updateChosenYears(newYears)
    }


    function isSelectionNewFilter() {
        // Se não há instituições ou anos selecionados, não é um novo filtro
        if (chosenInstitutions.length === 0 && chosenYears.length === 0) {
            return false
        }

        // Verifica se algum filtro aplicado abrange completamente a seleção atual
        const filters = CustomFiltersController.getFilters()
        for (let filter of filters) {
            const institutionsMatch = chosenInstitutions.every(inst => filter.institutions.includes(inst))
            const yearsMatch = chosenYears.every(year => filter.years.includes(year))

            if (institutionsMatch && yearsMatch) {
                return false // A seleção está completamente abrangida por um filtro existente
            }
        }

        // Se chegou até aqui, a seleção representa um novo filtro
        return true
    }


    function onTagpathSelected() {
        /*
            É tentador iniciar a sessão com
                paths = tree.extractCheckedLeafNodes(),
            mas isso ignora a possibilidade -- e a realidade! -- de questões que
            não estão classificadas em uma "leaf tag", mas sim em uma tag intermediária.
            
            Por outro lado, não precisamos temer sobreposição de tags, porque SessionBuilder
            (provavelmente?) elimina testIDs repetidos.
        */
        const paths = tree.extractCheckedNodes()
        setSelectedTagpaths(paths)
    }


    useEffect(() => {
        if (sessionConfig && selectedTagpaths) {
            const tests = SessionBuilder.simulate({
                testType,
                builder: 'custom',
                selectedTagpaths,
                sessionConfig
             })

            setNumAvailableTestsForSession(tests.length)
        }
    }, [selectedTagpaths, sessionConfig])


    async function saveFilter(filterName) {
        dispatch(setIsLoading(true))

        const newFilter = {
            'filter_name': filterName,
            'institutions': [...chosenInstitutions],
            'years': [...chosenYears]
        }

        const newFilters = await CustomFiltersController.saveFilter(newFilter)
        dispatch(setIsLoading(false))

        if (newFilters) {
            toastMsg(`Seu filtro "${newFilter.filter_name}" foi salvo.\nPara acessá-lo, clique na aba \"Meus filtros\".`)
        } else {
            toastMsg('Oops! Não conseguimos salvar seu filtro. Busque ajuda na DM. 🤔')
        }

        setShowSaveFilterDialog(false)
        setAppliedFilters(prev => [...prev, newFilter])
    }


    function clearSelectedTagpaths() {
        tree.clearSelectedNodes()
        setSelectedTagpaths([])
    }


    // ISSO TUDO PRECISA SER GENERALIZADO, EM NOME DE JESUS, POR FAVOR
    function startFlashcards() {
        dispatch(setIsLoading(true))
        SessionBuilder.start({
            testType: TEST_TYPES.FLASHCARDS,
            builder: 'custom',
            studyMode,
            selectedTagpaths,
            sessionConfig,
            saveAsLastSession: true,
            descriptor: 'Estudo Customizado'
         })

        prepareSession(studyMode)
    }


    function prepareSession(mode, customList = false) {
        if (Session.sessionSize > 0) {
            if (customList) {
                Session.addList(customList)
            }
            Session.addTemporaryListener(mode === 'consult-mode' ? moveToConsult : moveToTests)
        }
        else {
            dispatch(setIsLoading(false))
            toastMsg("Não esperávamos por esse erro... Manda um print por DM. Desculpa. 🫠")
        }
    }


    function moveToTests() {
        navigate("/test")
        dispatch(setIsLoading(false))
    }


    function moveToConsult() {
        navigate("/consult")
        dispatch(setIsLoading(false))
    }


    useEffect(() => {
        const nodesBackup = tree.extractNodeBackup()
        tree.update(studyMode, sessionConfig)

        const somethingChanged = tree.loadTreeFromPreviousNodes(nodesBackup)

        if (somethingChanged) {
            toastMsg('⚠️ Retiramos alguns temas porque não há questões para esse filtro!')
        }

        // Pode ter ocorrido alteração no checkedNodes, então precisamos
        // rever as tagpaths
        const paths = tree.extractCheckedNodes()
        setSelectedTagpaths(paths)


        triggerTreeRender()
    }, [testType, sessionConfig, studyMode, themeSearch, screenWidth])


    function triggerTreeRender() {
        console.log(`Houve mudança em algum nó! -- Nova renderização -- screenWidth: ${screenWidth}!`)
        const JSX = tree.createTreeJSX()
        const anyNodeVisible = tree.anyNodeVisible()
        const anyNodeAvailable = tree.anyNodeAvailable()

        if (anyNodeVisible && anyNodeAvailable) {
            setTreeJSX(JSX)
        }
        else if (!anyNodeAvailable) {
            setTreeJSX(
                <Empty>
                    <Main>Você já fez tudo!</Main>
                    <Sub>Não há nenhum teste disponível para os filtros/ajustes selecionados.</Sub>

                    <Sub>Ou você precisa mudar as configurações ou está tudo em dia mesmo. :)</Sub>
                </Empty>
            )
        }
        else {
            setTreeJSX(
                <Empty>
                    <Main>Não localizamos esse tema.</Main>
                    <Sub>Verifique a grafia e considere a possibilidade dele estar em outros decks e/ou com outro nome.</Sub>

                    <Sub>Se você acha que é algo específico que está faltando, peça por DM.</Sub>
                </Empty>
            )
        }

    }


    const containerRef = useRef(null)
    const [floatWidth, setFloatWidth] = useState(0)
    const [floatHeight, setFloatHeight] = useState(0)
    
useEffect(() => {
    function updateWidth() {
        // Pequeno delay para garantir que o componente já renderizou
        setTimeout(() => {
            if (containerRef.current) {
                const width = containerRef.current.getBoundingClientRect().width
                setFloatWidth(width)

                const height = containerRef.current.getBoundingClientRect().height
                setFloatHeight(height)
            }
        }, 100)
    }

    updateWidth()
    window.addEventListener('resize', updateWidth)
    return () => window.removeEventListener('resize', updateWidth)
}, [treeJSX, loadedInfo]) // Adicione estas dependências



    const saveFilterBttn = isSelectionNewFilter() ? (
        <OslerButton
            text='Salvar filtro'
            color='gray'
            width='100%'
            onClick={() => setShowSaveFilterDialog(true)} />
    ) : null



    const pillsData = useMemo(() => [
        {
            count: chosenInstitutions.length,
            labelSingular: 'instituição',
            labelPlural: 'instituições',
            onClear: () => updateChosenInstitutions([])
        },
        {
            count: chosenYears.length,
            labelSingular: 'ano',
            labelPlural: 'anos',
            onClear: () => updateChosenYears([])
        },
        {
            count: selectedTagpaths.length,
            labelSingular: 'tema',
            labelPlural: 'temas',
            onClear: () => clearSelectedTagpaths()
        }
    ], [chosenInstitutions.length, chosenYears.length, selectedTagpaths.length])

    

    const [hasSelections, setHasSelections] = useState(false)
    // const [pillsJSX, setPillsJSX] = useState(false)

    useEffect(() => {
        console.log(`CustomStudy: instituições: ${chosenInstitutions.length}, anos: ${chosenYears.length}, temas: ${selectedTagpaths.length}`)
 
        const hasSelections = chosenInstitutions.length > 0 || chosenYears.length > 0 || selectedTagpaths.length > 0
        setHasSelections(hasSelections)
    }, [chosenInstitutions, chosenYears, selectedTagpaths])


    const startSessionBttn = (
        <StartBttnLuxury
            testType={testType}
            nTests = {numAvailableTestsForSession}
            hasSelections = {hasSelections}
            pills = {pillsData}
            onStart={() => setShowCreationDialog(true)} />
    )


    if (!loadedInfo) return <IsLoading />

    return (
        <Container sessionAvailable={chosenInstitutions.length > 0 || chosenYears.length > 0 || selectedTagpaths.length > 0}>    
            <StudyModeGeneralDialog
                open={showHelp}
                onClose={() => setShowHelp(false)}
                testType={testType} />

            {/* Só existem para Residência */}
            <SaveFilterDialog
                showDialog={showSaveFilterDialog}
                setShowDialog={setShowSaveFilterDialog}
                saveFilter={saveFilter} />

            <CreateSessionDialog
                showDialog={showCreationDialog}
                setShowDialog={setShowCreationDialog}
                mode={studyMode}
                paths={selectedTagpaths}
                sessionConfig={sessionConfig} />


            {testType === 'Residencia' ? (
                <>
                    {/* <TabContainer>
                        {tabs.map(tab => (
                            <Tab
                                key={tab}
                                active={selectedTab === tab}
                                onClick={() => changeSelectedTab(tab)}
                            >
                                {tab}
                            </Tab>
                        ))}
                        <HelpBttn style = {{paddingBottom: '0.4em'}} onClick={() => setShowHelp(true)} />
                    </TabContainer> */}

                    <BttnsContainer>
                        {tabs.map(tab => (
                            <TabButton
                                key={tab.name}
                                className={selectedTab === tab.name ? 'active' : ''}
                                onClick={tab.onClick ? tab.onClick : () => changeSelectedTab(tab.name)}
                            >
                                <TabIcon src={tab.icon} alt={`${tab.name} icon`} />
                                <TabName>{tab.name}</TabName>
                            </TabButton>
                        ))}


                    </BttnsContainer>

                    <ContentWrapper>
                        <TreeContainer ref={containerRef}>
                            <SearchBar
                                currentString={selectedTab === 'Temas' ? themeSearch : institutionsSearch}
                                setSearch={selectedTab === 'Temas' ? setThemeSearch : setInstitutionsSearch}
                                visible={selectedTab === 'Temas' || selectedTab === 'Instituições'}
                                placeholder={selectedTab == 'Temas' ? 'Busque um tema...' : 'Busque uma instituição...'}
                                style={{ marginBottom: '1em' }} />

                            <ContentArea>
                                {selectedTab === 'Temas' && (
                                    treeJSX
                                )}

                                {selectedTab === 'Instituições' && (
                                    <InstitutionsCard
                                        searchedString={institutionsSearch}
                                        updateChosenInstitutions={updateChosenInstitutions}
                                        previouslyChecked={chosenInstitutions} />
                                )}

                                {selectedTab === 'Anos' && (
                                    <YearsCard
                                        updateChosenYears={updateChosenYears}
                                        previouslyChecked={chosenYears} />
                                )}

                                {selectedTab === 'Meus filtros' && (
                                    <CustomFiltersCard
                                        appliedFilters={appliedFilters}
                                        updateAppliedFilters={updateAppliedFilters}
                                        selectedInstitutions = {chosenInstitutions}
                                        selectedYears = {chosenYears} />
                                )}
                            </ContentArea>
                        </TreeContainer>

                        <CustomStudySideBar
                            testType={testType}
                            onConfigChange={onConfigChange}
                            onModeChange={setStudyMode}
                            treeContainerRef = {containerRef.current}
                            floatWidth = {floatWidth}
                            floatHeight = {floatHeight}
                            saveFilterBttn = {saveFilterBttn}

                            nTests = {numAvailableTestsForSession}
                            hasSelections = {hasSelections}
                            pills = {pillsData}
                            onStart={() => setShowCreationDialog(true)}

                    />

                    </ContentWrapper>
                </>
            ) : (
                // Layout simplificado para Flashcards
                <ContentWrapper>
                    <TreeContainer ref={containerRef}>
                        <SearchBar
                            currentString={themeSearch}
                            setSearch={setThemeSearch}
                            visible={true}
                            placeholder='Busque um tema...'
                            style={{ marginBottom: '1em' }} />

                        <ContentArea>
                            {treeJSX}
                        </ContentArea>
                    </TreeContainer>

                    <CustomStudySideBar
                        testType={testType}
                        onConfigChange={onConfigChange}
                        onModeChange={setStudyMode}
                        treeContainerRef = {containerRef.current}

                        floatWidth = {floatWidth}
                        floatHeight = {floatHeight}

                        nTests = {numAvailableTestsForSession}
                        hasSelections={hasSelections}
                        pills = {pillsData}
                        onStart={() => startFlashcards()} />
                </ContentWrapper>
            )}
        </Container>
    )
}
