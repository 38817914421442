import React, { useState } from 'react';
import styled from 'styled-components';
import CloseIcon from './../assets/close-gray.png';
import CloseIconBlack from './../assets/close-black.png';
import { formatNumber } from '../utils/Utils';


const Pill = styled.div`
    display: inline-flex;
    align-items: center;
    background: #ffffff;
    padding: 0.6em 1em;
    border-radius: 2em;
    border: 1px solid rgba(22, 114, 246, 0.4);
    box-shadow: 0 4px 12px rgba(22, 114, 246, 0.15);
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    max-width: max-content;

    ${(props) =>
        props.theme.darkMode
            ? `
        background: rgba(30, 30, 30, 0.95);
        border: 1px solid rgba(130, 165, 255, 0.4);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
        &:hover {
            transform: translateY(-2px);
            box-shadow: 0 6px 16px rgba(0, 0, 0, 0.6);
            border: 1px solid rgba(130, 165, 255, 0.5);
        }
    `
            : `
        &:hover {
            transform: translateY(-2px);
            box-shadow: 0 6px 16px rgba(22, 114, 246, 0.25);
            border: 1px solid rgba(22, 114, 246, 0.5);
        }
    `}

    span {
        font-size: 0.85em;
        color: ${(props) => (props.theme.darkMode ? 'rgb(230, 230, 230)' : '#1a1a1a')};
        font-weight: 500;
        letter-spacing: -0.01em;
    }

    strong {
        margin: 0 0.3em;
        color: ${(props) => (props.theme.darkMode ? '#82a5ff' : '#1672f6')};
        font-weight: 600;
        font-feature-settings: 'tnum';
        font-variant-numeric: tabular-nums;
    }
`;

const ClearButton = styled.button`
    background: none;
    border: none;
    padding: 0.1em;
    margin-left: 0.6em;
    opacity: 0.8;
    cursor: pointer;
    transition: all 0.2s ease;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        opacity: 1;
        background-color: ${(props) =>
            props.theme.darkMode ? 'rgba(255, 255, 255, 0.15)' : 'rgba(0, 0, 0, 0.05)'};
    }

    img {
        width: 0.8em;
        height: 0.8em;
        display: block;
        filter: ${(props) => (props.theme.darkMode ? 'invert(1)' : 'none')};
    }
`;



export default function CustomStudyPill({ data, onClearExtra }) {
    const [isHovered, setIsHovered] = useState(false)
    const { count, labelSingular, labelPlural, onClear } = data
 
    const handleClear = () => {
        onClear()
        if (onClearExtra) onClearExtra()
    }
 
    return count > 0 && (
        <Pill>
            <span>
                <strong>{formatNumber(count)}</strong>
                {count === 1 ? labelSingular : labelPlural}
            </span>
            <ClearButton 
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)} 
                onClick={handleClear}>
                <img src={isHovered ? CloseIconBlack : CloseIcon} alt="Limpar" />
            </ClearButton>
        </Pill>
    )
 }