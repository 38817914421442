import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { savePath } from '../redux/pathSlice';


// Essas funções são redundâncias para ganrantir que o usuário não acesse 
// telas que não deva.


export function LoginShield(props) {
    // Tem um Shield próprio, porque a lógica é inversa à das demais telas do
    // aplicativo: o usuário tem acesso se, e somente se, NÃO está logado.
    const user          = props.user
    const listenerCalled = props.listenerCalled
 
    console.log(`LoginShield: ${user} - ${listenerCalled}`)

    if (!listenerCalled) {
        // Se não há um usuário logado, há duas opções. Primeiro, estamos aguardando
        // o listener ser chamado pelo FirebaseAuth, e nesse caso devemos ir para uma
        // página de loading. Segundo, o listener já foi chamado, de fato não há ninguém
        // logado, e o login é permissível.
        return <Navigate to="/loading-user" replace />
    }
    else {
        if (user) {
            return <Navigate to="/app" replace />
        }
        else {
            return <Outlet />
        }
    }
}


export function LoadingShield(props) {
    const listenerCalled = props.listenerCalled

    const path = useSelector(state => state.path.path)

    if (listenerCalled) {
        // Esse condicional é muito importante, porque é baseado na atualização do state
        // justamente quando o listener que estamos esperando foi chamado. 
        // Enviamos o usuário para onde ele queria ir.

        // console.log("LoadingShield: finished; going to " + path)
        return <Navigate to={path} replace />;
    }
    else {
        return <Outlet />
    }
}




export function AppShield(props) {
    const user          = props.user
    const isActive      = props.isActive
    const listenerCalled = props.listenerCalled


    const location = useLocation()
    const dispatch = useDispatch()
    
    const path = location.pathname

    // console.log(`AppShield: ${user} - ${isActive} - ${listenerCalled} - ${path}`)

    if (!listenerCalled) {
        // console.log("AppShield: dispatching path as " + path)
        dispatch( savePath(path))
        return <Navigate to="/loading-user" replace />;
    }
    else {

        if (user) {
            if (isActive) {
                return <Outlet />
            }
            else {
                if (path == '/user' || path == '/checkout') {
                    return <Outlet />
                }
                else {
                    return <Navigate to="/user" replace />;
                }
            }
        }
        else {
            // Se não tem usuário, e queria ir para a raiz, colocamos em /home
            // Se não tem usuário, mas queria ir para o app, presumo que já usa a plataforma
            // e ofereço login.
            if (path == '/') {
                return <Navigate to="/home" replace />;
            }
            else {
                return <Navigate to="/login" replace />;
            }
        }
    }
}



// export function UserScreenShield(props) {
//     const user          = props.user
//     const listenerCalled = props.listenerCalled

//     if (!listenerCalled) {
//         return <Navigate to="/loading-user" replace />;
//     }
//     else {
//         if (user) {
//             return <Outlet />
//         }
//         else {
//             return <Navigate to="/login" replace />;
//         }
//     }
// }